body {
	font-size: 16px;
	line-height: 28px;
	color: $color_secondary;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased; }

html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px; }





h1,h2,h3,h4,h5,h6,p,span,div {
	-webkit-text-size-adjust: 100% {
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-size: 100%; } }

/* GLOBAL SETTINGS */

a {
	transition: all 0.15s ease-in-out; }

a,ins {}
a:hover {
	text-decoration: none; }

ul,ol, figure {
	margin: 0;
	padding: 0; }
img {
	max-width: 100%; }

.tim-container-box {
	width: 1200px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto; }

@media (min-width: 1600px) {
	.tim-container {
		padding: 0 6.5%; } }

@media (max-width: 1599px) {
	.tim-container {
		padding: 0 5%; } }


#site {
	position: relative;
	overflow: hidden; }

.container {
	max-width: 1400px;

	&.con-pd {
		padding: 0 125px; } }

.section-padding {
	padding: 120px 0; }


/* Reset */
.pt__100 {
	padding-top: 100px; }

.pb__100px {
	padding-bottom: 100px; }

.pr__30 {
	padding-right: 30px; }

.pl__30 {
	padding-left: 30px; }

.ml__15 {
	margin-left: 15px; }

.pdlr {
	padding: 0 100px; }

.pt__110 {
	padding-top: 110px; }

.pt_70 {
	padding-top: 70px; }

.mar-btn-30 {
	margin-bottom: 30px!important; }



/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6 {
	font-family: $font_heading; }

.section-title {
	margin-bottom: 50px;

	h2 {
		font-size: 40px;
		line-height: 26px;
		color: #444;
		font-weight: 900;
		text-transform: uppercase;
		margin-bottom: 23px;
		padding-bottom: 25px;
		position: relative;
		display: inline-block;

		&:before,
		&:after {
			position: absolute;
			content: '';
			bottom: 0;
			background: $color_primary;
			left: 50%;
			transform: translateX(-50%); }

		&:before {
			width: 200px;
			height: 1px; }

		&:after {
			height: 3px;
			bottom: -1px;
			width: 30px;
			left: 50%; }

		span {
			color: $color_primary; } }

	p {
		color: #494848;
		font-size: 16px;
		margin: 0; }

	&.title-two {
		h2 {
			margin-bottom: 30px;

			&:before {
				display: none; }

			&:after {
				background-image: url(../../media/background/2.png);
				background: transparent;
				height: 25px;
				max-width: 320px;
				background-repeat: no-repeat;
				width: 100%;
				left: 50%;
				bottom: -20px; } } }

	&.title-three {
		h2 {
			&:before {
				background-image: url(../img/dm2.png);
				background-color: transparent;
				height: 15px;
				width: 150px;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: -8px; }

			&:after {
				display: none; } } } }

.section-dark {
	.section-title {
		h2 {
			color: #fff; }

		p {
			color: #fff; }


		&.title-three {
			h2 {
				&:before {
					background-image: url(../img/dm2l.png); } } } } }


/* Button */
.tim-btn {
	padding: 10px 41px;
	border: 1px solid #e4e4e4;
	color: #363636;
	display: inline-block;
	font-size: 20px;
	font-weight: 500;
	font-family: $font_heading;
	border-radius: 5px;

	&:hover {
		color: #e43a90; }

	&.tim-btn-bgt {
		border: 1px solid #e4e4e4;
		color: #363636;


		&:hover {
			color: #fff; } } }

.video-btn-two {
	padding: 13px 37px;
	border: 1px solid #fff;
	color: #fff;
	border-radius: 4px;
	vertical-align: middle;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	font-family: $font_heading;

	i {
		margin-right: 20px;
		color: $color_primary;
		vertical-align: middle;
		font-size: 24px;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			height: 20px;
			width: 1px;
			background: #7c7d81;
			right: -14px;
			top: 2px; } }


	&:hover {
		background: $color_primary;
		border-color: $color_primary;
		color: #fff;

		i {
			color: #fff;

			&:after {
				background: #fff; } } } }

.store-btn {
	padding: 10px 25px;
	color: #fff;
	border: 2px solid #fff;
	font-weight: 600;
	border-radius: 4px;
	font-size: 18px;
	vertical-align: top;
	display: inline-block;

	i {
		font-size: 25px;
		margin-right: 7px; }

	&:hover {
		color: #fff;
		background: $color_primary;
		border-color: $color_primary; } }

/* Flat Icons */
@include font-face(tim-icon, '../fonts/tim-icon', normal);


[class^="tim-"]:before, [class*=" tim-"]:before,
[class^="tim-"]:after, [class*=" tim-"]:after {
	font-family: tim-icon;
	// font-size: 20px
	font-style: normal; }


.Switcher__control {
	background-image: none;
	font-size: 25px;
	outline: 0 !important; }


/* Flat Icons */

.tim-apple-logo {
	&:before {
		content: "\f100"; } }

.tim-playstore {
	&:before {
		content: "\f101"; } }

.tim-technology {
	&:before {
		content: "\f102"; } }

.tim-photo {
	&:before {
		content: "\f103"; } }

.tim-photo-camera {
	&:before {
		content: "\f104"; } }

.tim-cross-out {
	&:before {
		content: "\f105"; } }

.tim-repeat {
	&:before {
		content: "\f106"; } }

.tim-favorite {
	&:before {
		content: "\f107"; } }

.tim-eye {
	&:before {
		content: "\f108"; } }

.tim-headphones {
	&:before {
		content: "\f109"; } }

.tim-sound-frecuency {
	&:before {
		content: "\f10a"; } }

.tim-code-rate {
	&:before {
		content: "\f10b"; } }

.tim-slide {
	&:before {
		content: "\f10c"; } }

.tim-music-player-play {
	&:before {
		content: "\f10d"; } }

.tim-piano-keys {
	&:before {
		content: "\f10e"; } }

.tim-piano-keyboard {
	&:before {
		content: "\f10f"; } }

.tim-heart-outline {
	&:before {
		content: "\f110"; } }

.tim-favorite-heart-button {
	&:before {
		content: "\f111"; } }

.tim-drum-set-cartoon-variant {
	&:before {
		content: "\f112"; } }

.tim-music-album-1 {
	&:before {
		content: "\f113"; } }

.tim-electric-guitar-music-instrument {
	&:before {
		content: "\f114"; } }

.tim-cogwheel {
	&:before {
		content: "\f115"; } }

.tim-pause-button {
	&:before {
		content: "\f116"; } }

.tim-woofer-speaker {
	&:before {
		content: "\f117"; } }

.tim-layers {
	&:before {
		content: "\f118"; } }

.tim-view {
	&:before {
		content: "\f119"; } }

.tim-guitar {
	&:before {
		content: "\f11a"; } }

.tim-commerce {
	&:before {
		content: "\f11b"; } }

.tim-support {
	&:before {
		content: "\f11c"; } }

.tim-shopping-cart-1 {
	&:before {
		content: "\f11d"; } }

.tim-music {
	&:before {
		content: "\f11e"; } }

.tim-newsletter {
	&:before {
		content: "\f11f"; } }

.tim-tabs {
	&:before {
		content: "\f120"; } }

.tim-monitor {
	&:before {
		content: "\f121"; } }

.tim-music-album {
	&:before {
		content: "\f122"; } }

.tim-shopping-cart {
	&:before {
		content: "\f123"; } }

.tim-flute {
	&:before {
		content: "\f124"; } }

.tim-keyboard {
	&:before {
		content: "\f125"; } }

.tim-arrows {
	&:before {
		content: "\f126"; } }

.tim-layout {
	&:before {
		content: "\f127"; } }

.tim-zoom-out {
	&:before {
		content: "\f128"; } }

.tim-computer {
	&:before {
		content: "\f129"; } }

.tim-music-1 {
	&:before {
		content: "\f12a"; } }


.loader.is-active {
	background-color: #fff;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0; }

.loader-bar-ping-pong::before {
	background-color: #e7e3e3;
	border-radius: 16px;
	display: none; }

.loader-bar-ping-pong::after, .loader-bar-ping-pong::before {
	content: "";
	height: 2px;
	position: absolute;
	top: calc(50% - 10px);
	left: calc(50% - 100px); }



@media (max-width: 768px) {
	.section-padding {
		padding: 50px 0; }

	.section-title {
		h2 {
			font-size: 30px; }

		p {
			br {
				display: none; } } }

	.pt__100 {
		padding-top: 50px; }

	.pt__110 {
		padding-top: 50px; } }

@media (max-width:500px) {
	.col-full-width {
		max-width: 400px;
		margin: 0 auto;
		flex: 0 0 100%; }

	.container {
		&.con-pd {
			padding: 0; } } }
