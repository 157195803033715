.tabs-filter-button {
	margin: 0;
	padding: 0;
	list-style: none;
	background: #313131;
	padding: 37px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	text-align: center;

	li {
		position: relative;
		padding-left: 20px;

		&:after {
			content: '';
			position: absolute;
			right: -60px;
			top: 6px;
			height: 16px;
			width: 2px;
			background: #4e4e4e; }


		&:last-child {
			&:after {
				display: none; } }

		a {
			color: #fff;
			font-size: 18px;
			font-weight: 600;
			font-family: $font_heading;

			&:hover {
				color: $color_primary; } }
		&.current {
			a {
				color: $color_primary; } } } }


.tab-details {
	.details {
		margin: 0;
		padding: 30px 40px;
		list-style: none;
		box-shadow: 0px 5px 21px 0px rgba(142, 142, 142, 0.23);
		background: rgba(255,255,255,0.5);


		li {
			font-family: $font_heading;
			font-size: 16px;
			font-weight: 500;
			color: #585757;
			position: relative;
			padding-bottom: 5px;
			display: inline-block;
			text-align: left;
			width: 16%;

			&:before, &:after {
				content: '';
				position: absolute;
				background: #adadad; }

			&:before {
				bottom: 0;
				width: 25%;
				height: 1px;
				left: 25px;
				transform: translateX(-50%); }

			&:after {
				left: 16px;
				width: 15px;
				height: 4px;
				bottom: -1px; } } }

	&.tab-dark {
		ul {
			background: #313131;

			li {
				color: #fff; } } } }

.tabs-filter-button li {}
.songs-details {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		width: 16%;
		text-align: left;
		font-size: 16px;
		font-weight: 500;
		font-family: $font_heading;

		a {
			color: #585757;

			&:hover {
				color: $color_primary; } }

		span {
			color: $color_primary; }

		i {
			margin-right: 5px; } } }

.tabs-filter {
	margin: 0;
	padding: 0;
	list-style: none; }

.tab-filter-wraper {
	background-image: url(../../media/background/13.jpg);
	padding-bottom: 30px;

	&.top-music-wrap {
		box-shadow: 0px 5px 21px 0px rgba(184, 184, 184, 0.23); } }

.tim-isotope-3 {
	padding: 0 40px; }

.tim-songs-items {
	&.grid-item {
		width: 100%;
		padding: 20px 0;
		border-bottom: 1px solid #d1d1d1;

		&:last-child {
			border-bottom: 0; } }

	&.tranding-song {
		padding: 16px 0;
		border-bottom: 1px solid #d1d1d1;

		&:before {
			clear: both;
			content: '';
			display: block; } } }


/* Top Music */
#top-music {
	padding: 120px 0;
	background: #fbfbfb; }


.circle-player {
	background: #f5f5f5;
	padding: 25px; }


#jp_container_1 {
	.jp-type-playlist {
		background: #dea23c;
		padding: 80px; }
	.jp-gui {
		margin: 0 auto;
		height: 410px;
		width: 410px;
		border-radius: 50%;
		background: #FFF;
		padding: 80px;
		position: relative;

		.jp-controls {
			background: #dea23c;
			border-radius: 50%;
			text-align: center;
			height: 100%;
			width: 100%;
			padding: 65px 0;


			button {
				border: 0;
				background: transparent; } }

		.jp-next, .jp-previous {
			position: absolute;
			top: 50%;
			transform: translateY(-50%); }

		.jp-next {
			right: 25px; }

		.jp-previous {
			left: 25px; }

		.jp-volume-controls {
			border-radius: 50%;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: center;

			.jp-volume-bar {
				height: 5px;
				background: #fff;
				width: 70px;

				.jp-volume-bar-value {
					height: 5px;
					background: #4c4c4c; } }

			.jp-mute {
				margin-right: 5px; } }

		.jp-progress {
			width: 150px;
			margin: 0 auto;

			.jp-seek-bar {
				height: 5px;
				background: #fff;

				.jp-play-bar {
					height: 5px;
					background: #4c4c4c; } }

			.jp-current-time {
				float: left; }

			.jp-duration {
				float: right; } } } }

.tab-filter-wrapper {
	padding: 0 40px; }


@media (max-width: 1200px) {
	.tabs-filter-button {
		li {
			&:after {
				right: -44px; } } } }

@media (max-width: 1200px) {
	.tabs-filter-button {
		li {
			&:after {
				right: -30px; } } } }

@media (max-width: 1199px) {

	.tab-pd-left,.tab-pd-right {
		padding: 70px 35px; } }

@media (max-width: 768px) {

	.tabs-filter-button {
		li {
			width: 25%;

			&:after {
				right: -17px; } } }

	.tim-isotope-3 {
		padding: 0; }

	.tab-details {
		.details {
			display: none; } }



	.songs-details {
		li {
			display: block;
			width: 100%;
			float: right;
			padding: 15px;
			border-bottom: 1px solid #b1b1b1;

			&:last-child {
				border-bottom: 0; }

			&:before {
				width: 30%;
				float: left;
				font-weight: 600;
				text-align: left; }

			&:nth-of-type(1) {
				&:before {
					content: "Artist"; } }

			&:nth-of-type(2) {
				&:before {
					content: "Title"; } }

			&:nth-of-type(3) {
				&:before {
					content: "Type"; } }

			&:nth-of-type(4) {
				&:before {
					content: "Rating"; } }

			&:nth-of-type(5) {
				&:before {
					content: "Published"; } }

			&:nth-of-type(6) {
				&:before {
					content: "View"; } } } }

	.tim-songs-items {
		&.tranding-song {} }


	.tim-songs-items {
		&.grid-item {
			padding: 15px 0;
			border-bottom: 2px solid #000; } }

	.tim-songs-items {
		&.tranding-song {
			padding: 0;
			border-bottom: 2px solid #211f1f; } }

	.tabs-filter-button {
		li {
			&:nth-last-child(-n+4) {
				&:after {} } } }

	.tab-filter-wrapper {
		padding: 0;
		overflow: hidden; }

	#top-music {
		padding: 50px 0; } }

@media (max-width: 575px) {

	.tabs-filter-button {
		li {
			width: 50%; } }

	.tab-pd-left, .tab-pd-right {
		padding: 30px 15px 15px; } }


