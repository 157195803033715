.header {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;

	.top-header {
		background: rgba($color_primary, 0.65); }

	&.header-two {
		.top-header {
			position: relative;
			background: #fff;

			.skew-bg {
				background: $color_primary;
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 70%;
				height: 50px;

				&:before {
					position: absolute;
					right: -40px;
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 50px 0 0 40px;
					border-color: transparent transparent transparent $color_primary; } }

			.user-login {
				> li {
					&:after {
						background: rgba(0,0,0,0.3); } }
				li {
					> a {
						color: #363636;

						i {
							color: #363636; }

						&:hover {
							color: $color_primary; } }

					&.cart-count {
						.badge {
							color: #fff; } } } } }

		.header-inner {
			background: rgba($color_primary, 0.2); } } }


.site-social-link {
	list-style: none;
	display: inline-block;
	position: relative;

	li {
		display: inline-block;
		padding: 11px 0;
		a {
			display: block;
			color: #fff;
			font-size: 14px;
			margin-right: 15px;

			&:hover {
				color: #363636; } } } }


/* Header style three */


/* Top Header Right With Mini Cart */
.user-login {
	list-style: none;

	> li {
		display: inline-block;
		position: relative;
		margin-right: 30px;
		font-family: $font_heading;
		padding: 10px 0;

		&:after {
			position: absolute;
			content: '';
			height: 15px;
			width: 2px;
			background: rgba(255, 255, 255, 0.3);
			right: -18px;
			top: 50%;
			transform: translateY(-50%); } }
	li {
		&.cart-count {
			position: relative;

			.badge {
				position: absolute;
				top: 10px;
				left: 8px;
				background: #363636;
				border-radius: 50%;
				line-height: 16px;
				width: 17px;
				font-size: 11px;
				height: 17px;
				display: block;
				padding: 0;
				font-weight: 500;
				text-align: center; }

			.cart-overview {
				opacity: 0;
				visibility: hidden;
				z-index: 101;
				line-height: 1.8;
				position: absolute;
				background: #FFF;
				text-align: left;
				width: 350px;
				padding: 15px;
				margin-top: 0;
				right: 0;
				top: 140%;
				transition: all 0.3s ease-in-out;

				.cart-item {
					margin-bottom: 10px;
					display: block;
					position: relative;
					margin-right: 0;
					padding: 0;

					.product-thumbnail {
						margin-right: 1.3em;
						float: left;
						padding: 0 !important;
						display: inline-block;
						width: 70px;

						img {
							max-width: 100%; } }


					.product-details {
						position: relative;
						margin-left: 70px;

						.product-title {
							font-size: 16px;
							font-weight: 600;
							color: #000;
							display: block;
							text-decoration: none;


							&:hover {
								color: $color_primary; } }

						.product-remove {
							position: absolute;
							right: 0;
							top: 42%;
							height: 18px;
							width: 18px;
							font-size: 6px;
							background: #3e3e3e;
							color: #FFF;
							border-radius: 50%;
							text-align: center;
							line-height: 18px;
							color: #f3f3f3;
							transition: all 0.3s ease-in-out;

							&:hover {
								background: #444;
								color: #FFF; } }

						.product-quantity {
							display: inline-block; } } }

				.cart-subtotal {
					display: block;
					padding: 15px 0;
					border-top: 1px solid #dcdcdc;
					border-bottom: 1px solid #dcdcdc;
					margin-top: 15px;
					font-size: 14px;
					font-weight: 600;
					color: #000;
					text-transform: uppercase;

					.amount {
						float: right; } }

				.cart-actions {
					display: block;
					padding-top: 20px;
					.view-cart, .checkout {
						padding: 13px 25px !important;
						text-decoration: none;
						border-radius: 30px;
						transition: all 0.3s ease-in-out;
						text-transform: uppercase;
						font-size: 16px !important;
						display: inline-block !important;
						letter-spacing: 1px;
						font-weight: 600; } } }

			.view-cart {
				color: #363636;
				border: 1px solid #363636;

				&:hover {
					background: $color_primary;
					color: #fff !important;
					border-color: $color_primary; } }

			.checkout {
				background: $color_primary;
				color: #FFF !important;
				float: right;
				border: 1px solid $color_primary;

				&:hover {
					background: darken($color_primary, 10%); } } }
		&:hover {
			.cart-overview {
				opacity: 1 !important;
				top: 100% !important;
				visibility: visible !important; } }

		&:last-child {
			margin-right: 0;

			&:after {
				display: none; } }


		a {
			font-size: 15px;
			color: #fff;
			font-weight: 500;
			display: inline-block;
			line-height: 1;

			i {
				margin-right: 10px;
				color: #fff; } }

		&:hover {

			.badge {
				color: #fff; }

			a {
				color: #b9b9b9;

				i {
					color: #fff; } } } } }

.header-inner {
	background: rgba($color_primary, 0.2); }

#site-logo {
	line-height: 104px;

	.logo-stickky {
		display: none; } }

.hide-topbar {
	#site-logo {
		.logo-stickky {
			display: block; }

		.logo-main {
			display: none; } } }

#main-header-menu {
	list-style: none;

	> li {
		position: relative;

		a {
			position: relative;
			display: inline-block;
			font-family: $font_heading; }

		&:after, &:before {
			position: absolute;
			content: '';
			width: 30px;
			height: 1px;
			background: #fff;
			opacity: 0;
			transition: all 0.5s ease; }

		&:before {
			left: 30px;
			top: 35px; }


		&:after {
			bottom: 35px;
			right: 30px; }


		&:hover {

			&:after, &:before {
				opacity: 1; }

			&:before {
				left: -6px; }

			&:after {
				right: -5px; } }


		&.menu-item-has-children {
			.sub-menu {
				text-align: left;
				position: absolute;
				padding: 10px 20px;
				width: 240px;
				background: rgba(0,0,0,0.95);
				top: 100%;
				transform: translateY(-10px);
				left: auto;
				margin: 0;
				border-top: 1px solid $color_primary;
				opacity: 0;
				visibility: hidden;
				box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
				transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
				height: auto;

				li {
					display: block;
					padding: 5px 0;
					margin: 0;

					&:last-child {
						border-bottom: 0; }

					a {
						color: #fff;
						padding: 3px 0;
						font-size: 16px;

						&:hover, &.current_page {
							color: $color_primary; } } }



				&.mega-menu {
					width: 100%;
					padding: 20px 30px;

					> li {
						width: 25%;
						float: left;

						h3 {
							font-size: 20px;
							font-weight: 600;
							color: #fff;
							margin-left: 15px; } }

					li {

						.sub-menu {
							background-color: transparent;
							border-top-color: transparent;
							float: left;
							padding: 0 15px;



							li {
								display: block;
								a {
									display: block;
									padding: 3px 0; } } }

						&:not(:last-child) {
							.sub-menu {
								border-right: 1px solid rgba(255,255,255,0.1) !important; } } } } }

			&:hover {

				.sub-menu {
					opacity: 1;
					transform: translateY(0);
					visibility: visible;
					z-index: 20;
					transition-delay: 0s, 0s, 0.3s; } } } }

	li {
		display: inline-block;
		margin: 0 20px;
		padding: 37px 0;


		a {
			color: #fff;
			text-transform: uppercase;
			font-size: 17px; } }

	> li.active {

		&:before {
			left: -6px; }

		&:after {
			right: -5px; } }

	> li.active {

		&:before, &:after {
			opacity: 1; } } }

.header-inner {
	.nav {
		.head-btn {
			height: 35px;
			border: 2px solid rgba(255,255,255,0.8);
			border-radius: 25px;
			font-size: 16px;
			color: #fff;
			padding: 0 25px;
			margin: 33px 0 0 100px;
			text-transform: uppercase;
			box-sizing: border-box;
			display: block;
			float: right;
			line-height: 30px;
			transition: .25s;
			cursor: pointer;
			font-family: $font_heading;

			&:hover {
				background: $color_primary;
				color: #fff;
				border-color: $color_primary; } } } }


/* Mobile Nav */
#nav-toggle {
	margin-right: 0;
	float: right;
	width: 30px;
	padding: 7px 0;

	.toggle-inner {
		position: relative;

		> span {
			display: block;
			position: relative;
			margin-bottom: 5px;
			width: 100%;
			height: 2px;
			background-color: #fff;
			transition: all 0.25s ease-in-out;

			&:nth-child(2), &:nth-child(3) {
				width: 24px;
				margin-left: 6px;
				background-color: $color_primary!important; }

			&:nth-child(4) {
				margin-bottom: 0; } } }

	&.active {
		.toggle-inner {
			> span {
				&:nth-child(2),&:nth-child(3) {
					opacity: 0; }
				&:nth-child(1) {
					transform: rotate(45deg) translate(7px, 7px); }
				&:nth-child(4) {
					transform: rotate(-45deg) translate(8px, -8px); } } } } }


nav#accordian {
	padding: 20px 30px; }

#mobile-nav-wrap {
	display: none;
	background: #131313;
	padding: 16px 20px;

	#mobile-logo {
		float: left;
		padding: 5px 0;

		img {
			width: 60px; } }

	.user-link {
		padding: 5px 0;

		li {
			margin-right: 10px; } }

	&.mnav-fixed {
		position: fixed;
		top: 0;
		width: 100%;
		left: 0;
		z-index: 9999;
		animation: headerFixedTop .35s; } }

.mobile-menu-inner {
	display: block;
	width: 100%;
	text-align: center;
	background: $color_primary;
	position: fixed;
	z-index: 9999;
	left: -100%;
	transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
	top: 0;

	&.mnav-fixed {
		position: fixed; }

	#mobile-logo {
		margin-bottom: 30px; }

	.mob-nav {
		margin: 0;
		padding: 0;
		text-align: left;
		list-style: none; } }

.accordion-menu {
	width: 100%;
	margin: 20px auto 50px;
	text-align: left;
	border-radius: 4px;
	list-style: none;

	ul {
		list-style: none; }

	> li {
		position: relative;
		border-top: 1px solid rgba(255,255,255,0.3);

		&:first-child {
			border-top: 0; }

		&.single-link {
			margin: 3px 0; }

		.dropdownlink {
			&:before {
				content: "\f105";
				position: absolute;
				right: 0;
				top: 8px;
				font: normal normal normal 14px/1 FontAwesome;
				transition: all 0.3s ease-in-out;
				font-size: 16px;
				height: 30px;
				width: 30px;
				background: rgba(255,255,255,0.2);
				text-align: center;
				line-height: 29px; } }

		&.open {
			> a {
				border-bottom: 1px solid rgba(255,255,255,0.3); }

			.dropdownlink {
				&:before {
					content: "\f107";
					transition: all 0.3s ease-in-out; } } }
		> a {

			font-weight: 500;
			font-size: 16px;
			text-decoration: none;
			text-transform: uppercase;
			padding: 10px 0;
			color: #fff;
			display: block; } }

	.accordion-menu {
		li {
			&.open {
				.dropdownlink {
					color: $color_primary; } }
			&:last-child {
				.dropdownlink {
					border-bottom: 0; } } }

		.fa-angle-down {
			transform: rotate(180deg); } }

	.dropdownlink {
		cursor: pointer;
		color: #fff;
		position: relative;
		transition: all 0.4s ease-out;


		i {
			position: absolute;
			top: 13px;
			left: 16px;
			font-size: 14px; }

		.fa-angle-down {
			right: 12px;
			left: auto; }

		&:last-child {
			border-bottom: none; } }

	.submenuItems {
		display: none;
		background: transparent;
		li {

			&:last-child {
				border-bottom: none; } }

		a {
			display: block;
			color: #FFF;
			transition: all 0.4s ease-out;
			text-decoration: none;
			font-size: 16px;
			padding: 5px 0;
			font-weight: 600; }

		&:hover, &:focus {
			color: $color_primary; } } }

#moble-search {
	border: 1px solid #fff;
	display: flex;
	border-radius: 4px;

	input[type="text"] {
		background: transparent;
		height: 45px;
		color: #fff;
		border: 0;
		padding: 0 10px;
		width: 88%;
		outline: 0;

		&::placeholder {
			color: rgba(255,255,255,0.7); } }

	button[type="submit"] {
		background: transparent;
		border: 0;
		color: #fff; }

	@-webkit-keyframes headerFixedTop {
		0% {
			opacity: 0;
			transform: translateY(-100%); }

		100% {
			opacity: 1;
			transform: translateY(0); } }


	@keyframes headerFixedTop {
		0% {
			opacity: 0;
			transform: translateY(-100%); }

		100% {
			opacity: 1;
			transform: translateY(0); } } }

.mask-overlay {
	content: '';
	position: fixed;
	background: rgba(0 ,0 ,0, .8);
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999; }

.active {
	.mobile-menu-inner {
		left: 0; } }

.close-menu {
	position: absolute;
	right: 20px;
	top: 28px;
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	padding: 5px;

	.bar {
		display: block;
		position: relative;
		margin-bottom: 3px;
		width: 100%;
		height: 2px;
		background-color: #000;
		transition: all 0.25s ease-in-out;

		&:first-child {
			margin-bottom: 5px;
			transform: rotate(-45deg) translate(-9px, 10px); }

		&:last-child {
			transform: rotate(45deg) translate(5px, 4px); } } }

.user-link {
	li {
		a {
			color: #fff;
			i {
				margin-right: 5px; } } } }


.mobile-nav-top-wrap {
	.mob-header-inner {
		padding: 20px;
		background: #fff;

		.mobile-logo {
			margin: 0;
			max-width: 120px;
			float: left;

			a {
				display: block;

				img {
					max-width: 100%; } } } } }


.hide-topbar {
	.top-header {
		display: none; } }

.headroom--pinned {
	-ms-transform: translateY(0);
	transform: translateY(0);
	position: fixed;
	animation-name: headerSlideDown;

	&.hide-topbar {
		.header-inner {
			background: #FFF;
			box-shadow: 0 10px 30px rgba(0,0,0,0.05);

			#main-header-menu {
				li {
					&:after, &:before {
						background: $color_primary; }
					a {
						color: #363636;

						&:hover {
							color: $color_primary; } }

					&.menu-item-has-children {
						.sub-menu {
							background: #fff; } } }

				li.active {
					> a {
						color: $color_primary; } } }

			.nav {
				.head-btn {
					border-color: #d8d1d1;
					color: #363636;

					&:hover {
						color: #fff;
						border-color: $color_primary; } } } } } }


.headroom {
	will-change: transform;
	transition: transform 200ms linear;
	will-change: transform, opacity;
	animation-duration: .5s; }


.headroom--unpinned {
	transform: translateY(-100%);
	position: fixed; }



@-webkit-keyframes headerSlideDown {
	0% {
		margin-top: -150px; }

	100% {
		margin-top: 0; } }




.animated.slideUp {
	-webkit-animation-name: slideUp;
	-moz-animation-name: slideUp;
	-o-animation-name: slideUp;
	animation-name: slideUp; }



@media (max-width: 1440px) {

	#main-header-menu {
		li {
			margin: 0 14px; } }

	.header-inner {
		.nav {
			.head-btn {
				margin-left: 25px; } } } }

@media (max-width: 1199px) {

	#main-header-menu > li::before {
		top: 28px; }

	#main-header-menu > li::after {
		bottom: 28px; }

	#main-header-menu {
		li {
			padding: 25px 0;
			margin: 0 10px;

			a {
				font-size: 14px; } } }

	#site-logo {
		line-height: 75px; }

	.header-inner .nav .head-btn {
		margin-top: 22px; } }

@media (max-width: 1024px) {
	#main-header-menu {
		li {
			padding: 25px 0;
			margin: 0 10px;

			a {
				font-size: 14px; } } }

	#site-logo {
		max-width: 100px;

		a {
			img {
				max-width: 100%; } } }

	.header-inner {
		.nav {
			.head-btn {
				margin-left: 25px;
				margin-top: 22px; } } } }


@media (max-width: 991px) {
	.header {
		display: none; }

	#mobile-nav-wrap {
		display: block;
		width: 100%;
		z-index: 999;
		position: fixed; } }

/* 	#mobile-nav-wrap.headroom--top
		position: relative; */



/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/
.backtotop {
  position: fixed;
  bottom: 25px;
  right: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
  -webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35); }


.backtotop i {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 20px;
  background: #fff;
  margin: 2px;
  color: #050303;
  text-align: center;
  border-radius: 50%; }

