#album {
	padding: 120px 0; }

#album-two {
	padding: 120px 0 110px; }

.album-filter-button {
	margin: 0 auto 50px;
	padding: 0;
	list-style: none;
	max-width: 1000px;
	text-align: center;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		height: 1px;
		border-bottom: 1px dashed #dedede;
		width: 90%;
		top: 50%;
		z-index: -1;
		left: 27px; }


	li {
		display: inline-block;
		height: 60px;
		width: 60px;
		line-height: 58px;
		text-align: center;
		border: 1px dashed #dedede;
		border-radius: 50%;
		margin-left: 160px;
		background: #fff;

		a {
			display: block;
			text-transform: uppercase;
			color: #363636;
			font-weight: 500; }

		&:first-child {
			margin-left: 0 !important; }


		&:hover, &.current {
			background: $color_primary;
			border-color: $color_primary;
			border-style: solid;

			a {
				color: #fff; } } }

	&.album-filter-button-two {
		max-width: 760px;

		&:after {
			width: 90%;
			left: 0; }

		li {
			height: auto;
			width: auto;
			line-height: 20px;
			padding: 10px 15px;
			border-radius: 8px;
			border: 1px dashed #dedede;
			margin-left: 90px;
			transition: all 0.3s ease-in-out;

			&:hover, &.current {
				background: #fff;
				border-color: $color_primary;
				border-style: solid;
				border: 1px dashed $color_primary;

				a {
					color: $color_primary; } } } } }


.tim-album-items {
	margin: 0;
	padding: 0;
	list-style: none;

	.grid-item {
		width: 24.5%;
		padding: 15px;

		&:before {
			left: 15px;
			top: 15px;
			right: 15px;
			bottom: 15px; } } }

.tim-isotope {
	position: relative;
	&.tim-isotope-1 {
		.tim-album-item {
			display: inline-block;
			text-align: center;
			border-radius: 8px;
			position: relative;
			position: relative;
			overflow: hidden;

			img {
				border-radius: 8px;
				box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
				max-width: 100%;
				height: auto; }

			&:before {
				position: absolute;
				content: '';
				left: 15px;
				top: 15px;
				right: 15px;
				bottom: 15px;
				background: rgba(0,0,0, 0.7);
				padding: 10px;
				border-radius: 8px;
				opacity: 0;
				transition: all 0.3s ease-in-out; }

			.album_details_wrap {
				position: absolute;
				left: 0;
				bottom: 45px;
				width: 100%;
				visibility: hidden;
				transition: all 0.3s ease-in-out;
				opacity: 0;

				h4 {
					font-size: 24px;
					font-weight: 500;
					color: #fff;
					padding-bottom: 15px;
					margin-bottom: 15px;
					position: relative;

					&:before, &:after {
						content: '';
						position: absolute;
						background: #fff;
						left: 50%;
						transform: translateX(-50%);
						transition: all 0.3s ease-in-out; }

					&:before {
						width: 30px;
						height: 1px;
						left: 50%;
						bottom: 0; }

					&:after {
						width: 30px;
						height: 5px;
						bottom: -2px; } }


				h5 {
					font-size: 15px;
					font-weight: 500;
					color: #fff;
					margin-bottom: 10px; }

				.tim-btn {
					color: #fff;
					padding: 5px 30px;
					text-transform: uppercase;
					font-size: 16px;
					margin-top: 15px;

					&:hover {
						background: darken($color_primary, 10%); } } }

			&:hover {
				&:before {
					opacity: 1; }
				.album_details_wrap {
					visibility: visible;
					opacity: 1;

					h4 {
						&:before {
							width: 100px; } } } } } }
	&.tim-isotope-2 {
		.tim-album-item {
			position: relative;

			&:before {
				position: absolute;
				content: '';
				left: 15px;
				bottom: 15px;
				right: 15px;
				top: 15px;
				border-radius: 8px;
				background: rgba($color_primary,0.58);
				opacity: 0;
				transition: all 0.3s ease-in-out;
				z-index: 1; }

			.tim-isotope-grid__img {
				border-radius: 8px;
				overflow: hidden;

				img {
					width: 100%;
					border-radius: 8px;
					box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
					transition: all 0.3s ease-in-out; } }

			.popup-modal {
				position: absolute;
				top: 40px;
				left: 40px;
				color: #fff;
				opacity: 0;
				visibility: hidden;
				z-index: 2;
				transform: scale(0);
				transition: all 0.3s ease-in-out; }

			.gallery_details_wrap {
				text-align: left;
				position: absolute;
				left: 40px;
				bottom: 40px;
				z-index: 2;

				.gallery-info {
					opacity: 0;
					visibility: hidden;

					h4 {
						font-size: 26px;
						font-weight: 700;
						color: #fff;
						margin: 0;
						text-transform: uppercase;
						transform: translateY(-15px);
						transition: all 0.3s ease-in-out;
						transition-delay: 100ms;
						opacity: 0; }

					h5 {
						font-size: 15px;
						color: #fff;
						margin: 0;
						transform: translateY(-15px);
						transition: all 0.3s ease-in-out;
						opacity: 0; } } }


			&:hover {
				&:before {
					opacity: 1; }

				.tim-isotope-grid__img {
					img {
						transform: scale(1.1); } }

				.popup-modal, .gallery-info {
					visibility: visible;
					opacity: 1; }

				.popup-modal {
					transform: scale(1); }

				.gallery-info {
					h4, h5 {
						transform: translateY(0);
						opacity: 1; } } } } } }

.grid-item, .grid-sizer {
	width: 25%; }


/* Exclusive Album */
.exclusive-album-items {
	padding: 0;
	.album-item {
		position: relative;
		margin: 0 5px 10px;


		&:before {
			position: absolute;
			content: '';
			height: 100%;
			width: 100%;
			background: rgba($color_primary, 0.5);
			opacity: 0;
			transition: all 0.3s ease-in-out; }

		.album-image {
			img {
				max-width: 100%; }



			.exse-album-name {
				position: absolute;
				top: 0;
				left: 0;

				.icon {
					height: 40px;
					width: 45px;
					line-height: 40px;
					background: $color_primary;
					text-align: center;
					margin-right: 5px;
					display: inline-block;
					color: #fff;
					font-size: 22px;
					vertical-align: top; }

				h4 {
					color: #fff;
					background: $color_primary;
					font-size: 20px;
					font-weight: 600;
					padding: 10px 20px;
					display: inline-block;
					line-height: 20px;
					margin: 0;
					vertical-align: top;
					text-transform: uppercase; }

				&.exse-album-bottom {
					top: auto;
					left: auto;
					bottom: 0;
					right: 0; } } }

		.content {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 10px 20px;

			h4 {
				font-size: 26px;
				color: #fff;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
				line-height: 26px;
				transform: translateY(15px);
				transition: all 0.3s ease-in-out;
				opacity: 0; }

			span {
				font-size: 15px;
				color: #fff;
				text-transform: uppercase;
				transform: translateY(15px);
				transition: all 0.3s ease-in-out;
				transition-delay: 100ms;
				opacity: 0;
				display: inline-block; }

			.icon {
				position: absolute;
				right: 20px;
				top: 23px;
				opacity: 0;
				transition: all 0.3s ease-in-out;

				a {
					color: #fff;
					font-size: 25px;

					&:hover {
						color: $color_primary; } } } }

		&:hover {
			&:before {
				opacity: 1; }

			h4, span {
				transform: translateY(0);
				opacity: 1; }

			.icon {
				opacity: 1; } } } }




@media (max-width: 1280px) {
	.album-filter-button {
		max-width: 600px;

		li {
			margin-left: 70px; }

		&.album-filter-button-two {
			max-width: 760px; }

		&:after {
			width: 90%;
			left: 0; } }

	.tim-album-items {
		.grid-item, .grid-sizer {
			width: 33%; } } }

@media (max-width: 1024px) {
	.album-filter-button {
		max-width: 600px;

		&.album-filter-button-two {
			li {
				margin-left: 60px; }

			&:after {
				left: 30px;
				transform: translateX(0);
				width: 90%; } } } }


@media (max-width: 992px) {
	.album-filter-button {
		max-width: 450px;

		li {
			height: 50px;
			width: 50px;
			line-height: 46px;
			margin-left: 45px; } } }

@media (max-width: 768px) {

	#album {
		padding: 50px 0; }

	.tim-album-items {
		.grid-item, .grid-sizer {
			width: 50%; } }

	.album-filter-button {
		&.album-filter-button-two {
			max-width: 425px;

			&:after {
				display: none; }
			li {
				padding: 8px 12px;
				margin-left: 25px;

				a {
					font-size: 13px; } } } }

	#album-two {
		padding: 60px 0 50px; }

	.exclusive-album-items .album-item .album-image .exse-album-name.exse-album-bottom {
		top: 0;
		left: 0; }

	#gallery .section-title h2 {
		margin-bottom: 0; }

	#gallery .album-filter-button {
		margin: 0 auto 20px; }


	.album-filter-button {
		margin: 0 auto 20px; } }



@media (max-width: 580px) {

	.album-filter-button {
		max-width: 330px;

		&:after {
			left: 40px;
			width: 76%; }

		li {
			margin-left: 9px;
			height: 40px;
			width: 40px;
			line-height: 37px;

			a {
				font-size: 10px; } }

		&.album-filter-button-two {
			max-width: 500px; } } }

@media (max-width: 500px) {
	.tim-album-items {
		.grid-item {
			width: 100%; } }

	.album-filter-button {
		&.album-filter-button-two {
			li {
				margin-left: 10px;
				margin-bottom: 10px; } } } }
