.banner-slider-one {
	height: 100vh;

	.swiper-slide {
		background-size: cover;

		.slider-content {
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: center;
			transform: translate(-50%, -25%);
			width: 100%;

			h3 {
				font-size: 40px;
				color: #fff;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
				line-height: 40px; }

			h2 {
				color: #fff;
				font-size: 70px;
				font-weight: 800;
				text-transform: uppercase;
				line-height: 70px;
				margin-bottom: 20px; }

			p {
				color: #fff;
				font-size: 18px;
				line-height: 28px;
				margin-bottom: 40px; } } } }


.tim-slide-btn {
	padding: 18px 40px;
	font-size: 16px;
	color: #fff;
	border: 1px solid #fff;
	font-size: 17px;
	line-height: 18px;
	display: inline-block;
	border-radius: 4px;
	margin-right: 10px;
	margin-bottom: 2px;


	i {
		margin-right: 5px; }

	&:hover {
		color: #fff;
		background: rgba(109,18,66,0.73); } }

.banner-slider-two {
	height: 100vh;

	.swiper-slide {
		background-size: cover;

		.slider-content-two {
			position: absolute;
			top: 65%;
			width: 100%;
			transform: translateY(-50%);
			text-align: center;
			left: 10%;

			img {
				float: left;
				transform: translateY(-100px); }

			h3 {
				font-size: 40px;
				color: #fff;
				line-height: 40px;
				font-weight: 600; }

			h2 {
				color: #fff;
				font-size: 80px;
				font-weight: 700;
				line-height: 70px;
				margin-bottom: 20px; }

			p {
				color: #fff;
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 30px; }

			.tim-btn {
				padding: 13px 37px;
				font-size: 16px;
				background: transparent;
				border: 1px solid #fff;
				color: #fff;
				text-transform: uppercase;
				margin-right: 20px;

				&:hover {
					color: #fff;
					background: $color_primary;
					border-color: $color_primary; } }

			&.content-three {
				width: 80%;

				h3 {
					font-size: 40px;
					font-weight: 500;
					color: $color_primary;
					line-height: 40px; }

				h2 {
					font-size: 70px;
					line-height: 70px; } } } } }

.slider-nav-prev, .slider-nav-next {
	width: 60px;
	height: 150px;
	line-height: 150px;
	text-align: center;
	color: #fff;
	background: rgba(0,0,0,0.2);
	z-index: 999;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 35px; }

.slider-nav-next {
	right: 0; }



/* Music Player */
.header_player {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: rgba(0,0,0,0.8);
	z-index: 111;

	button {
		background: transparent;
		border: 0;
		outline: 0; }

	.player-container {
		max-width: 1200px;
		margin: 0 auto; }

	.jp-audio {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		.jp-type-playlist {
			display: block;
			position: relative;
			height: 100%;

			.jp-interface {
				padding: 36px 0;

				> div {
					float: left; }

				.jp-controls {
					text-align: center;
					width: 170px;
					padding-top: 0;
					margin-top: -15px;

					button {
						display: inline-block;
						background: none;
						border: none;
						outline: none;
						cursor: pointer;
						margin: 0 3px;

						i {
							width: 20px;
							text-align: center;
							color: #fff;
							font-size: 18px;
							line-height: 18px; } }

					.jp-play {
						height: 60px;
						width: 60px;
						border: 2px solid #fff;
						border-radius: 50%;
						text-align: center;
						padding-left: 2px;

						i {

							font-size: 20px;
							line-height: 40px;
							color: $color_primary;
							margin-left: 6px; } } }

				.jp-playlist {
					display: none;
					position: absolute;
					bottom: 100px;
					right: 0;
					width: 100%;
					height: 300px;
					overflow-y: scroll;
					overflow-x: hidden;
					background: rgba(0,0,0,0.95);
					padding: 15px;

					ul {
						padding: 0;
						margin: 0;

						li {
							display: block;
							border-bottom: 1px solid #333;
							padding: 5px 30px;
							transition: all 0.2s linear;

							&:last-child {
								border: none; }

							&:hover {
								background: $color_primary; }


							> div {
								display: block;
								position: relative;
								padding-right: 20px;


								.jp-free-media {
									display: block;
									float: left;
									font-style: normal;
									font-weight: 400;
									font-size: 13px;
									line-height: 13px;
									color: #333333;
									text-decoration: none;
									outline: 0;
									padding: 10px 0;
									padding-right: 10px;

									a {
										color: #fff;
										text-decoration: none; } }

								> a {
									display: block;
									overflow: hidden;
									white-space: nowrap;
									font-style: normal;
									font-weight: 500;
									font-size: 16px;
									line-height: 16px;
									color: #fff;
									text-decoration: none;
									outline: 0;
									padding: 14px 0;


									&.jp-playlist-item-remove {
										display: block;
										position: absolute;
										width: 10px;
										height: 10px;
										top: 0;
										right: 0; }


									.jp-artist {
										font-style: normal; } } }


							&.jp-playlist-current {
								background: $color_primary;
								div {
									> a {
										color: #fff; } } } } } }

				.jp-progress {
					z-index: 30;
					margin-top: 10px;
					overflow: hidden;
					height: 8px;
					max-width: 32%;
					width: 100%;
					background-color: rgba(255, 255, 255, 0.9);



					.jp-seek-bar {
						width: 0px;
						height: 100%;
						cursor: pointer;


						.jp-play-bar {
							background: $color_primary;
							width: 0px;
							height: 100%; } } }

				.jp-duration {
					color: #fff;
					font-family: $font_heading;
					margin-left: 10px; }

				.vel-wrap {
					width: 140px;
					margin-left: 20px;

					.jp-mute {
						color: #fff;
						width: 20px;
						text-align: left; } } }


			.jp-no-solution {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #333333;
				padding: 10px;
				background: #ffffff;


				span {
					font-weight: 700;
					color: $color_primary; } } }

		.jp-volume-bar {
			display: inline-block;
			position: relative;
			width: 65%;
			height: 5px;
			background: #fff;
			margin-bottom: 4px;
			cursor: pointer;

			.jp-volume-bar-value {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				background: $color_primary; } }

		&.jp-state-playing {
			.jp-play {
				i {
					&:before {
						content: "\f04c" !important; } } } }


		&.jp-state-muted {
			.jp-mute {
				i {
					color: #fff;

					&:before {
						content: "\f026" !important; } } } }



		&.single-album-player {
			display: block;
			position: relative;
			width: 100%;
			height: auto;
			box-sizing: border-box;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			background: #ffffff;
			border: 1px solid #e6e6e6;


			.jp-type-playlist {
				display: block;
				height: auto;
				background: #ffffff; } }

		#playlist-toggle {
			i {
				color: #fff; }
			&.close-icon {
				i {
					&:before {
						content: "\f00d"; } } } } }

	#nowPlaying {
		overflow: hidden;
		font-style: normal;
		color: #ffffff;
		padding: 21px 0 21px 15px;

		.track-name {
			font-size: 26px;
			font-family: $font_heading;
			color: #fff;
			margin: 0;
			font-weight: 700; } } }

.current-tracks {
	float: left;
	width: 365px;
	position: relative;

	&:after {
		position: absolute;
		top: 20px;
		right: 0;
		content: '';
		height: 60px;
		width: 1px;
		background: rgba(255,255,255,0.5); } }

#main_player {
	width: 100px !important;
	height: 100px !important;
	float: left;
	transform: translateY(-25px);


	img {
		width: 100% !important;
		height: 100% !important; } }




@media (max-width: 1440px) {
	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				max-width: 80%;
				img {
					max-width: 380px; }

				h2 {
					font-size: 60px;
					line-height: 55px; }

				p {
					br {
						display: none; } }

				&.content-three {
					h3 {
						font-size: 30px; }

					h2 {
						font-size: 55px;
						line-height: 60px; } } } } } }


@media (max-width: 1280px) {
	.header_player {
		.jp-audio {
			.jp-type-playlist {
				.jp-interface {
					.jp-progress {
						max-width: 21%; }

					.vel-wrap {
						width: 115px; } } } }
		#nowPlaying {
			.track-name {
				font-size: 20px; } } }

	.current-tracks {
		width: 320px; }

	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				&.content-three {
					h2 {
						font-size: 50px;
						line-height: 55px;
						margin-bottom: 15px; } } } } }

	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				img {
					max-width: 300px; } } } } }


@media (max-width: 1024px) {
	.banner-slider-one {
		.swiper-slide {
			.slider-content {
				h3 {
					font-size: 40px;
					line-height: 40px; }
				h2 {
					font-size: 55px;
					line-height: 55px; } } } }

	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				top: 60%;

				h2 {
					font-size: 50px; }

				&.content-three {
					h2 {
						font-size: 50px; } } } } } }

@media (max-width: 992px) {
	.header_player {
		.player-container {
			max-width: 500px;
			margin: 0 auto; } }

	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				top: 45%;

				img {
					max-width: 280px;
					transform: translateY(-10px); }

				h2 {
					font-size: 40px;
					line-height: 45px;
					margin-bottom: 10px; }

				h3 {
					font-size: 22px;
					margin-bottom: 0;
					line-height: 22px; }

				p {
					margin-bottom: 15px; }

				.tim-btn {
					padding: 7px 15px; }

				.video-btn-two {
					padding: 7px 15px;

					i {
						font-size: 18px;

						&:after {
							height: 15px; } } } } } }

	.header_player {
		.jp-audio {
			.jp-type-playlist {
				.jp-interface {
					.jp-controls {
						width: 145px;
						margin-top: -8px;

						.jp-play {
							height: 40px;
							width: 40px;

							i {
								line-height: 38px; } } }

					.jp-progress {
						max-width: 35%; }

					.vel-wrap {
						width: 70px; } } } } }

	.current-tracks {
		display: none; }


	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {

				img {
					max-width: 200px; }

				&.content-three {
					h3 {
						font-size: 25px;
						line-height: 30px; }

					h2 {
						font-size: 35px;
						margin-bottom: 10px;
						line-height: 40px; }

					p {
						font-size: 14px;
						margin-bottom: 15px;
						line-height: 20px; }

					.store-btn {
						padding: 6px 15px;

						i {
							font-size: 20px; } } } } } } }


@media (max-width: 768px) {

	.banner-slider-one {
		.swiper-slide {
			.slider-content {
				h3 {
					font-size: 28px;
					line-height: 28px; }

				h2 {
					font-size: 35px;
					line-height: 35px; }

				p {
					max-width: 500px;
					margin: 0 auto 30px;

					br {
						display: none; } } } } }

	.tim-slide-btn {
		padding: 13px 15px;
		font-size: 14px; }

	.slider-nav-prev, .slider-nav-next {
		width: 30px;
		height: 100px;
		line-height: 100px; }

	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				width: 90%;
				left: 0;

				img {
					max-width: 180px; }

				h2 {
					font-size: 35px;
					line-height: 40px; }

				p {
					display: none; }

				.tim-btn {
					margin-right: 8px;
					margin-top: 20px; }

				.tim-btn, .video-btn-two {
					padding: 10px 14px;
					font-size: 14px; } } } }

	.header_player {
		.jp-audio {
			.jp-type-playlist {
				.jp-interface {
					.jp-playlist {
						width: 400px; }

					.jp-progress {
						width: 30%; } } } } }

	.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
		width: 140px; }



	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				transform: translateY(-65%);

				&.content-three {
					h3 {
						font-size: 24px;
						line-height: 24px; }
					h2 {
						font-size: 30px;
						line-height: 40px;
						margin-bottom: 20px; }

					.store-btn {
						margin-bottom: 10px;
						padding: 5px 16px;
						font-size: 14px;

						i {
							font-size: 18px; } } } } } } }



@media (max-width: 500px) {

	.banner-slider-one {
		.swiper-slide {
			.slider-content {

				h3 {
					font-size: 20px;
					line-height: 20px;
					margin-bottom: 10px; }

				h2 {
					font-size: 25px;
					line-height: 20px; }

				p {
					max-width: 300px;
					font-size: 16px;
					margin: 0 auto 20px; } } } }

	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				transform: translateY(-65%);

				&.text-left {
					text-align: center !important; }

				img {
					max-width: 150px; }

				h2 {
					font-size: 26px;
					line-height: 30px;
					margin-bottom: 15px; }

				.video-btn-two, .tim-btn {
					width: 150px;
					padding: 10px;
					text-align: center;
					margin-right: 0;
					margin-top: {} }

				.tim-btn {
					margin-top: 10px; }

				&.content-three {
					h3 {
						font-size: 24px;
						line-height: 24px; }
					h2 {
						font-size: 26px;
						line-height: 40px;
						margin-bottom: 20px; }

					.store-btn {
						margin-bottom: 10px;
						margin-left: 0 !important;
						padding: 5px 15px; } } } } }


	.tim-slide-btn {
		display: block;
		max-width: 200px;
		margin: 0 auto 10px; }

	.slider-nav-prev, .slider-nav-next {
		width: 30px;
		height: 100px;
		line-height: 100px;
		font-size: 28px; }


	.banner-slider-two {
		text-align: center !important;
		.swiper-slide {
			.slider-content-two {
				img {
					float: none;
					margin: 0 auto; } } } }

	.header_player {
		.jp-audio {
			.jp-type-playlist {
				.jp-interface {
					padding: 20px 0;

					.jp-playlist {
						width: 100%;
						bottom: 70px;

						ul {
							li {
								padding: 0 15px;

								> div {
									> a {
										font-size: 12px; } } } } }
					.jp-progress {
						width: 29%; }

					.jp-controls {
						width: 120px;
						top: -4px;

						.jp-play {
							height: 35px;
							width: 35px;
							margin: 0;

							i {
								font-size: 15px;
								line-height: 32px; } }

						button {
							margin: 0;
							i {
								font-size: 15px; } } } }

				.vel-wrap {
					display: none; } } } }


	.banner-slider-two {
		.swiper-slide {
			.slider-content-two {
				width: 100%;
				left: 10%; } } } }

@media (max-width: 378px) {

	.banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
		margin-top: 10px; } }


@media (min-width: 501px) and (max-width: 555px) {

	.banner-slider-two .swiper-slide .slider-content-two .tim-btn, .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
		padding: 7px 5px;
		font-size: 14px; }

	.banner-slider-two .swiper-slide .slider-content-two {
		width: 100%;
		left: 0; }

	.banner-slider-two .swiper-slide .slider-content-two img {
		max-width: 170px; } }


