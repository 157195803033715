.concerts {
	background: #fff;
	box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 35px 0;
	margin-bottom: 40px;

	&:last-child {
		margin-bottom: 0; } }

.concerts-count {
	width: 26%;
	height: 75px;
	padding: 0 50px; }


.concerts {
	display: flex;
	align-items: center;

	.countdown {
		background: $color_primary;
		border-radius: 5px;
		height: 75px;
		padding: 15px 0;

		.CountdownContent {
			width: 25%;
			display: inline-block;
			text-align: center;
			font-size: 24px;
			color: #fff;
			font-weight: 500;
			position: relative;

			&:after {
				content: ':';
				position: absolute;
				right: -5px;
				color: #fff;
				top: 0; }

			&:last-child {
				&:after {
					display: none; } }

			.CountdownLabel {
				display: block;
				font-size: 12px;
				font-weight: 300; } } }


	.concert-details {
		width: 40%;
		padding: 0 65px;
		border-left: 1px solid #e1e1e1;
		border-right: 1px solid #e1e1e1;


		h3 {
			font-size: 18px;
			font-weight: 500;
			color: #363636; }

		h2 {
			color: #393838;
			font-size: 24px;
			margin: 0; }

		p {
			font-size: 16px;
			color: #363636;
			margin-bottom: 0; } }

	// .ticket
	// 	width: 34%

	.ticket {
		display: flex;
		align-items: center;
		padding: 0 20px;
		justify-content: center;
		width: 34%;

		.tic-btn {
			padding: 10px 24px;
			color: #343434;
			font-size: 20px;
			font-weight: 500;
			text-transform: uppercase;
			margin-right: 30px;
			border-radius: 4px;
			font-family: $font_heading;
			border: 1px solid #a2a2a2;

			&:focus {
				outline: none; }


			&:last-child {
				margin-right: 0; }



			&:hover {
				background: #444;
				color: #fff; }

			&.tic-btn-bg {
				background: $color_primary;
				color: #fff;
				border: transparent;

				&:hover {
					background: #444;
					color: #fff; } } } }




	&.concerts-two {
		background: transparent;
		box-shadow: none;
		border: 1px dashed #d9d9d9;
		transition: all 0.3s ease-in-out;

		.concerts-count {
			width: 25%;
			padding: 0 50px 0 0;

			.countdown {
				background: transparent;
				border: 1px dashed #cacaca;
				padding: 8px 0;

				.CountdownContent {
					color: #343434;
					&:after {
						color: #343434; }
					.CountdownLabel {
						color: #343434;
						font-size: 14px;
						font-weight: 500; } } } }


		.concert-details {
			padding: 0 30px;
			border: 0;
			display: flex;
			align-items: center;
			width: 40%;

			img {

				width: 125px;
				height: 125px;
				box-shadow: 0px 7px 17.43px 3.57px rgba(88, 88, 88, 0.14);
				border-radius: 4px; }

			.content {
				margin-left: 30px; } }

		.ticket {
			width: 35%; }

		&:hover {
			background: rgba(255,255,255,0.25);

			.concerts-count {

				.countdown {
					background: transparent; } } } } }


/* Upcomming Concert Three */
.upc-con-three {
	text-align: center;
	border-radius: 10px;
	position: relative;

	.cons-feature-image {
		position: relative;

		img {
			max-width: 100%; } }

	.content-wrap, .upc-count-wrap {}

	.content-wrap {
		height: inherit;
		position: absolute;
		top: 0;
		z-index: 900;
		text-align: center;
		-webkit-transform: rotateX(0deg) rotateY(0deg);
		-moz-transform: rotateX(0deg) rotateY(0deg);
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		transition: all .4s ease-in-out;
		// box-shadow: 0px 7px 34.2px 1.8px rgba(0, 0, 0, 0.1)


		.content {
			padding: 30px;

			h4 {
				font-size: 14px;
				font-weight: 500; }


			h3 {
				font-size: 20px;
				font-weight: 700;
				color: $color_primary;
				text-transform: uppercase; }

			p {
				margin: 0; } } }

	.upc-count-wrap {
		background: #363636;
		padding: 150px 0;
		height: inherit;
		position: absolute;
		top: 0;
		z-index: 1000;
		-webkit-transform: rotateY(-180deg) {
		   -moz-transform: rotateY(-180deg); }
		-webkit-transform-style: preserve-3d {
		   -moz-transform-style: preserve-3d; }
		-webkit-backface-visibility: hidden {
		   -moz-backface-visibility: hidden; }
		transition: all .4s ease-in-out;
		width: 100%;
		height: 100%;

		.countdown {
			margin-bottom: 20px;

			.CountdownContent {
				display: inline-block;
				color: #fff;
				font-size: 22px;
				font-family: $font_heading;
				padding: 0 12px;
				position: relative;
				line-height: 20px;
				font-weight: 500;

				&:after {
					position: absolute;
					content: '';
					height: 25px;
					width: 1px;
					right: 0;
					top: 7px;
					background: #fff; }


				&:last-child {
					&:after {
						display: none; } }

				.CountdownLabel {
					display: block;
					font-size: 14px;
					font-weight: 400; } } }

		.tic-btn {
			padding: 14px 22px;
			color: #fff;
			font-size: 15px;
			line-height: 1;
			font-weight: 500;
			text-transform: uppercase;
			border-radius: 4px;
			font-family: $font_heading;
			display: inline-block;
			border: 1px solid #fff;

			&:hover {
				background: $color_primary;
				color: #fff;
				border-color: $color_primary; }

			&.tic-btn-bg {
				margin-left: 15px; } } } }


.upc-con-three {
	margin: 0 auto;
	height: 420px;
	position: relative;
	-webkit-perspective: 600px;
	-moz-perspective: 600px; }



.upc-con-three .content-wrap,
.upc-con-three .upc-count-wrap {
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0px 7px 34.2px 1.8px rgba(0, 0, 0, 0.1); }



.upc-con-three.flip .content-wrap {
	z-index: 900;
	transform: rotateY(180deg); }


.upc-con-three.flip .upc-count-wrap {
	z-index: 1000;
	-webkit-transform: rotateX(0deg) rotateY(0deg);
	-moz-transform: rotateX(0deg) rotateY(0deg); }


@media (max-width: 1440px) {

	.concerts-count {
		padding: 0 30px; }

	.concerts {
		.concert-details {
			padding: 0 40px;

			h2 {
				font-size: 20px; } }

		.ticket {
			.tic-btn {
				padding: 10px 20px;
				font-size: 16px; } } } }

@media (min-width: 992px) and (max-width: 1200px) {
	.concerts {
		&.concerts-two {
			.concerts-count {
				padding: 0 0 0 30px; } }

		.ticket {
			padding: 0;
			.tic-btn {
				padding: 8px 15px;
				font-size: 15px; } }

		&.concerts-two {
			padding: 15px 0;

			.concerts-count {
				padding: 0 15px 0 0;
				width: 30%; }

			.ticket {
				width: 27%; }

			.concert-details {
				width: 58%;
				padding: 0 15px; } } }
	.concerts .ticket .tic-btn {
		margin-right: 9px; } }


@media (max-width: 991px) {

	.concerts {
		display: block;
		&.concerts-two {
			.concerts-count, .concert-details, .ticket {
				width: 100%;
				max-width: 500px;
				margin: 0 auto 30px; }

			.concerts-count {
				padding: 0 20px;
				margin-top: 20px; }

			.ticket {
				margin-bottom: 0; } } }

	.upc-con-three {
		margin-bottom: 30px; } }




@media (min-width: 768px) and (max-width: 1024px) {
	.concerts-count {
		padding: 0 15px; }

	.concerts {
		.countdown {
			.CountdownContent {
				font-size: 20px;

				&:after {
					right: -3px; }

				.CountdownLabel {
					font-size: 10px; } } }

		.concert-details {
			padding: 0 20px;

			h3 {
				font-size: 14px; }

			h2 {
				font-size: 16px; } }

		.ticket {
			.tic-btn {
				padding: 8px 14px;
				font-size: 13px; } } } }

@media (max-width: 768px) {
	.section-padding {
		padding: 50px 0; }

	.concerts {
		display: block !important;

		.concerts-count, .concert-details, .ticket {
			max-width: 550px;
			width: 100%;
			margin: 0 auto; }

		.concerts-count {
			margin: 0 auto 20px;
			padding: 0 15px;
			width: 100%; }


		.concert-details {
			padding: 20px 15px;
			border-left: 0;
			border-right: 0;
			border-top: 1px solid #e1e1e1;
			border-bottom: 1px solid #e1e1e1;
			text-align: center; }

		.ticket {
			margin: 20px auto 0; } }

	.concerts.concerts-two .concerts-count {
		margin: 20px auto 0px; }

	.upc-con-three .upc-count-wrap .tic-btn {
		padding: 14px 11px; } }

@media (min-width: 500px) and (max-width: 576px) {
	.upc-con-three {
		height: 480px; } }




@media (max-width: 500px) {
	.concerts {
		&.concerts-two {
			.concert-details {
				padding: 0;
				display: block;

				.content {
					margin: 10px 0 0 0; } }

			.ticket {
				margin: 50px 0; } } }

	.concerts {
		.ticket {
			.tic-btn {
				padding: 10px 14px;
				font-size: 15px; } } }

	.upc-con-three {
		height: 430px; }

	.concerts.concerts-two .concerts-count .countdown {
		border: none; } }








