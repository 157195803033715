/*!
  Theme Name: Music Studio
  Theme URI: http://www.themeim.com/demo/music-studio/
  Author: ThemeIm
  Author URI: http://www.themeim.com/
  Description: Music Studio HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Tags: music, musician, singer, audio
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Tranding Album
    ## Artist
    ## Upcomiong Concerts
    ## Ticket
    ## Ultimate Tabs
    ## Intro Video
    ## Blog
    ## Widgets
    ## Logo Carousel
    ## lesson
    ## About
    ## Event About
    ## Sale Album
    ## Album
    ## Artist Profiles
    ## Featured Album
    ## Newsletter
    ## Featured Album
    ## Sticky Audio Player
    ## Event Schedule
    ## Recent Performance
    ## Pricing
    ## Tabs
    ## Gallery
    ## Tabs
    ## Page Header
    ## Woocommerce
    ## Page Header
    ## woocommers
    ## Footer

  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Google Web Fonts
  --------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Changa:200,300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
/* Primary Color */
body {
  font-size: 16px;
  line-height: 28px;
  color: #494848;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

h1, h2, h3, h4, h5, h6, p, span, div {
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust-font-variant-ligatures: none;
  -webkit-text-size-adjust--webkit-font-variant-ligatures: none;
  -webkit-text-size-adjust-text-rendering: optimizeLegibility;
  -webkit-text-size-adjust--moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust-font-smoothing: antialiased;
  -webkit-text-size-adjust--webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  -webkit-text-size-adjust-font-size: 100%;
}

/* GLOBAL SETTINGS */
a {
  transition: all 0.15s ease-in-out;
}

a:hover {
  text-decoration: none;
}

ul, ol, figure {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.tim-container-box {
  width: 1200px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1600px) {
  .tim-container {
    padding: 0 6.5%;
  }
}

@media (max-width: 1599px) {
  .tim-container {
    padding: 0 5%;
  }
}

#site {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1400px;
}

.container.con-pd {
  padding: 0 125px;
}

.section-padding {
  padding: 120px 0;
}

/* Reset */
.pt__100 {
  padding-top: 100px;
}

.pb__100px {
  padding-bottom: 100px;
}

.pr__30 {
  padding-right: 30px;
}

.pl__30 {
  padding-left: 30px;
}

.ml__15 {
  margin-left: 15px;
}

.pdlr {
  padding: 0 100px;
}

.pt__110 {
  padding-top: 110px;
}

.pt_70 {
  padding-top: 70px;
}

.mar-btn-30 {
  margin-bottom: 30px !important;
}

/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6 {
  font-family: "Changa", sans-serif;
}

.section-title {
  margin-bottom: 50px;
}

.section-title h2 {
  font-size: 40px;
  line-height: 26px;
  color: #444;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 23px;
  padding-bottom: 25px;
  position: relative;
  display: inline-block;
}

.section-title h2:before, .section-title h2:after {
  position: absolute;
  content: '';
  bottom: 0;
  background: #e43a90;
  left: 50%;
  transform: translateX(-50%);
}

.section-title h2:before {
  width: 200px;
  height: 1px;
}

.section-title h2:after {
  height: 3px;
  bottom: -1px;
  width: 30px;
  left: 50%;
}

.section-title h2 span {
  color: #e43a90;
}

.section-title p {
  color: #494848;
  font-size: 16px;
  margin: 0;
}

.section-title.title-two h2 {
  margin-bottom: 30px;
}

.section-title.title-two h2:before {
  display: none;
}

.section-title.title-two h2:after {
  background-image: url(../../media/background/2.png);
  background: transparent;
  height: 25px;
  max-width: 320px;
  background-repeat: no-repeat;
  width: 100%;
  left: 50%;
  bottom: -20px;
}

.section-title.title-three h2:before {
  background-image: url(../img/dm2.png);
  background-color: transparent;
  height: 15px;
  width: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -8px;
}

.section-title.title-three h2:after {
  display: none;
}

.section-dark .section-title h2 {
  color: #fff;
}

.section-dark .section-title p {
  color: #fff;
}

.section-dark .section-title.title-three h2:before {
  background-image: url(../img/dm2l.png);
}

/* Button */
.tim-btn {
  padding: 10px 41px;
  border: 1px solid #e4e4e4;
  color: #363636;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
  border-radius: 5px;
}

.tim-btn:hover {
  color: #e43a90;
}

.tim-btn.tim-btn-bgt {
  border: 1px solid #e4e4e4;
  color: #363636;
}

.tim-btn.tim-btn-bgt:hover {
  color: #fff;
}

.video-btn-two {
  padding: 13px 37px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.video-btn-two i {
  margin-right: 20px;
  color: #e43a90;
  vertical-align: middle;
  font-size: 24px;
  position: relative;
}

.video-btn-two i:after {
  position: absolute;
  content: '';
  height: 20px;
  width: 1px;
  background: #7c7d81;
  right: -14px;
  top: 2px;
}

.video-btn-two:hover {
  background: #e43a90;
  border-color: #e43a90;
  color: #fff;
}

.video-btn-two:hover i {
  color: #fff;
}

.video-btn-two:hover i:after {
  background: #fff;
}

.store-btn {
  padding: 10px 25px;
  color: #fff;
  border: 2px solid #fff;
  font-weight: 600;
  border-radius: 4px;
  font-size: 18px;
  vertical-align: top;
  display: inline-block;
}

.store-btn i {
  font-size: 25px;
  margin-right: 7px;
}

.store-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

/* Flat Icons */
@font-face {
  font-family: tim-icon;
  src: url("../fonts/tim-icon.eot");
  src: url("../fonts/tim-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/tim-icon.woff2") format("woff2"), url("../fonts/tim-icon.woff") format("woff"), url("../fonts/tim-icon.ttf") format("truetype"), url("../fonts/tim-icon.svg#tim-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: tim-icon;
    src: url("../fonts/tim-icon.svg#tim-icon") format("svg");
  }
}

[class^="tim-"]:before, [class*=" tim-"]:before,
[class^="tim-"]:after, [class*=" tim-"]:after {
  font-family: tim-icon;
  font-style: normal;
}

.Switcher__control {
  background-image: none;
  font-size: 25px;
  outline: 0 !important;
}

/* Flat Icons */
.tim-apple-logo:before {
  content: "\f100";
}

.tim-playstore:before {
  content: "\f101";
}

.tim-technology:before {
  content: "\f102";
}

.tim-photo:before {
  content: "\f103";
}

.tim-photo-camera:before {
  content: "\f104";
}

.tim-cross-out:before {
  content: "\f105";
}

.tim-repeat:before {
  content: "\f106";
}

.tim-favorite:before {
  content: "\f107";
}

.tim-eye:before {
  content: "\f108";
}

.tim-headphones:before {
  content: "\f109";
}

.tim-sound-frecuency:before {
  content: "\f10a";
}

.tim-code-rate:before {
  content: "\f10b";
}

.tim-slide:before {
  content: "\f10c";
}

.tim-music-player-play:before {
  content: "\f10d";
}

.tim-piano-keys:before {
  content: "\f10e";
}

.tim-piano-keyboard:before {
  content: "\f10f";
}

.tim-heart-outline:before {
  content: "\f110";
}

.tim-favorite-heart-button:before {
  content: "\f111";
}

.tim-drum-set-cartoon-variant:before {
  content: "\f112";
}

.tim-music-album-1:before {
  content: "\f113";
}

.tim-electric-guitar-music-instrument:before {
  content: "\f114";
}

.tim-cogwheel:before {
  content: "\f115";
}

.tim-pause-button:before {
  content: "\f116";
}

.tim-woofer-speaker:before {
  content: "\f117";
}

.tim-layers:before {
  content: "\f118";
}

.tim-view:before {
  content: "\f119";
}

.tim-guitar:before {
  content: "\f11a";
}

.tim-commerce:before {
  content: "\f11b";
}

.tim-support:before {
  content: "\f11c";
}

.tim-shopping-cart-1:before {
  content: "\f11d";
}

.tim-music:before {
  content: "\f11e";
}

.tim-newsletter:before {
  content: "\f11f";
}

.tim-tabs:before {
  content: "\f120";
}

.tim-monitor:before {
  content: "\f121";
}

.tim-music-album:before {
  content: "\f122";
}

.tim-shopping-cart:before {
  content: "\f123";
}

.tim-flute:before {
  content: "\f124";
}

.tim-keyboard:before {
  content: "\f125";
}

.tim-arrows:before {
  content: "\f126";
}

.tim-layout:before {
  content: "\f127";
}

.tim-zoom-out:before {
  content: "\f128";
}

.tim-computer:before {
  content: "\f129";
}

.tim-music-1:before {
  content: "\f12a";
}

.loader.is-active {
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.loader-bar-ping-pong::before {
  background-color: #e7e3e3;
  border-radius: 16px;
  display: none;
}

.loader-bar-ping-pong::after, .loader-bar-ping-pong::before {
  content: "";
  height: 2px;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 100px);
}

@media (max-width: 768px) {
  .section-padding {
    padding: 50px 0;
  }
  .section-title h2 {
    font-size: 30px;
  }
  .section-title p br {
    display: none;
  }
  .pt__100 {
    padding-top: 50px;
  }
  .pt__110 {
    padding-top: 50px;
  }
}

@media (max-width: 500px) {
  .col-full-width {
    max-width: 400px;
    margin: 0 auto;
    flex: 0 0 100%;
  }
  .container.con-pd {
    padding: 0;
  }
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header .top-header {
  background: rgba(228, 58, 144, 0.65);
}

.header.header-two .top-header {
  position: relative;
  background: #fff;
}

.header.header-two .top-header .skew-bg {
  background: #e43a90;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 70%;
  height: 50px;
}

.header.header-two .top-header .skew-bg:before {
  position: absolute;
  right: -40px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 40px;
  border-color: transparent transparent transparent #e43a90;
}

.header.header-two .top-header .user-login > li:after {
  background: rgba(0, 0, 0, 0.3);
}

.header.header-two .top-header .user-login li > a {
  color: #363636;
}

.header.header-two .top-header .user-login li > a i {
  color: #363636;
}

.header.header-two .top-header .user-login li > a:hover {
  color: #e43a90;
}

.header.header-two .top-header .user-login li.cart-count .badge {
  color: #fff;
}

.header.header-two .header-inner {
  background: rgba(228, 58, 144, 0.2);
}

.site-social-link {
  list-style: none;
  display: inline-block;
  position: relative;
}

.site-social-link li {
  display: inline-block;
  padding: 11px 0;
}

.site-social-link li a {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-right: 15px;
}

.site-social-link li a:hover {
  color: #363636;
}

/* Header style three */
/* Top Header Right With Mini Cart */
.user-login {
  list-style: none;
}

.user-login > li {
  display: inline-block;
  position: relative;
  margin-right: 30px;
  font-family: "Changa", sans-serif;
  padding: 10px 0;
}

.user-login > li:after {
  position: absolute;
  content: '';
  height: 15px;
  width: 2px;
  background: rgba(255, 255, 255, 0.3);
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
}

.user-login li.cart-count {
  position: relative;
}

.user-login li.cart-count .badge {
  position: absolute;
  top: 10px;
  left: 8px;
  background: #363636;
  border-radius: 50%;
  line-height: 16px;
  width: 17px;
  font-size: 11px;
  height: 17px;
  display: block;
  padding: 0;
  font-weight: 500;
  text-align: center;
}

.user-login li.cart-count .cart-overview {
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  line-height: 1.8;
  position: absolute;
  background: #FFF;
  text-align: left;
  width: 350px;
  padding: 15px;
  margin-top: 0;
  right: 0;
  top: 140%;
  transition: all 0.3s ease-in-out;
}

.user-login li.cart-count .cart-overview .cart-item {
  margin-bottom: 10px;
  display: block;
  position: relative;
  margin-right: 0;
  padding: 0;
}

.user-login li.cart-count .cart-overview .cart-item .product-thumbnail {
  margin-right: 1.3em;
  float: left;
  padding: 0 !important;
  display: inline-block;
  width: 70px;
}

.user-login li.cart-count .cart-overview .cart-item .product-thumbnail img {
  max-width: 100%;
}

.user-login li.cart-count .cart-overview .cart-item .product-details {
  position: relative;
  margin-left: 70px;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: block;
  text-decoration: none;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-title:hover {
  color: #e43a90;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-remove {
  position: absolute;
  right: 0;
  top: 42%;
  height: 18px;
  width: 18px;
  font-size: 6px;
  background: #3e3e3e;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  color: #f3f3f3;
  transition: all 0.3s ease-in-out;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-remove:hover {
  background: #444;
  color: #FFF;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-quantity {
  display: inline-block;
}

.user-login li.cart-count .cart-overview .cart-subtotal {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.user-login li.cart-count .cart-overview .cart-subtotal .amount {
  float: right;
}

.user-login li.cart-count .cart-overview .cart-actions {
  display: block;
  padding-top: 20px;
}

.user-login li.cart-count .cart-overview .cart-actions .view-cart, .user-login li.cart-count .cart-overview .cart-actions .checkout {
  padding: 13px 25px !important;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 16px !important;
  display: inline-block !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.user-login li.cart-count .view-cart {
  color: #363636;
  border: 1px solid #363636;
}

.user-login li.cart-count .view-cart:hover {
  background: #e43a90;
  color: #fff !important;
  border-color: #e43a90;
}

.user-login li.cart-count .checkout {
  background: #e43a90;
  color: #FFF !important;
  float: right;
  border: 1px solid #e43a90;
}

.user-login li.cart-count .checkout:hover {
  background: #cf1c77;
}

.user-login li:hover .cart-overview {
  opacity: 1 !important;
  top: 100% !important;
  visibility: visible !important;
}

.user-login li:last-child {
  margin-right: 0;
}

.user-login li:last-child:after {
  display: none;
}

.user-login li a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}

.user-login li a i {
  margin-right: 10px;
  color: #fff;
}

.user-login li:hover .badge {
  color: #fff;
}

.user-login li:hover a {
  color: #b9b9b9;
}

.user-login li:hover a i {
  color: #fff;
}

.header-inner {
  background: rgba(228, 58, 144, 0.2);
}

#site-logo {
  line-height: 104px;
}

#site-logo .logo-stickky {
  display: none;
}

.hide-topbar #site-logo .logo-stickky {
  display: block;
}

.hide-topbar #site-logo .logo-main {
  display: none;
}

#main-header-menu {
  list-style: none;
}

#main-header-menu > li {
  position: relative;
}

#main-header-menu > li a {
  position: relative;
  display: inline-block;
  font-family: "Changa", sans-serif;
}

#main-header-menu > li:after, #main-header-menu > li:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 1px;
  background: #fff;
  opacity: 0;
  transition: all 0.5s ease;
}

#main-header-menu > li:before {
  left: 30px;
  top: 35px;
}

#main-header-menu > li:after {
  bottom: 35px;
  right: 30px;
}

#main-header-menu > li:hover:after, #main-header-menu > li:hover:before {
  opacity: 1;
}

#main-header-menu > li:hover:before {
  left: -6px;
}

#main-header-menu > li:hover:after {
  right: -5px;
}

#main-header-menu > li.menu-item-has-children .sub-menu {
  text-align: left;
  position: absolute;
  padding: 10px 20px;
  width: 240px;
  background: rgba(0, 0, 0, 0.95);
  top: 100%;
  transform: translateY(-10px);
  left: auto;
  margin: 0;
  border-top: 1px solid #e43a90;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  height: auto;
}

#main-header-menu > li.menu-item-has-children .sub-menu li {
  display: block;
  padding: 5px 0;
  margin: 0;
}

#main-header-menu > li.menu-item-has-children .sub-menu li:last-child {
  border-bottom: 0;
}

#main-header-menu > li.menu-item-has-children .sub-menu li a {
  color: #fff;
  padding: 3px 0;
  font-size: 16px;
}

#main-header-menu > li.menu-item-has-children .sub-menu li a:hover, #main-header-menu > li.menu-item-has-children .sub-menu li a.current_page {
  color: #e43a90;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu {
  width: 100%;
  padding: 20px 30px;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu > li {
  width: 25%;
  float: left;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu > li h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-left: 15px;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu {
  background-color: transparent;
  border-top-color: transparent;
  float: left;
  padding: 0 15px;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li {
  display: block;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li a {
  display: block;
  padding: 3px 0;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li:not(:last-child) .sub-menu {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

#main-header-menu > li.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  z-index: 20;
  transition-delay: 0s, 0s, 0.3s;
}

#main-header-menu li {
  display: inline-block;
  margin: 0 20px;
  padding: 37px 0;
}

#main-header-menu li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
}

#main-header-menu > li.active:before {
  left: -6px;
}

#main-header-menu > li.active:after {
  right: -5px;
}

#main-header-menu > li.active:before, #main-header-menu > li.active:after {
  opacity: 1;
}

.header-inner .nav .head-btn {
  height: 35px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
  margin: 33px 0 0 100px;
  text-transform: uppercase;
  box-sizing: border-box;
  display: block;
  float: right;
  line-height: 30px;
  transition: .25s;
  cursor: pointer;
  font-family: "Changa", sans-serif;
}

.header-inner .nav .head-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

/* Mobile Nav */
#nav-toggle {
  margin-right: 0;
  float: right;
  width: 30px;
  padding: 7px 0;
}

#nav-toggle .toggle-inner {
  position: relative;
}

#nav-toggle .toggle-inner > span {
  display: block;
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}

#nav-toggle .toggle-inner > span:nth-child(2), #nav-toggle .toggle-inner > span:nth-child(3) {
  width: 24px;
  margin-left: 6px;
  background-color: #e43a90 !important;
}

#nav-toggle .toggle-inner > span:nth-child(4) {
  margin-bottom: 0;
}

#nav-toggle.active .toggle-inner > span:nth-child(2), #nav-toggle.active .toggle-inner > span:nth-child(3) {
  opacity: 0;
}

#nav-toggle.active .toggle-inner > span:nth-child(1) {
  transform: rotate(45deg) translate(7px, 7px);
}

#nav-toggle.active .toggle-inner > span:nth-child(4) {
  transform: rotate(-45deg) translate(8px, -8px);
}

nav#accordian {
  padding: 20px 30px;
}

#mobile-nav-wrap {
  display: none;
  background: #131313;
  padding: 16px 20px;
}

#mobile-nav-wrap #mobile-logo {
  float: left;
  padding: 5px 0;
}

#mobile-nav-wrap #mobile-logo img {
  width: 60px;
}

#mobile-nav-wrap .user-link {
  padding: 5px 0;
}

#mobile-nav-wrap .user-link li {
  margin-right: 10px;
}

#mobile-nav-wrap.mnav-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
  animation: headerFixedTop .35s;
}

.mobile-menu-inner {
  display: block;
  width: 100%;
  text-align: center;
  background: #e43a90;
  position: fixed;
  z-index: 9999;
  left: -100%;
  transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
}

.mobile-menu-inner.mnav-fixed {
  position: fixed;
}

.mobile-menu-inner #mobile-logo {
  margin-bottom: 30px;
}

.mobile-menu-inner .mob-nav {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}

.accordion-menu {
  width: 100%;
  margin: 20px auto 50px;
  text-align: left;
  border-radius: 4px;
  list-style: none;
}

.accordion-menu ul {
  list-style: none;
}

.accordion-menu > li {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.accordion-menu > li:first-child {
  border-top: 0;
}

.accordion-menu > li.single-link {
  margin: 3px 0;
}

.accordion-menu > li .dropdownlink:before {
  content: "\f105";
  position: absolute;
  right: 0;
  top: 8px;
  font: normal normal normal 14px/1 FontAwesome;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  height: 30px;
  width: 30px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  line-height: 29px;
}

.accordion-menu > li.open > a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.accordion-menu > li.open .dropdownlink:before {
  content: "\f107";
  transition: all 0.3s ease-in-out;
}

.accordion-menu > li > a {
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 0;
  color: #fff;
  display: block;
}

.accordion-menu .accordion-menu li.open .dropdownlink {
  color: #e43a90;
}

.accordion-menu .accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
}

.accordion-menu .accordion-menu .fa-angle-down {
  transform: rotate(180deg);
}

.accordion-menu .dropdownlink {
  cursor: pointer;
  color: #fff;
  position: relative;
  transition: all 0.4s ease-out;
}

.accordion-menu .dropdownlink i {
  position: absolute;
  top: 13px;
  left: 16px;
  font-size: 14px;
}

.accordion-menu .dropdownlink .fa-angle-down {
  right: 12px;
  left: auto;
}

.accordion-menu .dropdownlink:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems {
  display: none;
  background: transparent;
}

.accordion-menu .submenuItems li:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems a {
  display: block;
  color: #FFF;
  transition: all 0.4s ease-out;
  text-decoration: none;
  font-size: 16px;
  padding: 5px 0;
  font-weight: 600;
}

.accordion-menu .submenuItems:hover, .accordion-menu .submenuItems:focus {
  color: #e43a90;
}

#moble-search {
  border: 1px solid #fff;
  display: flex;
  border-radius: 4px;
}

#moble-search input[type="text"] {
  background: transparent;
  height: 45px;
  color: #fff;
  border: 0;
  padding: 0 10px;
  width: 88%;
  outline: 0;
}

#moble-search input[type="text"]::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

#moble-search button[type="submit"] {
  background: transparent;
  border: 0;
  color: #fff;
}

@-webkit-keyframes headerFixedTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes headerFixedTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mask-overlay {
  content: '';
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.active .mobile-menu-inner {
  left: 0;
}

.close-menu {
  position: absolute;
  right: 20px;
  top: 28px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  padding: 5px;
}

.close-menu .bar {
  display: block;
  position: relative;
  margin-bottom: 3px;
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.25s ease-in-out;
}

.close-menu .bar:first-child {
  margin-bottom: 5px;
  transform: rotate(-45deg) translate(-9px, 10px);
}

.close-menu .bar:last-child {
  transform: rotate(45deg) translate(5px, 4px);
}

.user-link li a {
  color: #fff;
}

.user-link li a i {
  margin-right: 5px;
}

.mobile-nav-top-wrap .mob-header-inner {
  padding: 20px;
  background: #fff;
}

.mobile-nav-top-wrap .mob-header-inner .mobile-logo {
  margin: 0;
  max-width: 120px;
  float: left;
}

.mobile-nav-top-wrap .mob-header-inner .mobile-logo a {
  display: block;
}

.mobile-nav-top-wrap .mob-header-inner .mobile-logo a img {
  max-width: 100%;
}

.hide-topbar .top-header {
  display: none;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  animation-name: headerSlideDown;
}

.headroom--pinned.hide-topbar .header-inner {
  background: #FFF;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li:after, .headroom--pinned.hide-topbar .header-inner #main-header-menu li:before {
  background: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li a {
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li a:hover {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li.menu-item-has-children .sub-menu {
  background: #fff;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li.active > a {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn {
  border-color: #d8d1d1;
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn:hover {
  color: #fff;
  border-color: #e43a90;
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
  will-change: transform, opacity;
  animation-duration: .5s;
}

.headroom--unpinned {
  transform: translateY(-100%);
  position: fixed;
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp;
}

@media (max-width: 1440px) {
  #main-header-menu li {
    margin: 0 14px;
  }
  .header-inner .nav .head-btn {
    margin-left: 25px;
  }
}

@media (max-width: 1199px) {
  #main-header-menu > li::before {
    top: 28px;
  }
  #main-header-menu > li::after {
    bottom: 28px;
  }
  #main-header-menu li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #main-header-menu li a {
    font-size: 14px;
  }
  #site-logo {
    line-height: 75px;
  }
  .header-inner .nav .head-btn {
    margin-top: 22px;
  }
}

@media (max-width: 1024px) {
  #main-header-menu li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #main-header-menu li a {
    font-size: 14px;
  }
  #site-logo {
    max-width: 100px;
  }
  #site-logo a img {
    max-width: 100%;
  }
  .header-inner .nav .head-btn {
    margin-left: 25px;
    margin-top: 22px;
  }
}

@media (max-width: 991px) {
  .header {
    display: none;
  }
  #mobile-nav-wrap {
    display: block;
    width: 100%;
    z-index: 999;
    position: fixed;
  }
}

/* 	#mobile-nav-wrap.headroom--top
		position: relative; */
/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/
.backtotop {
  position: fixed;
  bottom: 25px;
  right: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
  -webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
}

.backtotop i {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 20px;
  background: #fff;
  margin: 2px;
  color: #050303;
  text-align: center;
  border-radius: 50%;
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
.banner-slider-one {
  height: 100vh;
}

.banner-slider-one .swiper-slide {
  background-size: cover;
}

.banner-slider-one .swiper-slide .slider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -25%);
  width: 100%;
}

.banner-slider-one .swiper-slide .slider-content h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  line-height: 40px;
}

.banner-slider-one .swiper-slide .slider-content h2 {
  color: #fff;
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 20px;
}

.banner-slider-one .swiper-slide .slider-content p {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 40px;
}

.tim-slide-btn {
  padding: 18px 40px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  font-size: 17px;
  line-height: 18px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.tim-slide-btn i {
  margin-right: 5px;
}

.tim-slide-btn:hover {
  color: #fff;
  background: rgba(109, 18, 66, 0.73);
}

.banner-slider-two {
  height: 100vh;
}

.banner-slider-two .swiper-slide {
  background-size: cover;
}

.banner-slider-two .swiper-slide .slider-content-two {
  position: absolute;
  top: 65%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  left: 10%;
}

.banner-slider-two .swiper-slide .slider-content-two img {
  float: left;
  transform: translateY(-100px);
}

.banner-slider-two .swiper-slide .slider-content-two h3 {
  font-size: 40px;
  color: #fff;
  line-height: 40px;
  font-weight: 600;
}

.banner-slider-two .swiper-slide .slider-content-two h2 {
  color: #fff;
  font-size: 80px;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 20px;
}

.banner-slider-two .swiper-slide .slider-content-two p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.banner-slider-two .swiper-slide .slider-content-two .tim-btn {
  padding: 13px 37px;
  font-size: 16px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  margin-right: 20px;
}

.banner-slider-two .swiper-slide .slider-content-two .tim-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

.banner-slider-two .swiper-slide .slider-content-two.content-three {
  width: 80%;
}

.banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
  font-size: 40px;
  font-weight: 500;
  color: #e43a90;
  line-height: 40px;
}

.banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
  font-size: 70px;
  line-height: 70px;
}

.slider-nav-prev, .slider-nav-next {
  width: 60px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 35px;
}

.slider-nav-next {
  right: 0;
}

/* Music Player */
.header_player {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 111;
}

.header_player button {
  background: transparent;
  border: 0;
  outline: 0;
}

.header_player .player-container {
  max-width: 1200px;
  margin: 0 auto;
}

.header_player .jp-audio {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.header_player .jp-audio .jp-type-playlist {
  display: block;
  position: relative;
  height: 100%;
}

.header_player .jp-audio .jp-type-playlist .jp-interface {
  padding: 36px 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface > div {
  float: left;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
  text-align: center;
  width: 170px;
  padding-top: 0;
  margin-top: -15px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button {
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 3px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button i {
  width: 20px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
  height: 60px;
  width: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  text-align: center;
  padding-left: 2px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  font-size: 20px;
  line-height: 40px;
  color: #e43a90;
  margin-left: 6px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
  display: none;
  position: absolute;
  bottom: 100px;
  right: 0;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.95);
  padding: 15px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul {
  padding: 0;
  margin: 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li {
  display: block;
  border-bottom: 1px solid #333;
  padding: 5px 30px;
  transition: all 0.2s linear;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:last-child {
  border: none;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover {
  background: #e43a90;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div {
  display: block;
  position: relative;
  padding-right: 20px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div .jp-free-media {
  display: block;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
  text-decoration: none;
  outline: 0;
  padding: 10px 0;
  padding-right: 10px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div .jp-free-media a {
  color: #fff;
  text-decoration: none;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-decoration: none;
  outline: 0;
  padding: 14px 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a.jp-playlist-item-remove {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  right: 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a .jp-artist {
  font-style: normal;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current {
  background: #e43a90;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current div > a {
  color: #fff;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
  z-index: 30;
  margin-top: 10px;
  overflow: hidden;
  height: 8px;
  max-width: 32%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar {
  width: 0px;
  height: 100%;
  cursor: pointer;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
  background: #e43a90;
  width: 0px;
  height: 100%;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-duration {
  color: #fff;
  font-family: "Changa", sans-serif;
  margin-left: 10px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
  width: 140px;
  margin-left: 20px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap .jp-mute {
  color: #fff;
  width: 20px;
  text-align: left;
}

.header_player .jp-audio .jp-type-playlist .jp-no-solution {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  padding: 10px;
  background: #ffffff;
}

.header_player .jp-audio .jp-type-playlist .jp-no-solution span {
  font-weight: 700;
  color: #e43a90;
}

.header_player .jp-audio .jp-volume-bar {
  display: inline-block;
  position: relative;
  width: 65%;
  height: 5px;
  background: #fff;
  margin-bottom: 4px;
  cursor: pointer;
}

.header_player .jp-audio .jp-volume-bar .jp-volume-bar-value {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #e43a90;
}

.header_player .jp-audio.jp-state-playing .jp-play i:before {
  content: "\f04c" !important;
}

.header_player .jp-audio.jp-state-muted .jp-mute i {
  color: #fff;
}

.header_player .jp-audio.jp-state-muted .jp-mute i:before {
  content: "\f026" !important;
}

.header_player .jp-audio.single-album-player {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e6e6e6;
}

.header_player .jp-audio.single-album-player .jp-type-playlist {
  display: block;
  height: auto;
  background: #ffffff;
}

.header_player .jp-audio #playlist-toggle i {
  color: #fff;
}

.header_player .jp-audio #playlist-toggle.close-icon i:before {
  content: "\f00d";
}

.header_player #nowPlaying {
  overflow: hidden;
  font-style: normal;
  color: #ffffff;
  padding: 21px 0 21px 15px;
}

.header_player #nowPlaying .track-name {
  font-size: 26px;
  font-family: "Changa", sans-serif;
  color: #fff;
  margin: 0;
  font-weight: 700;
}

.current-tracks {
  float: left;
  width: 365px;
  position: relative;
}

.current-tracks:after {
  position: absolute;
  top: 20px;
  right: 0;
  content: '';
  height: 60px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
}

#main_player {
  width: 100px !important;
  height: 100px !important;
  float: left;
  transform: translateY(-25px);
}

#main_player img {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 1440px) {
  .banner-slider-two .swiper-slide .slider-content-two {
    max-width: 80%;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 380px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 60px;
    line-height: 55px;
  }
  .banner-slider-two .swiper-slide .slider-content-two p br {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 30px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 55px;
    line-height: 60px;
  }
}

@media (max-width: 1280px) {
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 21%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
    width: 115px;
  }
  .header_player #nowPlaying .track-name {
    font-size: 20px;
  }
  .current-tracks {
    width: 320px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 300px;
  }
}

@media (max-width: 1024px) {
  .banner-slider-one .swiper-slide .slider-content h3 {
    font-size: 40px;
    line-height: 40px;
  }
  .banner-slider-one .swiper-slide .slider-content h2 {
    font-size: 55px;
    line-height: 55px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    top: 60%;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 50px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 50px;
  }
}

@media (max-width: 992px) {
  .header_player .player-container {
    max-width: 500px;
    margin: 0 auto;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    top: 45%;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 280px;
    transform: translateY(-10px);
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h3 {
    font-size: 22px;
    margin-bottom: 0;
    line-height: 22px;
  }
  .banner-slider-two .swiper-slide .slider-content-two p {
    margin-bottom: 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    padding: 7px 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    padding: 7px 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two i {
    font-size: 18px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two i:after {
    height: 15px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 145px;
    margin-top: -8px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
    height: 40px;
    width: 40px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
    line-height: 38px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 35%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
    width: 70px;
  }
  .current-tracks {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 200px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 35px;
    margin-bottom: 10px;
    line-height: 40px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three p {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn {
    padding: 6px 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn i {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .banner-slider-one .swiper-slide .slider-content h3 {
    font-size: 28px;
    line-height: 28px;
  }
  .banner-slider-one .swiper-slide .slider-content h2 {
    font-size: 35px;
    line-height: 35px;
  }
  .banner-slider-one .swiper-slide .slider-content p {
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .banner-slider-one .swiper-slide .slider-content p br {
    display: none;
  }
  .tim-slide-btn {
    padding: 13px 15px;
    font-size: 14px;
  }
  .slider-nav-prev, .slider-nav-next {
    width: 30px;
    height: 100px;
    line-height: 100px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    width: 90%;
    left: 0;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 180px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .banner-slider-two .swiper-slide .slider-content-two p {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    margin-right: 8px;
    margin-top: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn, .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    padding: 10px 14px;
    font-size: 14px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
    width: 400px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    width: 30%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 140px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    transform: translateY(-65%);
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn {
    margin-bottom: 10px;
    padding: 5px 16px;
    font-size: 14px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn i {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .banner-slider-one .swiper-slide .slider-content h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .banner-slider-one .swiper-slide .slider-content h2 {
    font-size: 25px;
    line-height: 20px;
  }
  .banner-slider-one .swiper-slide .slider-content p {
    max-width: 300px;
    font-size: 16px;
    margin: 0 auto 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    transform: translateY(-65%);
  }
  .banner-slider-two .swiper-slide .slider-content-two.text-left {
    text-align: center !important;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 150px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two, .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    width: 150px;
    padding: 10px;
    text-align: center;
    margin-right: 0;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    margin-top: 10px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn {
    margin-bottom: 10px;
    margin-left: 0 !important;
    padding: 5px 15px;
  }
  .tim-slide-btn {
    display: block;
    max-width: 200px;
    margin: 0 auto 10px;
  }
  .slider-nav-prev, .slider-nav-next {
    width: 30px;
    height: 100px;
    line-height: 100px;
    font-size: 28px;
  }
  .banner-slider-two {
    text-align: center !important;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    float: none;
    margin: 0 auto;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface {
    padding: 20px 0;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
    width: 100%;
    bottom: 70px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li {
    padding: 0 15px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a {
    font-size: 12px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    width: 29%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 120px;
    top: -4px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
    height: 35px;
    width: 35px;
    margin: 0;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
    font-size: 15px;
    line-height: 32px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button {
    margin: 0;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button i {
    font-size: 15px;
  }
  .header_player .jp-audio .jp-type-playlist .vel-wrap {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    width: 100%;
    left: 10%;
  }
}

@media (max-width: 378px) {
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    margin-top: 10px;
  }
}

@media (min-width: 501px) and (max-width: 555px) {
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn, .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    padding: 7px 5px;
    font-size: 14px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    width: 100%;
    left: 0;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 170px;
  }
}

/*--------------------------------------------------------------
  ##  Tranding Album
  --------------------------------------------------------------*/
#tranding-album, #tranding-album-two {
  padding: 120px 0 90px;
}

#tranding-album-two {
  padding: 120px 0 70px;
}

.album-box {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 530px;
}

.album-box .box-thumb:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.album-box img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  transform-origin: 100% 100%;
}

.album-box .album-details {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  bottom: 0;
  padding: 22px 40px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 222;
}

.album-box .album-details .content {
  display: inline-block;
  float: left;
}

.album-box .album-details .content p {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 0;
}

.album-box .album-details .content .album-name {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  line-height: 24px;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.album-box .album-details .content .album-name a {
  color: #fff;
}

.album-box .album-details .content .album-name a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.album-box .album-details .content .album-name:before, .album-box .album-details .content .album-name:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  background: #e43a90;
  transition: all 0.3s ease-in-out;
}

.album-box .album-details .content .album-name:before {
  width: 100px;
  height: 1px;
}

.album-box .album-details .content .album-name:after {
  width: 30px;
  height: 3px;
  bottom: -1px;
  left: 15px;
}

.album-box .album-details .icon {
  text-align: right;
  height: 50px;
  position: relative;
  margin-top: 3px;
  transition: all 0.3s ease-in-out;
  padding-left: 15px;
}

.album-box .album-details .icon i {
  font-size: 50px;
  line-height: 42px;
  color: #e43a90;
  transition: all 0.3s ease-in-out;
}

.album-box:hover .box-thumb:before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

.album-box:hover img {
  transform: scale(1.05);
}

.album-box:hover .album-details {
  background: rgba(228, 58, 144, 0.97);
}

.album-box:hover .album-details .content .album-name:before, .album-box:hover .album-details .content .album-name:after {
  background: #fff;
}

.album-box:hover .album-details .icon {
  border-color: #fff;
}

.album-box:hover .album-details .icon i {
  color: #fff;
}

.album-box.album-box-two .box-thumb {
  position: relative;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  border-radius: 4px;
  overflow: hidden;
}

.album-box.album-box-two .box-thumb img {
  width: 100%;
}

.album-box.album-box-two .box-thumb .icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.album-box.album-box-two .box-thumb .icon i {
  font-size: 30px;
  color: #fff;
}

.album-box.album-box-two .album-details {
  position: static;
  background: transparent !important;
  padding: 20px 0;
}

.album-box.album-box-two .album-details .content h3 a {
  color: #444;
}

.album-box.album-box-two .album-details .content h3 a:hover {
  color: #e43a90;
}

.album-box.album-box-two .album-details .content h3:before, .album-box.album-box-two .album-details .content h3:after {
  background: #000;
}

.album-box.album-box-two .album-details .content p {
  color: #444;
}

.album-box.album-box-two:hover {
  background: transparent;
}

.album-box.album-box-two:hover .content .album-name:before, .album-box.album-box-two:hover .content .album-name:after {
  background: #e43a90 !important;
}

.album-box.album-box-two:hover .content p {
  color: #444;
}

.tab-top-music {
  background: url(../../media/background/13.jpg);
}

.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hover14 figure:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@media (max-width: 1280px) {
  .album-box .album-details {
    padding: 15px 30px;
  }
  .album-box .album-details .icon i {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  #tranding-album-two {
    padding: 50px 0 20px;
  }
  #tranding-album {
    padding: 50px 0 20px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .album-box .album-details {
    padding: 10px 15px;
  }
  .album-box .album-details .content .album-name {
    font-size: 18px;
    font-weight: 600;
  }
  .album-box .album-details .content p {
    font-size: 14px;
  }
  .album-box .album-details .icon {
    height: 35px;
    margin-top: 10px;
    padding-left: 10px;
  }
  .album-box .album-details .icon i {
    font-size: 35px;
    line-height: 30px;
  }
}

@media (max-width: 500px) {
  .album-box .album-details .content .album-name {
    font-size: 17px;
    font-weight: 400;
  }
}

/*--------------------------------------------------------------
  ##  Artist
  --------------------------------------------------------------*/
.artist-image img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.artist-details {
  margin-top: 50px;
}

.artist-details .artist-name {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 26px;
}

.artist-details .band-name {
  color: #fff;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.artist-details .details h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.artist-details .details p {
  color: #fff;
}

.artist-details .details .sng {
  margin-top: 20px;
}

.artist-details-two {
  margin-top: 45px;
}

@media (max-width: 1200px) {
  .artist-details {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .artist-details {
    margin-top: 30px;
  }
  .artist-details-two {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Upcomiong Concerts
  --------------------------------------------------------------*/
.concerts {
  background: #fff;
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 35px 0;
  margin-bottom: 40px;
}

.concerts:last-child {
  margin-bottom: 0;
}

.concerts-count {
  width: 26%;
  height: 75px;
  padding: 0 50px;
}

.concerts {
  display: flex;
  align-items: center;
}

.concerts .countdown {
  background: #e43a90;
  border-radius: 5px;
  height: 75px;
  padding: 15px 0;
}

.concerts .countdown .CountdownContent {
  width: 25%;
  display: inline-block;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  position: relative;
}

.concerts .countdown .CountdownContent:after {
  content: ':';
  position: absolute;
  right: -5px;
  color: #fff;
  top: 0;
}

.concerts .countdown .CountdownContent:last-child:after {
  display: none;
}

.concerts .countdown .CountdownContent .CountdownLabel {
  display: block;
  font-size: 12px;
  font-weight: 300;
}

.concerts .concert-details {
  width: 40%;
  padding: 0 65px;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.concerts .concert-details h3 {
  font-size: 18px;
  font-weight: 500;
  color: #363636;
}

.concerts .concert-details h2 {
  color: #393838;
  font-size: 24px;
  margin: 0;
}

.concerts .concert-details p {
  font-size: 16px;
  color: #363636;
  margin-bottom: 0;
}

.concerts .ticket {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
  width: 34%;
}

.concerts .ticket .tic-btn {
  padding: 10px 24px;
  color: #343434;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 30px;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  border: 1px solid #a2a2a2;
}

.concerts .ticket .tic-btn:focus {
  outline: none;
}

.concerts .ticket .tic-btn:last-child {
  margin-right: 0;
}

.concerts .ticket .tic-btn:hover {
  background: #444;
  color: #fff;
}

.concerts .ticket .tic-btn.tic-btn-bg {
  background: #e43a90;
  color: #fff;
  border: transparent;
}

.concerts .ticket .tic-btn.tic-btn-bg:hover {
  background: #444;
  color: #fff;
}

.concerts.concerts-two {
  background: transparent;
  box-shadow: none;
  border: 1px dashed #d9d9d9;
  transition: all 0.3s ease-in-out;
}

.concerts.concerts-two .concerts-count {
  width: 25%;
  padding: 0 50px 0 0;
}

.concerts.concerts-two .concerts-count .countdown {
  background: transparent;
  border: 1px dashed #cacaca;
  padding: 8px 0;
}

.concerts.concerts-two .concerts-count .countdown .CountdownContent {
  color: #343434;
}

.concerts.concerts-two .concerts-count .countdown .CountdownContent:after {
  color: #343434;
}

.concerts.concerts-two .concerts-count .countdown .CountdownContent .CountdownLabel {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
}

.concerts.concerts-two .concert-details {
  padding: 0 30px;
  border: 0;
  display: flex;
  align-items: center;
  width: 40%;
}

.concerts.concerts-two .concert-details img {
  width: 125px;
  height: 125px;
  box-shadow: 0px 7px 17.43px 3.57px rgba(88, 88, 88, 0.14);
  border-radius: 4px;
}

.concerts.concerts-two .concert-details .content {
  margin-left: 30px;
}

.concerts.concerts-two .ticket {
  width: 35%;
}

.concerts.concerts-two:hover {
  background: rgba(255, 255, 255, 0.25);
}

.concerts.concerts-two:hover .concerts-count .countdown {
  background: transparent;
}

/* Upcomming Concert Three */
.upc-con-three {
  text-align: center;
  border-radius: 10px;
  position: relative;
}

.upc-con-three .cons-feature-image {
  position: relative;
}

.upc-con-three .cons-feature-image img {
  max-width: 100%;
}

.upc-con-three .content-wrap {
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 900;
  text-align: center;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transition: all .4s ease-in-out;
}

.upc-con-three .content-wrap .content {
  padding: 30px;
}

.upc-con-three .content-wrap .content h4 {
  font-size: 14px;
  font-weight: 500;
}

.upc-con-three .content-wrap .content h3 {
  font-size: 20px;
  font-weight: 700;
  color: #e43a90;
  text-transform: uppercase;
}

.upc-con-three .content-wrap .content p {
  margin: 0;
}

.upc-con-three .upc-count-wrap {
  background: #363636;
  padding: 150px 0;
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 1000;
  -webkit-transform: rotateY(-180deg);
  -webkit-transform--moz-transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -webkit-transform-style--moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-backface-visibility--moz-backface-visibility: hidden;
  transition: all .4s ease-in-out;
  width: 100%;
  height: 100%;
}

.upc-con-three .upc-count-wrap .countdown {
  margin-bottom: 20px;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent {
  display: inline-block;
  color: #fff;
  font-size: 22px;
  font-family: "Changa", sans-serif;
  padding: 0 12px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent:after {
  position: absolute;
  content: '';
  height: 25px;
  width: 1px;
  right: 0;
  top: 7px;
  background: #fff;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent:last-child:after {
  display: none;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent .CountdownLabel {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.upc-con-three .upc-count-wrap .tic-btn {
  padding: 14px 22px;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  display: inline-block;
  border: 1px solid #fff;
}

.upc-con-three .upc-count-wrap .tic-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

.upc-con-three .upc-count-wrap .tic-btn.tic-btn-bg {
  margin-left: 15px;
}

.upc-con-three {
  margin: 0 auto;
  height: 420px;
  position: relative;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
}

.upc-con-three .content-wrap,
.upc-con-three .upc-count-wrap {
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 34.2px 1.8px rgba(0, 0, 0, 0.1);
}

.upc-con-three.flip .content-wrap {
  z-index: 900;
  transform: rotateY(180deg);
}

.upc-con-three.flip .upc-count-wrap {
  z-index: 1000;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
}

@media (max-width: 1440px) {
  .concerts-count {
    padding: 0 30px;
  }
  .concerts .concert-details {
    padding: 0 40px;
  }
  .concerts .concert-details h2 {
    font-size: 20px;
  }
  .concerts .ticket .tic-btn {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .concerts.concerts-two .concerts-count {
    padding: 0 0 0 30px;
  }
  .concerts .ticket {
    padding: 0;
  }
  .concerts .ticket .tic-btn {
    padding: 8px 15px;
    font-size: 15px;
  }
  .concerts.concerts-two {
    padding: 15px 0;
  }
  .concerts.concerts-two .concerts-count {
    padding: 0 15px 0 0;
    width: 30%;
  }
  .concerts.concerts-two .ticket {
    width: 27%;
  }
  .concerts.concerts-two .concert-details {
    width: 58%;
    padding: 0 15px;
  }
  .concerts .ticket .tic-btn {
    margin-right: 9px;
  }
}

@media (max-width: 991px) {
  .concerts {
    display: block;
  }
  .concerts.concerts-two .concerts-count, .concerts.concerts-two .concert-details, .concerts.concerts-two .ticket {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .concerts.concerts-two .concerts-count {
    padding: 0 20px;
    margin-top: 20px;
  }
  .concerts.concerts-two .ticket {
    margin-bottom: 0;
  }
  .upc-con-three {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .concerts-count {
    padding: 0 15px;
  }
  .concerts .countdown .CountdownContent {
    font-size: 20px;
  }
  .concerts .countdown .CountdownContent:after {
    right: -3px;
  }
  .concerts .countdown .CountdownContent .CountdownLabel {
    font-size: 10px;
  }
  .concerts .concert-details {
    padding: 0 20px;
  }
  .concerts .concert-details h3 {
    font-size: 14px;
  }
  .concerts .concert-details h2 {
    font-size: 16px;
  }
  .concerts .ticket .tic-btn {
    padding: 8px 14px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .section-padding {
    padding: 50px 0;
  }
  .concerts {
    display: block !important;
  }
  .concerts .concerts-count, .concerts .concert-details, .concerts .ticket {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
  }
  .concerts .concerts-count {
    margin: 0 auto 20px;
    padding: 0 15px;
    width: 100%;
  }
  .concerts .concert-details {
    padding: 20px 15px;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    text-align: center;
  }
  .concerts .ticket {
    margin: 20px auto 0;
  }
  .concerts.concerts-two .concerts-count {
    margin: 20px auto 0px;
  }
  .upc-con-three .upc-count-wrap .tic-btn {
    padding: 14px 11px;
  }
}

@media (min-width: 500px) and (max-width: 576px) {
  .upc-con-three {
    height: 480px;
  }
}

@media (max-width: 500px) {
  .concerts.concerts-two .concert-details {
    padding: 0;
    display: block;
  }
  .concerts.concerts-two .concert-details .content {
    margin: 10px 0 0 0;
  }
  .concerts.concerts-two .ticket {
    margin: 50px 0;
  }
  .concerts .ticket .tic-btn {
    padding: 10px 14px;
    font-size: 15px;
  }
  .upc-con-three {
    height: 430px;
  }
  .concerts.concerts-two .concerts-count .countdown {
    border: none;
  }
}

/*--------------------------------------------------------------
  ##  Ticket
  --------------------------------------------------------------*/
#ticket {
  background-size: cover;
  background-position: center center;
  padding: 120px 0 120px;
}

.live-ticket h2 {
  font-size: 40px;
  color: #fff;
  line-height: 46px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.live-ticket h2 span {
  color: #e43a90;
}

.live-ticket h2:after {
  position: absolute;
  content: '';
  background-image: url(../img/dm2l.png);
  height: 15px;
  max-width: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  left: 0;
  bottom: -8px;
  display: none;
}

.live-ticket h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 18px;
}

.live-ticket h4 span {
  color: #e43a90;
  font-weight: 700;
}

.live-ticket p {
  color: #fff;
  line-height: 26px;
  font-size: 16px;
  margin: 0;
}

.live-ticket .tim-btn {
  color: #fff;
  border-color: #fff;
}

.live-ticket .tim-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

.ticket-thumb img {
  max-width: 100%;
  max-width: 100%;
  grid-column: 1 / span 1;
  clip-path: polygon(0 0, 0 100%, 93% 100%, 100% 0);
}

.live-ticket-count {
  margin: 45px 0 45px;
}

.live-ticket-count .countdown {
  max-width: 500px;
}

.live-ticket-count .countdown .CountdownContent {
  display: inline-block;
  height: 93px;
  width: 104px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  font-family: "Changa", sans-serif;
  text-align: center;
  position: relative;
  background-image: url("../../media/background/1.png");
  background-size: contain;
  margin-right: 20px;
  background-repeat: no-repeat;
  padding: 24px 0;
}

.live-ticket-count .countdown .CountdownContent .CountdownLabel {
  display: block;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .live-ticket h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .live-ticket h4 {
    margin-bottom: 10px;
  }
  .live-ticket p br {
    display: none;
  }
  .live-ticket .tim-btn {
    padding: 8px 25px;
  }
  .live-ticket-count {
    margin: 25px 0 15px;
  }
  .live-ticket-count .countdown .CountdownContent {
    width: 85px;
    font-size: 30px;
    padding: 13px 0;
  }
  .live-ticket-count .countdown .CountdownContent .CountdownLabel {
    font-size: 12px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .live-ticket h2 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .live-ticket h4 {
    margin-bottom: 10px;
  }
  .live-ticket p {
    font-size: 14px;
    line-height: 22px;
  }
  .live-ticket p br {
    display: none;
  }
  .live-ticket .tim-btn {
    padding: 5px 22px;
  }
  .live-ticket-count {
    margin: 20px 0 15px;
  }
  .live-ticket-count .countdown .CountdownContent {
    width: 70px;
    font-size: 25px;
    padding: 12px 0;
    height: 62px;
    line-height: 20px;
  }
  .live-ticket-count .countdown .CountdownContent .CountdownLabel {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .live-ticket {
    padding: 30px;
  }
  .ticket-thumb img {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
}

@media (max-width: 768px) {
  .live-ticket {
    padding: 30px 15px;
    text-align: center;
  }
  .live-ticket h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .live-ticket p br {
    display: none;
  }
  .live-ticket-count .countdown {
    max-width: 100%;
  }
  .live-ticket-count .countdown .CountdownContent {
    height: 90px;
    width: 100px;
    font-size: 25px;
    padding: 18px 0;
    margin-right: 10px;
    background-size: cover;
    margin-bottom: 15px;
  }
  .live-ticket-count .countdown .CountdownContent .CountdownLabel {
    font-size: 16px;
  }
  #ticket {
    padding: 50px 0 50px;
  }
}

@media (max-width: 500px) {
  .live-ticket h2 {
    font-size: 30px;
  }
  .live-ticket h4 {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
  ##  Ultimate Tabs
  --------------------------------------------------------------*/
.tabs-wrap {
  background: #f6f6f6;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}

.music-tabs-items {
  flex-wrap: wrap;
  display: flex;
}

.tabs-item {
  flex: 0 0 25%;
  padding: 0 10px;
  max-width: 25%;
  -webkit-box-flex: 0;
}

.tabs-item-two {
  flex: 0 0 33.33%;
  padding: 0 10px;
  max-width: 33.33%;
  -webkit-box-flex: 0;
}

.music-tabs {
  background-size: cover;
  background-position: center center;
}

.tab-pd-left {
  padding: 80px 80px 60px 135px;
  background-size: cover;
}

.tab-pd-right {
  padding: 17px 135px 0 80px;
}

.music-tab {
  position: relative;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
}

.music-tab:before {
  position: absolute;
  content: '';
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.music-tab img {
  width: 100%;
  height: auto;
}

.music-tab > a {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.music-tab > a span {
  color: #e43a90;
}

.music-tab .content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 20px 15px 20px;
  width: 100%;
}

.music-tab .content h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.music-tab .content h3 a {
  color: #fff;
}

.music-tab .content h3 a:hover {
  color: #e43a90;
}

.music-tab .content .ratting {
  list-style: none;
}

.music-tab .content .ratting li {
  display: inline-block;
  color: #e43a90;
  font-size: 14px;
}

.music-tab.music-tab-two {
  background: #fff;
  border-radius: 4px;
  padding: 29px 20px;
  position: relative;
  min-height: 145px;
}

.music-tab.music-tab-two:before {
  display: none;
}

.music-tab.music-tab-two .bg-icon {
  font-size: 50px;
  font-family: Flaticon;
  position: absolute;
  bottom: 47px;
  right: 15px;
}

.music-tab.music-tab-two .bg-icon i {
  font-size: 60px;
  opacity: 0.09;
}

.music-tab.music-tab-two i {
  font-size: 22px;
}

.music-tab.music-tab-two .content {
  position: relative;
  padding: 0;
}

.music-tab.music-tab-two .content h3 {
  color: #444;
  font-family: "Changa", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.music-tab.music-tab-two .content h3 a {
  position: relative;
  color: #444;
  right: auto;
  top: auto;
}

.music-tab.music-tab-two .content h3 a:hover {
  color: #e43a90;
}

.music-tab.music-tab-two .content .ratting li i {
  font-size: 14px;
}

.music-tab.music-tab-two .view-btn {
  position: relative;
  color: #444;
  right: auto;
}

.music-tab.music-tab-two .view-btn:hover {
  color: #e43a90;
}

@media (max-width: 1600px) {
  .tab-pd-right {
    padding: 30px 80px;
  }
  .tab-pd-left {
    padding-left: 70px;
  }
}

@media (min-width: 1280px) and (max-width: 1600px) {
  .tabs-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .tabs-item-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .tabs-item, .tabs-item-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .music-tabs-items {
    padding: 50px 5% 30px;
  }
  .tabs-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .tabs-item-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .tabs-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 500px) {
  .tabs-item-two {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .music-tab.music-tab-two .content h3 {
    font-size: 15px;
    font-weight: 400;
  }
}

/*--------------------------------------------------------------
  ##  Intro Video
  --------------------------------------------------------------*/
#video-intro {
  padding: 250px 0;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.intro-video {
  text-align: center;
}

.video-btn {
  height: 85px;
  width: 85px;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  line-height: 82px;
  display: block;
  margin: 0 auto;
  font-size: 26px;
  margin-bottom: 20px;
}

.video-btn i {
  margin-left: 3px;
  color: #fff;
}

.video-btn:hover {
  background: #e43a90;
  border-color: #e43a90;
}

.video-btn:hover i {
  color: #fff;
}

.intro-video h2 {
  font-size: 70px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  letter-spacing: 5px;
}

.intro-video h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.intro-video p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0px;
  font-family: "Changa", sans-serif;
  font-weight: 400;
}

.intro-video span {
  display: block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

/* Watch Video */
#watch {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 150px 0;
}

.watch-inner .tim-btn {
  color: #fff;
  border-color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.watch-inner .tim-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

.watch-inner .tim-btn.tim-btn-bg {
  margin-left: 20px;
  background: #e43a90;
  border-color: #e43a90;
}

.watch-inner .tim-btn.tim-btn-bg:hover {
  color: #363636;
  background: #fff;
  border-color: #fff;
}

@media (max-width: 768px) {
  #video-intro {
    padding: 150px 0;
  }
  .intro-video h2 {
    font-size: 30px;
  }
  .intro-video p {
    font-size: 18px;
  }
  .watch-inner .tim-btn.tim-btn-bg {
    margin-top: 10px;
    margin-left: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
#blog-grid {
  background: #f8f8f8;
}

#blog-grid.section-padding {
  padding-bottom: 90px;
}

.blog-post-grid {
  background: #FFF;
  max-width: 400px;
  margin: 0 auto 30px;
}

.blog-post-grid img {
  max-width: 100%;
  height: auto;
}

.blog-post-grid .entry-date {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  background: #e43a90;
  padding: 10px;
  text-align: center;
  height: 80px;
  width: 80px;
  display: inline-block;
  transform: translate(-10px, -90px);
  position: absolute;
  font-family: "Changa", sans-serif;
  text-transform: uppercase;
}

.blog-post-grid .entry-date span {
  font-size: 16px;
  font-style: normal;
}

.blog-post-grid .share-link {
  float: left;
  list-style: none;
  text-align: center;
  padding: 0 20px;
}

.blog-post-grid .share-link li a {
  color: #363636;
  font-size: 17px;
  line-height: 28px;
}

.blog-post-grid .entry-content {
  padding: 30px 25px;
}

.blog-post-grid .entry-content .entry-header .entry-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}

.blog-post-grid .entry-content .entry-header .entry-title a {
  color: #363636;
}

.blog-post-grid .entry-content .entry-header .entry-title a:hover {
  color: #e43a90;
}

.blog-post-grid .entry-content .read-btn {
  font-family: "Changa", sans-serif;
  color: #434343;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding: 8px 15px 8px 0;
}

.blog-post-grid .entry-content .read-btn:hover {
  color: #e43a90;
}

.blog-post-grid .entry-meta {
  border-top: 1px solid #e4e4e4;
  padding: 10px 20px;
}

.blog-post-grid .entry-meta a {
  color: #5d5a5a;
  font-size: 16px;
}

.blog-post-grid .entry-meta a i {
  margin-right: 5px;
  color: #5d5a5a;
}

.blog-post-grid .entry-meta a span {
  color: #5d5a5a;
}

.blog-post-grid .entry-meta a:hover {
  color: #e43a90;
}

.blog-post-grid .entry-meta .comments {
  margin-left: 30px;
  position: relative;
  float: right;
}

.blog-post-grid .post-type-icon {
  height: 50px;
  width: 50px;
  text-align: center;
  display: block;
  position: relative;
  z-index: 111;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 48px;
  transform: translate(7px, -70px);
  position: absolute;
  background: #e43a90;
}

.blog-post-grid .post-type-icon span {
  display: block;
  font-size: 16px;
}

.blog-post-grid .post-type-icon:before, .blog-post-grid .post-type-icon:after {
  display: none;
}

.post-type-icon {
  height: 65px;
  width: 65px;
  background-color: transparent;
  transform: translate(27px, -50px);
  font-size: 25px;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 11;
}

.post-type-icon:before {
  position: absolute;
  content: '';
  height: 65px;
  width: 65px;
  background: #e43a90;
  left: 0;
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 4px;
  z-index: -1;
}

.post-type-icon:after {
  position: absolute;
  content: '';
  border: 2px solid #fff;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  left: 0;
  top: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Blog List */
.blog-posts {
  padding: 100px 0;
}

.blog-post {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  margin-bottom: 30px;
  overflow: hidden;
}

.blog-post .post-thumbnail a {
  display: block;
}

.blog-post .post-thumbnail a img {
  max-width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.blog-post.blog-grid .entry-content-wrapper {
  padding: 25px 30px;
}

.blog-post .tim-btn-bgt.read-more-btn {
  border-color: #e4e4e4;
  color: #363636;
}

.blog-post .tim-btn-bgt.read-more-btn:hover {
  color: #e43a90;
}

.entry-content-wrapper {
  padding: 30px;
  position: relative;
  background: #fff;
}

.entry-content-wrapper ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: block;
  clear: both;
}

.entry-content-wrapper ul li {
  float: left;
  width: 50%;
  padding-left: 20px;
  position: relative;
  line-height: 30px;
}

.entry-content-wrapper ul li:before {
  position: absolute;
  content: '';
  left: 0;
  top: 9px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #464646;
}

.entry-content-wrapper .entry-meta ul.meta {
  margin: 0;
  padding: 0;
  list-style: none;
}

.entry-content-wrapper .entry-meta ul.meta li {
  display: inline-block;
  margin-right: 5px;
  font-family: "Changa", sans-serif;
  width: auto;
  padding-left: 0;
  float: none;
}

.entry-content-wrapper .entry-meta ul.meta li:before {
  display: none;
}

.entry-content-wrapper .entry-meta ul.meta li a {
  color: #717171;
}

.entry-content-wrapper .entry-meta ul.meta li a:hover {
  color: #e43a90;
}

.entry-content-wrapper .entry-meta ul.meta li .author a {
  color: #e43a90;
}

.entry-content-wrapper .entry-header .entry-title {
  font-size: 26px;
  font-weight: 500;
}

.entry-content-wrapper .entry-header .entry-title a {
  color: #363636;
}

.entry-content-wrapper .entry-header .entry-title a:hover {
  color: #e43a90;
}

.entry-content-wrapper .blog-footer {
  border-top: 1px dashed #e0e0e0;
  padding-top: 30px;
  margin-top: 80px;
}

.entry-content-wrapper .blog-footer .tag a {
  padding: 0 12px;
  background: #363636;
  font-size: 15px;
  line-height: 28px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  margin-right: 5px;
}

.entry-content-wrapper .blog-footer .tag a:hover {
  background: #e43a90;
  color: #fff;
}

.entry-content-wrapper .entry-content {
  margin-bottom: 30px;
}

.post-on {
  position: absolute;
  height: 65px;
  width: 65px;
  text-align: center;
  transform: translate(27px, -49px);
  padding: 12px 0;
  line-height: 20px;
}

.post-on:before {
  position: absolute;
  content: '';
  background: rgba(228, 58, 144, 0.9);
  border: 3px solid #e2dfe2;
  border-radius: 4px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: rotate(-45deg);
}

.post-on a {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  font-family: "Changa", sans-serif;
  height: 65px;
  width: 65px;
  z-index: 1;
  position: relative;
  color: #fff;
}

.post-on a span {
  display: block;
  font-size: 15px;
}

ul.shared-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  clear: inherit;
}

ul.shared-link li {
  height: 40px;
  width: 40px !important;
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 40px;
  float: none;
  width: auto;
  padding-left: 0;
  display: inline-block;
  margin-right: 15px;
}

ul.shared-link li:before {
  content: '';
  position: absolute;
  height: 40px;
  width: 40px;
  border: 4px solid #ebebeb;
  z-index: -1;
  left: 0;
  top: 0;
  transform: rotate(-45deg);
  box-shadow: 0px 5px 17.22px 3.78px rgba(180, 180, 180, 0.21);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
}

ul.shared-link li a {
  color: #717171;
  display: block;
  line-height: 40px;
}

ul.shared-link li:hover:before {
  background: #e43a90;
  border-color: #e43a90;
}

ul.shared-link li:hover a {
  color: #fff;
}

blockquote {
  color: #474747;
  background: #f6f6f6;
  padding: 30px 30px 30px 60px;
  font-size: 20px;
  font-style: italic;
  position: relative;
  font-weight: 500;
}

blockquote .quote-icon {
  position: absolute;
  left: 35px;
  top: 30px;
  color: #c5c5c5;
}

.blog-count-details {
  padding: 7px 0;
}

.blog-count-details a {
  color: #666;
  margin-right: 15px;
  position: relative;
  font-weight: 600;
}

.blog-count-details a:after {
  position: absolute;
  content: '';
  height: 12px;
  width: 1px;
  background: #bbb;
  right: -10px;
  top: 5px;
}

.blog-count-details a:last-child:after {
  display: none;
}

.blog-count-details a i {
  margin-right: 5px;
  color: #e43a90;
}

.blog-post-list {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}

/* Comment */
.comments-area ul {
  margin: 0;
  list-style: none;
}

.comments-area ul ul {
  margin-left: 50px;
}

.comment-inner {
  background: #fff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  border-radius: 25px;
  padding: 60px 40px;
  margin-bottom: 30px;
}

.reply-title h3, .comment-reply-title {
  font-size: 26px;
  font-weight: 600;
  color: #3c3c3c;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}

ul.comment-list .comment-body {
  position: relative;
  margin-bottom: 40px;
}

ul.comment-list .comment-body .comment-author a {
  font-size: 24px;
  color: #e43a90;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

ul.comment-list .comment-body .comment-author .author-thumb {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  border: 6px solid #ffffff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  float: left;
}

ul.comment-list .comment-body .comment-author .author-thumb img {
  max-width: 100%;
  border-radius: 50%;
}

ul.comment-list .comment-body .comment-author .comment-metadata a {
  font-size: 15px;
  color: #979696;
}

ul.comment-list .comment-body .author-name {
  margin-bottom: 10px;
}

ul.comment-list .comment-body .comment-content p {
  margin-bottom: 0;
}

ul.comment-list .comment-body .author-name, ul.comment-list .comment-body .comment-content {
  margin-left: 130px;
}

ul.comment-list .comment-body .reply {
  position: absolute;
  top: 0;
  right: 0;
}

ul.comment-list .comment-body .reply a {
  color: #363636;
  font-weight: 500;
  font-family: "Changa", sans-serif;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
}

ul.comment-list .comment-body .reply a i {
  margin: 5px;
}

ul.comment-list .comment-body .reply a:hover {
  color: #e43a90;
}

.comment-respond {
  background: #fff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  border-radius: 25px;
  padding: 60px 40px;
}

.comment-form:after {
  content: '';
  clear: both;
  display: block;
}

.comment-form p.comment-form-comment {
  width: 100%;
}

.comment-form p.comment-form-comment textarea {
  width: 100%;
  background: #fafafa;
  height: 170px;
  border: 1px solid #ededed;
  box-shadow: none;
  border-radius: 25px;
  padding: 20px;
  outline: 0;
  transition: all 0.5s ease-in-out;
}

.comment-form p.comment-form-comment textarea:hover, .comment-form p.comment-form-comment textarea:focus {
  border-color: #a59a9a;
}

.comment-form p {
  width: 33.33%;
  float: left;
  padding-right: 20px;
}

.comment-form p input {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  padding: 10px 20px;
  border: 1px solid #ededed;
  outline: 0;
  transition: all 0.5s ease-in-out;
}

.comment-form p input:hover, .comment-form p input:focus {
  border-color: #a59a9a;
}

.comment-form p input::placeholder {
  color: #838383;
}

.comment-form .form-submit {
  width: 100%;
  padding-right: 0;
  text-align: center;
  margin-top: 30px;
}

.comment-form .form-submit .submit {
  border: 1px solid #e4e4e4;
  padding: 10px 20px;
  color: #363636;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  max-width: 200px;
  text-transform: uppercase;
  font-family: "Changa", sans-serif;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: transparent;
}

.comment-form .form-submit .submit:hover {
  color: #e43a90;
}

/* Post Navigation */
.posts-navigation ul {
  margin: 60px 0 0;
  padding: 0;
  list-style: none;
}

.posts-navigation ul li {
  height: 35px;
  width: 35px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  line-height: 34px;
}

.posts-navigation ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #a5a4a4;
  line-height: 18px;
}

.posts-navigation ul li:hover a, .posts-navigation ul li.active a {
  color: #e43a90;
}

.posts-navigation ul li:first-child, .posts-navigation ul li:last-child {
  height: auto;
  width: auto;
  padding: 7px 20px;
  line-height: 18px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-post-grid {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .blog-post.blog-grid .entry-content-wrapper {
    padding: 25px 20px;
  }
  .blog-post.blog-grid .entry-content-wrapper .entry-header .entry-title {
    font-size: 20px;
  }
  .widget {
    padding: 20px 15px;
  }
}

@media (max-width: 768px) {
  .blog-post-grid .share-link {
    padding: 0 10px;
  }
  .blog-post-grid .entry-content {
    padding: 30px 15px;
  }
  .blog-post-grid .entry-meta {
    padding: 10px 15px;
  }
  .comment-form p {
    width: 100%;
    padding-right: 0;
  }
  .comment-respond {
    margin-bottom: 30px;
  }
  .widget_popular_entries .widgets-popular-post li {
    max-width: 350px;
  }
  .blog-posts {
    padding: 50px 0;
  }
}

@media (max-width: 500px) {
  .blog-post .post-type-icon {
    height: 50px;
    width: 50px;
    line-height: 48px;
    font-size: 22px;
    transform: translate(24px, -33px);
  }
  .blog-post .post-type-icon:before {
    height: 50px;
    width: 50px;
  }
  .entry-content-wrapper {
    padding: 30px 20px;
  }
  .entry-content-wrapper .blog-footer {
    text-align: center;
  }
  .entry-content-wrapper ul li {
    width: 100%;
    line-height: 28px;
  }
  .blog-count-details.float-left {
    float: none !important;
    margin-bottom: 10px;
  }
  ul.shared-link {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 20px;
  }
  ul.shared-link li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0;
  }
  blockquote {
    padding: 30px 30px 30px 45px;
  }
  blockquote .quote-icon {
    left: 15px;
  }
  ul.shared-link {
    margin: 0;
  }
  ul.shared-link.float-right {
    float: none !important;
  }
  ul.comment-list .comment-body {
    text-align: center;
  }
  ul.comment-list .comment-body .comment-author .author-thumb {
    float: none;
    margin: 0 auto;
  }
  ul.comment-list .comment-body .author-name, ul.comment-list .comment-body .comment-content {
    margin-left: 0;
  }
  ul.comment-list .comment-body .reply {
    position: static;
  }
  #blog-grid.section-padding {
    padding: 50px 0 20px;
  }
  #blog-grid.section-padding {
    padding-bottom: 20px !important;
  }
  .posts-navigation ul li {
    margin-top: 15px;
  }
}

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
.widget {
  background: #fff;
  padding: 40px 30px;
  text-align: center;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  border-radius: 15px;
  margin-bottom: 60px;
}

.widget.widget-shop {
  text-align: left !important;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.widget.widget-shop .widget-title-shop {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
  color: #363636;
  margin-bottom: 20px;
  font-family: "Changa", sans-serif;
}

.widget.widget-shop .widget-title-shop span {
  color: #e43a90;
}

.widget .widget-title {
  font-size: 26px;
  color: #3c3c3c;
  padding: 7px 0;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  font-family: "Changa", sans-serif;
  position: relative;
}

.widget .widget-title:before, .widget .widget-title:after {
  position: absolute;
  content: '';
  bottom: 0;
  background: #e43a90;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.widget .widget-title:before {
  width: 60px;
  height: 1px;
}

.widget .widget-title:after {
  height: 3px;
  bottom: -1px;
  width: 25px;
  left: 50%;
}

.widget .widget-title span {
  color: #e43a90;
}

.widget p {
  color: #676767;
}

.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.widget ul li {
  line-height: 45px;
  border-bottom: 1px dashed #e0e0e0;
}

.widget ul li:last-child {
  border-bottom: 0;
}

.widget ul li a {
  color: #585858;
  font-size: 16px;
  position: relative;
  display: block;
  font-family: "Changa", sans-serif;
  text-transform: uppercase;
}

.widget ul li a span {
  position: absolute;
  right: 0;
}

.widget ul li a:hover {
  color: #e43a90;
}

/* Widget Newsletter */
.widgit_newsletter {
  position: relative;
}

.widgit_newsletter:after {
  position: absolute;
  content: '';
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../../media/background/12.jpg);
  max-width: 250px;
  width: 100%;
  height: 213px;
  z-index: 1;
}

.widgit_newsletter .newsletter-form {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 222;
  background: rgba(255, 255, 255, 0.7);
}

.widgit_newsletter .newsletter-form input {
  height: 60px;
  border: 0;
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-radius: 4px 4px 0  0;
  background: transparent;
}

.widgit_newsletter .newsletter-form input::placeholder {
  color: #a2a2a2;
}

.widgit_newsletter .newsletter-form .tim-btn {
  width: 100%;
  display: block;
  border-radius: 0;
  padding: 14px 0;
  text-align: center;
  background: #e4e4e4;
  color: #363636;
  text-transform: uppercase;
}

.widgit_newsletter .newsletter-form .tim-btn:hover {
  background: #e43a90;
}

.widgit_newsletter p {
  color: #676767;
  margin-bottom: 25px;
  position: relative;
  z-index: 22;
}

/* Widget About Me */
.ab-thumbnails {
  margin-bottom: 20px;
}

.ab-thumbnails img {
  max-width: 100%;
  height: auto;
}

/* Popular Post */
.widgets-popular-post li {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.widgets-popular-post li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.widgets-popular-post li .content {
  float: left;
  max-width: 65%;
}

.widgets-popular-post li .content .entry-meta {
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}

.widgets-popular-post li .content .entry-meta.cat a {
  color: #e43a90;
}

.widgets-popular-post li .content h3 {
  font-size: 18px;
}

.widgets-popular-post li .feature-img {
  float: right;
  width: 35%;
  display: block;
}

.widgets-popular-post li .feature-img img {
  max-width: 100%;
}

/* Product Price Filter */
#slider-range {
  background-color: #363636;
}

#range-slider p {
  line-height: 1.6875em;
  color: #363636;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

#range-slider p #amount {
  border: 0;
  background-color: transparent;
}

.ui-slider-horizontal {
  height: 4px;
}

.ui-slider-horizontal .ui-slider-range {
  background-color: #e43a90;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -10px;
}

.ui-widget.ui-widget-content {
  border: 0;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  background: #e43a90;
}

.ui-slider .ui-slider-handle {
  box-shadow: inset 0px 6px 3.68px 0.32px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: #e43a90;
  border: 0;
  outline: 0;
}

.fil-btn {
  padding: 10px 25px;
  color: #363636;
  border: 1px solid #e4e4e4;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  margin-top: 30px;
  font-size: 17px;
  line-height: 20px;
  border-radius: 4px;
}

.fil-btn:hover {
  color: #e43a90;
}

/* Best Sales */
.best-sales .sales-item {
  display: inline-block;
  position: relative;
  margin: 5px;
  width: 29%;
}

.best-sales .sales-item:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(228, 58, 144, 0.9);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.best-sales .sales-item img {
  max-width: 100%;
}

.best-sales .sales-item .content {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 15px;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.best-sales .sales-item .content a {
  color: #696969;
  position: relative;
  z-index: 2;
  text-align: center;
  line-height: 28px;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-bottom: 10px;
}

.best-sales .sales-item .content a i {
  font-size: 14px;
  margin-left: -2px;
}

.best-sales .sales-item .content a:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 4px;
  transform: rotate(-45deg);
  z-index: -1;
  top: 0;
  left: 0;
}

.best-sales .sales-item .content a:hover {
  color: #e43a90;
}

.best-sales .sales-item .content .star-rating {
  margin: 0 auto;
  text-align: center;
  width: 4.7em;
}

.best-sales .sales-item .content .star-rating:before {
  font-size: 14px;
}

.best-sales .sales-item .content .star-rating span:before {
  color: #fff;
  font-size: 14px;
}

.best-sales .sales-item .content .price {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  line-height: 20px;
}

.best-sales .sales-item .content .price span {
  line-height: 20px;
}

.best-sales .sales-item:hover:before {
  opacity: 1;
}

.best-sales .sales-item:hover .content {
  opacity: 1;
}

@media (min-width: 768px) and (max-width: 1320px) {
  .best-sales .sales-item {
    width: 44%;
  }
}

@media (max-width: 767px) {
  .best-sales .sales-item {
    width: auto;
  }
}

/* Adds */
.adds-thumbnails a {
  display: block;
}

.adds-thumbnails a img {
  max-width: 100%;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .widget {
    padding: 20px 15px;
  }
  .widget ul li a {
    font-size: 14px;
  }
  #range-slider p {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .widgit_newsletter:after {
    bottom: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Logo Carousel
  --------------------------------------------------------------*/
#logo-carousel {
  padding: 65px 0;
}

.tim-logo-carousel .swiper-wrapper {
  display: flex;
  align-items: center;
}

.tim-logo-carousel .brand-logo {
  text-align: center;
}

.tim-logo-carousel .brand-logo img {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}

.tim-logo-carousel .brand-logo:hover img {
  opacity: 1;
}

@media (max-width: 768px) {
  #logo-carousel {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##  lesson
  --------------------------------------------------------------*/
#lesson {
  padding: 120px 0 90px;
}

.lesson-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  max-width: 400px;
  margin: 0 auto 30px;
}

.lesson-box:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 11;
}

.lesson-box img {
  border-radius: 8px;
  vertical-align: top;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
}

.lesson-box i {
  color: #fff;
  font-size: 30px;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 22;
}

.lesson-box .content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  text-align: center;
  z-index: 22;
}

.lesson-box .content h3 {
  color: #fff;
  font-weight: 700;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.lesson-box .content h3 a {
  color: #fff;
}

.lesson-box .content h3 a:hover {
  color: #e43a90;
}

.lesson-box .content p {
  color: #c5c5c5;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.05s;
}

.lesson-box:hover:before {
  opacity: 1;
}

.lesson-box:hover .content h3, .lesson-box:hover .content p {
  transform: translateY(-50px);
}

.lesson-box:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 767px) {
  #lesson {
    padding: 50px 0 20px;
  }
}

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
.about-details img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.about-details h3 {
  font-size: 34px;
  font-weight: 500;
}

.about-details h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.about-details p {
  margin-bottom: 50px;
}

.artist-details-two .artist-name {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.artist-details-two .band-name {
  font-size: 18px;
  font-weight: 400;
  color: #e43a90;
  margin-bottom: 40px;
}

.artist-details-two .band-name span {
  color: #fff;
}

.artist-details-two .details {
  margin-bottom: 20px;
}

.artist-details-two .details p {
  color: #fff;
  margin-bottom: 50px;
}

.artist-music-inner {
  padding: 20px 0;
  border-top: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
}

.artist-music-inner .aritist-music {
  padding: 0 30px;
  border-right: 2px solid #d9d9d9;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.artist-music-inner .aritist-music .icon {
  float: left;
  margin-right: 20px;
}

.artist-music-inner .aritist-music .icon i {
  color: #e43a90;
  font-size: 50px;
  line-height: 50px;
}

.artist-music-inner .aritist-music .content {
  margin-left: 70px;
}

.artist-music-inner .aritist-music .content p {
  color: #494848;
  font-size: 30px;
  margin: 0;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  margin-bottom: 5px;
}

.artist-music-inner .aritist-music .content span {
  font-size: 20px;
  color: #494848;
  font-family: "Changa", sans-serif;
}

.artist-music-inner .aritist-music:last-child {
  border-right: 0;
}

.artist-music-inner .aritist-music:first-child {
  padding-left: 0;
}

.artist-music-inner.artist-music-inner-two {
  border-top: 2px dashed #717171;
  border-bottom: 2px dashed #717171;
  margin-bottom: 40px;
}

.artist-music-inner.artist-music-inner-two .aritist-music {
  border-color: #333;
  padding: 0 15px;
}

.artist-music-inner.artist-music-inner-two .aritist-music .icon {
  margin-right: 0;
}

.artist-music-inner.artist-music-inner-two .aritist-music .content {
  margin-left: 60px;
}

.artist-music-inner.artist-music-inner-two .aritist-music .content p, .artist-music-inner.artist-music-inner-two .aritist-music .content span {
  color: #fff;
}

.artist-music-inner.artist-music-inner-two .aritist-music .content span {
  display: block;
  line-height: 20px;
}

.album-cover-img {
  position: relative;
}

.album-cover-img img {
  max-width: 100%;
  margin-bottom: 30px;
}

.album-cover-img .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 46px;
  color: #fff;
  font-size: 20px;
  transform: translate(-50%, -50%);
  outline: 0;
}

.artist-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.artist-social-link li {
  display: inline-block;
  margin-right: 5px;
}

.artist-social-link li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  color: #fff;
  border: 1px dashed #fff;
  border-radius: 4px;
  text-align: center;
  line-height: 38px;
}

.artist-social-link li a:hover {
  border-color: #e43a90;
  color: #e43a90;
}

/* Music Playlist One */
.hit-plalist {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #f5f5f5;
}

.hit-plalist li {
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
}

.hit-plalist li:last-child {
  border-bottom: 0;
}

.hit-plalist li .sm2_button {
  width: 7%;
  display: inline-block;
  font-family: "Changa", sans-serif;
  color: #363636;
  font-size: 18px;
  text-align: center;
}

.hit-plalist li .song-name {
  width: 63%;
  text-align: left;
  display: inline-block;
}

.hit-plalist li span {
  width: 10%;
  display: inline-block;
  text-align: center;
  font-family: "Changa", sans-serif;
}

.hit-plalist li .video-song, .hit-plalist li .song-download {
  width: 10%;
  display: inline-block;
  text-align: center;
  color: #363636;
}

.hit-plalist li .video-song:hover, .hit-plalist li .song-download:hover {
  color: #e43a90;
}

.hit-plalist li.active {
  background: #e43a90;
}

.hit-plalist li.active .sm2_button, .hit-plalist li.active .video-song, .hit-plalist li.active .song-download, .hit-plalist li.active span {
  color: #fff;
}

.hit-plalist li.active .video-song:hover, .hit-plalist li.active .song-download:hover {
  color: #751043;
}

/* About Two */
.artist-about .artist-name {
  font-size: 34px;
  font-weight: 500;
  margin-top: 0;
}

.artist-about .artist-name a {
  color: #363636;
}

.artist-about .artist-name a:hover {
  color: #e43a90;
}

.artist-about h6 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.artist-about span {
  display: block;
  margin-bottom: 10px;
  color: #828282;
}

.artist-about .tim-btn {
  padding: 7px 20px;
  font-size: 16px;
  margin-bottom: 20px;
  position: absolute;
  right: 30px;
  top: 0;
}

.artist-about .content {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.artist-about .alb-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.alb-single {
  display: flex;
  flex-wrap: wrap;
}

.alb-single .single-items {
  display: inline-block;
  width: 20%;
  padding-right: 5px;
}

.alb-single .single-items img {
  max-width: 100%;
}

.album-feature {
  position: relative;
}

.album-feature img {
  max-width: 100%;
}

.album-feature .artist-music-inner {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(228, 58, 144, 0.8);
  border-top: 0;
  padding-left: 30px;
}

.album-feature .artist-music-inner .aritist-music {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.album-feature .artist-music-inner .aritist-music .icon i {
  color: #fff;
}

.album-feature .artist-music-inner .aritist-music .content p, .album-feature .artist-music-inner .aritist-music .content span {
  color: #fff;
}

.sm2_button {
  height: 30px;
  width: 30px;
  position: relative;
  background: transparent;
}

.sm2_button i:after {
  content: "\f04b";
  font: normal normal normal 14px/1 FontAwesome;
}

.sm2_button.sm2_playing i:after {
  content: "\f04c";
  font: normal normal normal 14px/1 FontAwesome;
}

.artist-about.pt_70 .tim-btn {
  top: 70px;
}

@media (max-width: 1600px) {
  .artist-about {
    margin-top: 0;
  }
}

@media (max-width: 1440px) {
  .alb-single {
    margin-bottom: 20px;
  }
  .artist-music-inner .aritist-music {
    padding: 0 15px;
  }
  .artist-music-inner .aritist-music .content {
    margin-left: 55px;
  }
  .artist-music-inner .aritist-music .content p {
    font-size: 26px;
  }
  .artist-music-inner .aritist-music .content span {
    font-size: 14px;
    display: block;
    line-height: 16px;
  }
  .artist-music-inner .aritist-music .icon {
    margin-right: 0;
  }
  .artist-music-inner .aritist-music .icon i {
    font-size: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .artist-music-inner .aritist-music {
    padding: 0 5px;
  }
  .artist-music-inner .aritist-music .icon i {
    font-size: 30px;
  }
  .artist-music-inner .aritist-music .content {
    margin-left: 35px;
  }
  .artist-music-inner .aritist-music .content p {
    margin: 0;
  }
  .artist-about.pt_70 {
    padding-top: 0;
  }
  .artist-about.pt_70 .tim-btn {
    top: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .artist-about .artist-name {
    font-size: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .artist-music-inner.artist-music-inner-two .aritist-music .content {
    margin-left: 40px;
  }
  .artist-details-two .band-name {
    margin-bottom: 20px;
  }
  .artist-details-two .details p {
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .artist-profile .profoile-image {
    max-width: 190px !important;
  }
  .artist-profile {
    padding: 30px 25px;
  }
  .artist-profile .content {
    margin-left: 210px !important;
  }
}

@media (max-width: 767px) {
  .artist-profile .profoile-image {
    float: none !important;
    margin: 0 auto;
  }
  .artist-profile .content {
    margin-left: 0 !important;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .album-feature {
    margin-bottom: 30px;
  }
  .artist-about.pl__30 {
    padding-left: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .artist-music-inner .aritist-music {
    border-right: 0;
  }
}

@media (max-width: 768px) {
  .artist-music-inner .aritist-music {
    padding: 0 10px;
  }
  .album-feature .artist-music-inner .aritist-music {
    border-right: 0;
  }
}

@media (max-width: 580px) {
  .album-cover-img {
    margin: 0 auto 20px;
  }
  .artist-music-inner {
    display: block;
  }
  .artist-music-inner .aritist-music {
    border-right: 0;
    padding-left: 0;
    width: 50%;
    float: left;
    margin-bottom: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .artist-music-inner .aritist-music .content {
    margin-left: 30px;
  }
  .artist-music-inner .aritist-music .content p {
    font-size: 21px;
  }
  .artist-music-inner .aritist-music .icon i {
    font-size: 20px;
    line-height: 30px;
  }
  .artist-about .tim-btn {
    position: relative;
    right: 0;
    top: 5px;
  }
  .artist-about.pt_70 .tim-btn {
    top: 6px;
  }
}

/*--------------------------------------------------------------
  ##  Event About
  --------------------------------------------------------------*/
#event-about {
  padding: 100px 0;
}

.event-thumb img {
  max-width: 100%;
}

.event-content h2 {
  font-size: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.event-content h2 span {
  color: #e43a90;
}

.event-content h2 em {
  font-style: normal;
  font-weight: 400;
}

.event-content p {
  margin-bottom: 0;
}

.event-content .event-details {
  margin: 30px 0;
}

.event-content .event-details p {
  margin: 0;
}

.event-content .event-details p span {
  font-weight: 600;
  font-size: 18px;
  font-family: "Changa", sans-serif;
}

.event-content h4 {
  font-size: 18px;
  font-weight: 600;
}

.event-content .tim-btn {
  padding: 12px 27px;
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
}

@media (max-width: 991px) {
  .event-content {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  #event-about {
    padding: 50px 0;
  }
  .event-content h2 {
    font-size: 26px;
  }
}

/*--------------------------------------------------------------
  ##  Sale Album
  --------------------------------------------------------------*/
#sale-album {
  background: #f7f7f7;
}

.gallery-tab .swiper-slide .slider-tab {
  float: right;
}

.slider-content h3 {
  font-size: 24px;
  font-weight: 500;
}

.slider-content p {
  font-size: 16px;
}

.slider-content ul {
  margin-bottom: 20px;
  list-style: none;
}

.slider-content ul li {
  line-height: 30px;
  font-size: 16px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 7px;
}

.slider-content ul li:before {
  position: absolute;
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  left: 0;
  top: 5px;
  color: #e43a90;
  border: 1px solid #cecece;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  padding-left: 2px;
}

.sale-tabs {
  position: relative;
  max-width: 1072px;
  margin: 0 auto;
  text-align: center;
}

.sale-tabs li {
  width: 25%;
}

.sale-tabs li a {
  color: #5b5b5b;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 30px;
  position: relative;
  font-family: "Changa", sans-serif;
}

.sale-tabs li a:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  border: 1px solid  #cdcdcd;
  background: #fff;
  border-radius: 50%;
  left: 50%;
  bottom: -7px;
  transform: translate(-50%);
}

.sale-tabs li a.active:after {
  position: absolute;
  content: '';
  height: 9px;
  width: 9px;
  background: #e43a90;
  border-radius: 50%;
  left: 50%;
  bottom: -4px;
  transform: translate(-50%);
}

/* Tab Nav */
.tabnav-wrap {
  position: relative;
}

.tabnav-wrap:before {
  content: '';
  width: 100%;
  height: 1px;
  background: #cdcdcd;
  position: absolute;
  bottom: 20px;
}

.tab-content .tab-pane {
  padding: 50px 15px 0;
}

.tab-content .tab-pane.active {
  animation: fadeIn .75s ease forwards;
}

.tim-music-player {
  width: 100%;
  margin: 0;
}

.tim-music-player .player {
  width: 100%;
  float: left;
}

/* Track Info */
.tim-music-player .track-info p {
  font-family: "Changa", sans-serif;
  color: #363636;
}

.tim-music-player .track-info p.title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.tim-music-player .track-info p.artist-outer {
  font-size: 16px;
}

.tim-music-player .player .album-cover img {
  max-width: 100%;
}

.tim-music-player .album-cover {
  position: relative;
}

.tim-music-player .album-cover .track-info {
  position: absolute;
  left: 15px;
  bottom: 60px;
}

.tim-music-player .album-cover .track-info .title {
  color: #fff;
  font-family: "Changa", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.tim-music-player .album-cover .track-info .artist-outer {
  color: #fff;
}

.tim-music-player .album-cover .player-controls {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
}

.tim-music-player .album-cover .player-controls > div {
  cursor: pointer;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tim-music-player .album-cover .player-controls .main .play-control {
  height: 35px;
  width: 35px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 5px;
}

.tim-music-player .album-cover .player-controls .main .jp-stop {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin-right: 10px;
}

.tim-music-player .album-cover .player-controls .main .jp-stop:before {
  content: "\f04d";
  color: #fff;
  font-size: 12px;
  font: normal normal normal 14px/1 FontAwesome;
}

.tim-music-player .album-cover .player-controls .jp-time-holder {
  margin-right: 10px;
}

.tim-music-player .album-cover .player-controls .jp-duration {
  margin-right: 10px;
  color: #fff;
}

.tim-music-player .progress-wrapper {
  height: 9px;
  max-width: 140px;
  width: 100%;
  margin: 7px 10px 5px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

.tim-music-player .progress {
  height: 9px;
  width: 135px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}

.tim-music-player .elapsed {
  height: 9px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 60%;
  background: #e43a90;
}

.tim-music-player .description {
  clear: both;
  margin: 30px 0 0 0;
  font-size: 12px;
  color: #999;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
}

.tim-music-player .description.showing {
  margin: 30px 0;
}

.tim-music-player .player {
  width: 40%;
  float: left;
  padding-right: 30px;
}

.tim-music-player .tracklist .title {
  max-width: 60%;
  width: 100%;
  display: inline-block;
  padding: 0 0 0 14px;
  cursor: pointer;
}

.tim-music-player .jp-volume-controls button {
  display: block;
  position: absolute;
  overflow: hidden;
  text-indent: -9999px;
  border: none;
  cursor: pointer;
}

.tim-music-player .jp-volume-bar {
  overflow: hidden;
  background: #fff;
  width: 46px;
  height: 5px;
  cursor: pointer;
}

.tim-music-player .jp-volume-bar-value {
  width: 0;
  height: 5px;
  background: #e43a90;
  display: block;
}

.tim-music-player .jp-mute {
  width: 20px;
  height: 20px;
  line-height: 18px;
  position: relative;
}

.tim-music-player .jp-mute:before {
  content: "\f028";
  font: normal normal normal 14px/1 FontAwesome;
  color: #fff;
  font-size: 12px;
}

.tim-music-player .jp-current-time {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.jp-state-muted .jp-unmute {
  width: 20px;
  height: 20px;
  line-height: 18px;
  position: relative;
}

.jp-state-muted .jp-unmute:after {
  content: "\f026";
  font: normal normal normal 14px/1 FontAwesome;
  color: #fff;
  font-size: 12px;
}

.tim-music-player .rating {
  height: 16px;
  margin: 20px 0 20px 0;
}

.tim-music-player .rating-star {
  width: 15px;
  height: 16px;
  display: block;
  float: left;
  cursor: pointer;
}

.tim-music-player .rating-star:before {
  font-family: FontAwesome;
  content: "\f005";
  font-size: 15px;
  color: #333;
}

.tim-music-player .rating-bar:before {
  font-family: FontAwesome;
  content: "\f005";
  font-size: 11px !important;
  color: #333;
}

.hover:before,
.on:before {
  color: #FFFF00 !important;
}

/* Player */
.progress {
  float: left;
}

.tim-music-player .previous, .tim-music-player .next {
  color: #fff;
}

.tim-music-player .previous:hover:before, .tim-music-player .next:hover:before {
  color: #e43a90;
}

div.jp-seeking-bg {
  background: url("../img/jplayer_seeking.gif") !important;
}

.tim-music-player .play {
  color: #fff;
}

.tim-music-player .play:before {
  content: "\f102";
}

.tim-music-player .pause:before {
  content: "\f101";
}

.tim-music-player .play:before, .tim-music-player .pause:before {
  font-family: tim-icon;
  font-size: 14px;
  color: #f5f5f5;
  margin-left: 1px;
}

.tim-music-player .pause {
  color: #fff;
}

/** Track List **/
.tim-music-player .tracklist {
  padding: 0;
  border: 1px solid #ededed;
  overflow: hidden;
}

.tim-music-player .tracklist ol {
  margin: 0;
  padding: 0;
}

.tim-music-player .tracklist li {
  padding: 11px 0 11px 20px;
  list-style-position: inside;
  position: relative;
  border-bottom: 1px solid #ededed;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
  font-family: "Changa", sans-serif;
}

.tim-music-player .tracklist li:hover {
  color: #fff;
  background: #e43a90;
}

.tim-music-player li:last-child {
  border-bottom: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.tim-music-player .show-more-button li:last-child {
  background: transparent url(../img/tracklist-item-bg.png) repeat-x bottom left scroll;
}

.tim-music-player li:nth-child(1n + 10) .title {
  padding-left: 7px;
}

.tim-music-player li.playing {
  color: #fff !important;
  background: #e43a90;
}

.tim-music-player .tracklist .rating {
  margin-left: 30px;
  width: 66px;
  display: block;
  position: absolute;
  right: 92px;
  top: -15px;
}

.tim-music-player .rating-bar {
  width: 13px;
  display: block;
  float: left;
  cursor: pointer;
}

.tim-music-player .buy {
  height: 18px;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  width: 8%;
  color: #363636;
}

.tim-music-player .video {
  color: #363636;
  width: 16%;
  text-align: center;
  display: block;
}

.tim-music-player li.track:hover .buy,
.tim-music-player li .playing .buy, .tim-music-player li.track:hover .video {
  color: #fff;
}

.tim-music-player .more {
  font-size: 14px;
  padding: 5px 0;
  cursor: pointer;
  text-align: center;
  background: #fff;
  position: relative;
}

.tim-music-player .more:hover {
  color: #fff;
  background: #e43a90;
}

.tim-music-player .more,
.tim-music-player .album-cover .highlight,
.tim-music-player .tracklist li,
.tim-music-player .buy {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tim-music-player .tracklist li span {
  display: inline-block;
}

.tim-music-player .player:after {
  clear: both;
  content: ' ';
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

.music-single .tracklist {
  width: 94%;
  margin-top: 20px;
}

.music-single .player {
  width: 100%;
  float: none;
  display: block;
}

.music-single .track-info {
  width: 67%;
}

.music-single .player-controls {
  width: 67%;
}

.music-single .player-controls div {
  width: 79%;
}

.wide-mp3 .player-controls {
  width: 54%;
}

.wide-mp3 .progress-wrapper {
  width: 173px;
}

/* Best Music 2018 */
#the-sorcerers-wrath-container {
  margin-top: 30px;
}

#the-sorcerers-wrath-container .jp-audio {
  max-width: 100%;
  margin-bottom: 60px;
}

#the-sorcerers-wrath-container .jp-controls {
  float: left;
  width: 95px;
  min-height: 42px;
  margin-bottom: 30px;
}

#the-sorcerers-wrath-container .jp-controls a {
  float: left;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border: 2px solid #434343;
  border-radius: 50%;
  color: #434343;
  outline: 0;
}

#the-sorcerers-wrath-container .jp-controls a i {
  font-size: 16px;
}

#the-sorcerers-wrath-container .jp-controls a.jp-play {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  margin-top: -5px;
}

#the-sorcerers-wrath-container .jp-controls a.jp-play i {
  font-size: 22px;
  line-height: 46px;
  margin-left: 3px;
}

#the-sorcerers-wrath-container .jp-controls a:hover {
  background: transparent;
}

#the-sorcerers-wrath-container .jp-seek-bar {
  background: #ededed;
  cursor: pointer;
}

#the-sorcerers-wrath-container .jp-play-bar {
  background: #e43a90;
}

#the-sorcerers-wrath-container .jp-middle {
  position: relative;
  width: -webkit-calc( 100% - 95px);
  width: calc( 100% - 95px);
  min-height: 42px;
  float: left;
  padding: 0 0 0 22px;
}

#the-sorcerers-wrath-container .jp-progress {
  width: 100%;
  height: 8px;
  margin-bottom: 4px;
}

#the-sorcerers-wrath-container .jp-seek-bar {
  width: 0;
  height: 100%;
}

#the-sorcerers-wrath-container .jp-play-bar {
  width: 0;
  height: 8px;
  position: relative;
}

#the-sorcerers-wrath-container .jp-title {
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 6px;
}

#the-sorcerers-wrath-container .jp-current-time {
  float: left;
}

#the-sorcerers-wrath-container .jp-duration {
  float: right;
}

#the-sorcerers-wrath-container .jp-volume-max {
  display: block;
  width: 24px;
  min-height: 42px;
  float: left;
  font-size: 14px;
  text-align: center;
  padding-top: 8px;
}

#the-sorcerers-wrath-container .jp-volume-bar {
  width: 59%;
  height: 8px;
  float: left;
  margin-top: 17px;
}

#the-sorcerers-wrath-container .jp-volume-bar-value {
  width: 0;
  height: 8px;
  position: relative;
}

#the-sorcerers-wrath-container .jp-playlist {
  clear: both;
}

#the-sorcerers-wrath-container .jp-playlist ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ededed;
}

#the-sorcerers-wrath-container .jp-playlist ul li {
  position: relative;
  border-bottom: 1px solid #ededed;
}

#the-sorcerers-wrath-container .jp-playlist ul li:last-child {
  border-bottom: 0;
}

#the-sorcerers-wrath-container .jp-playlist ul li a {
  display: block;
  position: relative;
  min-height: 50px;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 1px;
  padding: 0 20px;
  color: #494949;
  font-family: "Changa", sans-serif;
  font-size: 16px;
}

#the-sorcerers-wrath-container .jp-playlist ul li.jp-free-media {
  position: absolute;
  display: inline;
  top: 0;
  right: 20px;
  font-size: 65%;
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  z-index: 1;
}

#the-sorcerers-wrath-container .jp-playlist ul li.jp-free-media a {
  height: auto;
  margin: 0;
  padding: 0;
  display: inline;
  font-weight: normal;
}

#the-sorcerers-wrath-container .jp-playlist ul li.jp-free-media a:hover {
  box-shadow: none;
}

#the-sorcerers-wrath-container .jp-playlist ul li:hover, #the-sorcerers-wrath-container .jp-playlist ul li.jp-playlist-current {
  background: #e43a90;
}

#the-sorcerers-wrath-container .jp-playlist ul li:hover a, #the-sorcerers-wrath-container .jp-playlist ul li.jp-playlist-current a {
  outline: 0;
  color: #fff;
}

#the-sorcerers-wrath-container .track-byline {
  font-weight: normal;
}

.jp-controls a {
  color: #e43a90;
}

.jp-state-playing .jp-play i:before {
  content: "\f04c";
}

/* Bast Sale Album */
.best-sale-album-cover {
  width: 45%;
  float: left;
}

.best-sale-album-cover img {
  max-width: 100%;
}

.music-playlist-two {
  margin-left: 47%;
}

.music-playlist-two .hit-plalist .sm2_button {
  width: 10%;
}

.music-playlist-two .hit-plalist .song-name {
  width: 60%;
}

@media (max-width: 1280px) {
  .tim-music-player .progress-wrapper {
    width: 110px;
  }
}

@media (min-width: 991px) and (max-width: 1279px) {
  .tim-music-player .tracklist .title {
    width: 55%;
  }
  .tim-music-player .player {
    padding-right: 10px;
  }
  .slider-content p {
    font-size: 14px;
  }
  .slider-content ul li {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .tim-music-player .tracklist .title {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .tim-music-player .player {
    max-width: 400px;
    float: none;
    width: 100%;
    margin: 0 auto 30px;
  }
  .best-sale-album-cover {
    width: 100%;
    float: none;
  }
  .music-playlist-two {
    margin-left: 0px;
  }
  .hit-plalist li span {
    width: 15%;
  }
  .hit-plalist li .song-name {
    width: 50%;
  }
  .hit-plalist li .video-song {
    width: 14%;
  }
  .music-playlist-two .hit-plalist .song-name {
    width: 47%;
  }
}

@media (max-width: 500px) {
  .tim-music-player .tracklist .title {
    width: 52%;
  }
  .tim-music-player .tracklist li {
    padding: 10px 0 11px 8px;
  }
  .tim-music-player .tracklist li span {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-content ul li {
    font-size: 15px;
    float: left;
    padding-right: 7px;
    width: 50%;
  }
  .best-sale-album {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
  ##   Album
  --------------------------------------------------------------*/
#album {
  padding: 120px 0;
}

#album-two {
  padding: 120px 0 110px;
}

.album-filter-button {
  margin: 0 auto 50px;
  padding: 0;
  list-style: none;
  max-width: 1000px;
  text-align: center;
  position: relative;
}

.album-filter-button:after {
  position: absolute;
  content: '';
  height: 1px;
  border-bottom: 1px dashed #dedede;
  width: 90%;
  top: 50%;
  z-index: -1;
  left: 27px;
}

.album-filter-button li {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 58px;
  text-align: center;
  border: 1px dashed #dedede;
  border-radius: 50%;
  margin-left: 160px;
  background: #fff;
}

.album-filter-button li a {
  display: block;
  text-transform: uppercase;
  color: #363636;
  font-weight: 500;
}

.album-filter-button li:first-child {
  margin-left: 0 !important;
}

.album-filter-button li:hover, .album-filter-button li.current {
  background: #e43a90;
  border-color: #e43a90;
  border-style: solid;
}

.album-filter-button li:hover a, .album-filter-button li.current a {
  color: #fff;
}

.album-filter-button.album-filter-button-two {
  max-width: 760px;
}

.album-filter-button.album-filter-button-two:after {
  width: 90%;
  left: 0;
}

.album-filter-button.album-filter-button-two li {
  height: auto;
  width: auto;
  line-height: 20px;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px dashed #dedede;
  margin-left: 90px;
  transition: all 0.3s ease-in-out;
}

.album-filter-button.album-filter-button-two li:hover, .album-filter-button.album-filter-button-two li.current {
  background: #fff;
  border-color: #e43a90;
  border-style: solid;
  border: 1px dashed #e43a90;
}

.album-filter-button.album-filter-button-two li:hover a, .album-filter-button.album-filter-button-two li.current a {
  color: #e43a90;
}

.tim-album-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tim-album-items .grid-item {
  width: 24.5%;
  padding: 15px;
}

.tim-album-items .grid-item:before {
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
}

.tim-isotope {
  position: relative;
}

.tim-isotope.tim-isotope-1 .tim-album-item {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  position: relative;
  position: relative;
  overflow: hidden;
}

.tim-isotope.tim-isotope-1 .tim-album-item img {
  border-radius: 8px;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  max-width: 100%;
  height: auto;
}

.tim-isotope.tim-isotope-1 .tim-album-item:before {
  position: absolute;
  content: '';
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap {
  position: absolute;
  left: 0;
  bottom: 45px;
  width: 100%;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:before, .tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:after {
  content: '';
  position: absolute;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:before {
  width: 30px;
  height: 1px;
  left: 50%;
  bottom: 0;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:after {
  width: 30px;
  height: 5px;
  bottom: -2px;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h5 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap .tim-btn {
  color: #fff;
  padding: 5px 30px;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 15px;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap .tim-btn:hover {
  background: #cf1c77;
}

.tim-isotope.tim-isotope-1 .tim-album-item:hover:before {
  opacity: 1;
}

.tim-isotope.tim-isotope-1 .tim-album-item:hover .album_details_wrap {
  visibility: visible;
  opacity: 1;
}

.tim-isotope.tim-isotope-1 .tim-album-item:hover .album_details_wrap h4:before {
  width: 100px;
}

.tim-isotope.tim-isotope-2 .tim-album-item {
  position: relative;
}

.tim-isotope.tim-isotope-2 .tim-album-item:before {
  position: absolute;
  content: '';
  left: 15px;
  bottom: 15px;
  right: 15px;
  top: 15px;
  border-radius: 8px;
  background: rgba(228, 58, 144, 0.58);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.tim-isotope.tim-isotope-2 .tim-album-item .tim-isotope-grid__img {
  border-radius: 8px;
  overflow: hidden;
}

.tim-isotope.tim-isotope-2 .tim-album-item .tim-isotope-grid__img img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-2 .tim-album-item .popup-modal {
  position: absolute;
  top: 40px;
  left: 40px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap {
  text-align: left;
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 2;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap .gallery-info {
  opacity: 0;
  visibility: hidden;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap .gallery-info h4 {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  transform: translateY(-15px);
  transition: all 0.3s ease-in-out;
  transition-delay: 100ms;
  opacity: 0;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap .gallery-info h5 {
  font-size: 15px;
  color: #fff;
  margin: 0;
  transform: translateY(-15px);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover:before {
  opacity: 1;
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .tim-isotope-grid__img img {
  transform: scale(1.1);
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .popup-modal, .tim-isotope.tim-isotope-2 .tim-album-item:hover .gallery-info {
  visibility: visible;
  opacity: 1;
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .popup-modal {
  transform: scale(1);
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .gallery-info h4, .tim-isotope.tim-isotope-2 .tim-album-item:hover .gallery-info h5 {
  transform: translateY(0);
  opacity: 1;
}

.grid-item, .grid-sizer {
  width: 25%;
}

/* Exclusive Album */
.exclusive-album-items {
  padding: 0;
}

.exclusive-album-items .album-item {
  position: relative;
  margin: 0 5px 10px;
}

.exclusive-album-items .album-item:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(228, 58, 144, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.exclusive-album-items .album-item .album-image img {
  max-width: 100%;
}

.exclusive-album-items .album-item .album-image .exse-album-name {
  position: absolute;
  top: 0;
  left: 0;
}

.exclusive-album-items .album-item .album-image .exse-album-name .icon {
  height: 40px;
  width: 45px;
  line-height: 40px;
  background: #e43a90;
  text-align: center;
  margin-right: 5px;
  display: inline-block;
  color: #fff;
  font-size: 22px;
  vertical-align: top;
}

.exclusive-album-items .album-item .album-image .exse-album-name h4 {
  color: #fff;
  background: #e43a90;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  display: inline-block;
  line-height: 20px;
  margin: 0;
  vertical-align: top;
  text-transform: uppercase;
}

.exclusive-album-items .album-item .album-image .exse-album-name.exse-album-bottom {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

.exclusive-album-items .album-item .content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
}

.exclusive-album-items .album-item .content h4 {
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  line-height: 26px;
  transform: translateY(15px);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.exclusive-album-items .album-item .content span {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  transform: translateY(15px);
  transition: all 0.3s ease-in-out;
  transition-delay: 100ms;
  opacity: 0;
  display: inline-block;
}

.exclusive-album-items .album-item .content .icon {
  position: absolute;
  right: 20px;
  top: 23px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.exclusive-album-items .album-item .content .icon a {
  color: #fff;
  font-size: 25px;
}

.exclusive-album-items .album-item .content .icon a:hover {
  color: #e43a90;
}

.exclusive-album-items .album-item:hover:before {
  opacity: 1;
}

.exclusive-album-items .album-item:hover h4, .exclusive-album-items .album-item:hover span {
  transform: translateY(0);
  opacity: 1;
}

.exclusive-album-items .album-item:hover .icon {
  opacity: 1;
}

@media (max-width: 1280px) {
  .album-filter-button {
    max-width: 600px;
  }
  .album-filter-button li {
    margin-left: 70px;
  }
  .album-filter-button.album-filter-button-two {
    max-width: 760px;
  }
  .album-filter-button:after {
    width: 90%;
    left: 0;
  }
  .tim-album-items .grid-item, .tim-album-items .grid-sizer {
    width: 33%;
  }
}

@media (max-width: 1024px) {
  .album-filter-button {
    max-width: 600px;
  }
  .album-filter-button.album-filter-button-two li {
    margin-left: 60px;
  }
  .album-filter-button.album-filter-button-two:after {
    left: 30px;
    transform: translateX(0);
    width: 90%;
  }
}

@media (max-width: 992px) {
  .album-filter-button {
    max-width: 450px;
  }
  .album-filter-button li {
    height: 50px;
    width: 50px;
    line-height: 46px;
    margin-left: 45px;
  }
}

@media (max-width: 768px) {
  #album {
    padding: 50px 0;
  }
  .tim-album-items .grid-item, .tim-album-items .grid-sizer {
    width: 50%;
  }
  .album-filter-button.album-filter-button-two {
    max-width: 425px;
  }
  .album-filter-button.album-filter-button-two:after {
    display: none;
  }
  .album-filter-button.album-filter-button-two li {
    padding: 8px 12px;
    margin-left: 25px;
  }
  .album-filter-button.album-filter-button-two li a {
    font-size: 13px;
  }
  #album-two {
    padding: 60px 0 50px;
  }
  .exclusive-album-items .album-item .album-image .exse-album-name.exse-album-bottom {
    top: 0;
    left: 0;
  }
  #gallery .section-title h2 {
    margin-bottom: 0;
  }
  #gallery .album-filter-button {
    margin: 0 auto 20px;
  }
  .album-filter-button {
    margin: 0 auto 20px;
  }
}

@media (max-width: 580px) {
  .album-filter-button {
    max-width: 330px;
  }
  .album-filter-button:after {
    left: 40px;
    width: 76%;
  }
  .album-filter-button li {
    margin-left: 9px;
    height: 40px;
    width: 40px;
    line-height: 37px;
  }
  .album-filter-button li a {
    font-size: 10px;
  }
  .album-filter-button.album-filter-button-two {
    max-width: 500px;
  }
}

@media (max-width: 500px) {
  .tim-album-items .grid-item {
    width: 100%;
  }
  .album-filter-button.album-filter-button-two li {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

/*--------------------------------------------------------------
  ##   Artist Profiles
  --------------------------------------------------------------*/
.artist-profile {
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.22);
  padding: 65px 40px;
}

.artist-profile.artist-profile-details {
  padding: 65px 40px 40px;
}

.artist-profile .profoile-image {
  float: left;
  max-width: 250px;
  text-align: center;
}

.artist-profile .profoile-image img {
  max-width: 100%;
  border-radius: 50%;
}

.artist-profile .profoile-image .artist-social-link {
  margin-top: 10px;
}

.artist-profile .profoile-image .artist-social-link li {
  margin-right: 5px;
  height: auto;
  width: auto;
  border: 0;
}

.artist-profile .profoile-image .artist-social-link li a {
  color: #868686;
  height: auto;
  width: auto;
  border: 0;
}

.artist-profile .profoile-image .artist-social-link li a:hover {
  color: #e43a90;
  border: 0;
}

.artist-profile .content {
  margin-left: 280px;
}

.artist-profile .content h3 {
  font-size: 34px;
  font-weight: 500;
}

.artist-profile .content h3 a {
  color: #363636;
}

.artist-profile .content h3 a:hover {
  color: #e43a90;
}

.artist-profile .content span {
  display: block;
  margin-bottom: 10px;
  color: #828282;
}

.artist-profile .content p {
  margin-bottom: 30px;
}

.artist-profile .content .tim-btn {
  font-size: 16px;
  text-transform: uppercase;
  padding: 7px 20px;
}

.artist-profile .recent-concert {
  margin-top: 35px;
}

.artist-profile .recent-concert .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 12px;
}

.artist-profile .recent-concert p {
  margin-bottom: 30px;
}

.artist-profile .video-inner {
  position: relative;
}

.artist-profile .video-inner .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  font-size: 20px;
  transform: translate(-50%, -50%);
  outline: 0;
}

.artist-profile {
  margin-bottom: 30px;
}

.artist-profile .video-inner img {
  max-width: 100%;
}

/*--------------------------------------------------------------
  ##   Featured Album
  --------------------------------------------------------------*/
#feature-album {
  padding: 100px 0 70px;
}

.feature-albums .tim-album-item {
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto 30px;
}

.feature-albums .tim-album-item:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.feature-albums .tim-album-item .tim-feature-image {
  border-radius: 8px;
}

.feature-albums .tim-album-item .tim-feature-image img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
}

.feature-albums .tim-album-item .album_details_wrap {
  position: absolute;
  left: 0;
  bottom: 45px;
  width: 100%;
  visibility: hidden;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.feature-albums .tim-album-item .album_details_wrap h4 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.feature-albums .tim-album-item .album_details_wrap h4:before, .feature-albums .tim-album-item .album_details_wrap h4:after {
  content: '';
  position: absolute;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.feature-albums .tim-album-item .album_details_wrap h4:before {
  width: 30px;
  height: 1px;
  left: 50%;
  bottom: 0;
}

.feature-albums .tim-album-item .album_details_wrap h4:after {
  width: 30px;
  height: 5px;
  bottom: -2px;
}

.feature-albums .tim-album-item .album_details_wrap h5 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.feature-albums .tim-album-item .album_details_wrap .tim-btn {
  color: #fff;
  border-color: #fff;
  padding: 5px 30px;
  font-size: 16px;
  margin-top: 15px;
  text-transform: uppercase;
}

.feature-albums .tim-album-item .album_details_wrap .tim-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

.feature-albums .tim-album-item:hover:before {
  opacity: 1;
}

.feature-albums .tim-album-item:hover .album_details_wrap {
  opacity: 1;
  visibility: visible;
}

.feature-albums .tim-album-item:hover .album_details_wrap h4:before {
  width: 100px;
}

@media (max-width: 768px) {
  #feature-album {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##   Newsletter
  --------------------------------------------------------------*/
#newsletter {
  padding: 90px 0;
  background: #e8e8e8;
}

.newsletter h2 {
  font-size: 36px;
  font-weight: 600;
}

.newsletter h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}

.section-dark h2, .section-dark h4 {
  color: #fff;
}

.newsletter-inner {
  margin-top: 7px;
}

.newsletter-inner .newsletter-form {
  display: flex;
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}

.newsletter-inner .newsletter-form input {
  height: 60px;
  background: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 15px 20px;
}

.newsletter-inner .newsletter-form input::placeholder {
  color: #363636;
  font-family: "Changa", sans-serif;
}

.newsletter-inner .newsletter-form input:hover, .newsletter-inner .newsletter-form input:focus {
  outline: 0;
}

.newsletter-inner .newsletter-form .tim-btn {
  padding: 10px 23px;
  border-radius: 0;
  text-transform: uppercase;
  border: 0;
}

.newsletter-inner .newsletter-form .tim-btn:hover {
  background: #e12483;
  color: #fff;
}

.newsletter-form > button > i {
  display: none;
}

/* Newsletter Two */
.newsletter-wrap-two {
  position: relative;
  padding: 90px 0;
}

.newsletter-wrap-two:before {
  position: absolute;
  content: '';
  background: rgba(228, 58, 144, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.newsletter-wrap-two .newsletter-inner .newsletter-form input {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.newsletter-wrap-two .newsletter-inner .newsletter-form .tim-btn {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

@media (max-width: 575px) {
  .newsletter-inner .newsletter-form .tim-btn {
    position: relative;
    left: 50%;
    margin-left: -70px;
    margin-top: 20px;
  }
  .newsletter-wrap-two .newsletter-inner .newsletter-form input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .newsletter-wrap-two .newsletter-inner .newsletter-form .tim-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .newsletter-inner .newsletter-form .tim-btn {
    padding: 7px 23px;
  }
  .newsletter-inner .newsletter-form input {
    height: 45px;
  }
  .newsletter-inner .newsletter-form {
    display: block;
  }
}

@media (max-width: 768px) {
  .newsletter {
    text-align: center;
    margin-bottom: 30px;
  }
  #newsletter {
    padding: 50px 0;
  }
  .newsletter h2 {
    font-size: 28px;
  }
  .newsletter-wrap-two {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##   Sticky Audio Player
  --------------------------------------------------------------*/
.app-player {
  background: rgba(228, 58, 144, 0.8);
}

/*--------------------------------------------------------------
  ##   Event Schedule
  --------------------------------------------------------------*/
#event-schedule {
  display: flex;
  padding-bottom: 100px;
}

.schedule-ticket {
  position: relative;
  max-width: 32%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.schedule-ticket img {
  max-width: 100%;
}

.schedule-ticket .content {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
}

.schedule-ticket .content p {
  font-size: 18px;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  color: #fff;
  margin: 0;
}

.schedule-ticket .content h3 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.schedule-ticket .content .tim-btn {
  padding: 10px 30px;
  text-transform: uppercase;
  color: #fff;
  border-color: #fff;
}

.schedule-ticket .content .tim-btn:hover {
  color: #e43a90;
}

.schedule {
  width: 68%;
  float: left;
}

.schedule .schedule-item {
  text-align: center;
  padding: 41px 0;
  border: 1px solid #ededed;
  border-left: 0;
}

.schedule .schedule-item .schedule-thumb {
  height: 125px;
  width: 125px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

.schedule .schedule-item .schedule-thumb img {
  width: 100%;
  border-radius: 50%;
}

.schedule .schedule-item .sch-time {
  font-size: 24px;
  color: #666666;
  font-weight: 600;
}

.schedule .schedule-item .band-name {
  font-size: 18px;
  font-weight: 600;
  color: #e43a90;
  position: relative;
  padding-top: 10px;
}

.schedule .schedule-item .band-name:before {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  width: 50px;
  height: 2px;
  background: #e43a90;
  transform: translateX(-50%);
}

.schedule .schedule-item p {
  color: #747373;
  margin: 0;
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .schedule .schedule-item p {
    font-size: 14px;
  }
  .schedule-ticket .content {
    left: 10%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .schedule-ticket .content {
    left: 10%;
  }
}

@media (max-width: 1024px) {
  #event-schedule {
    display: block;
    background: #f3f3f3;
  }
  .schedule {
    width: 100%;
  }
  .schedule-ticket {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  #event-schedule {
    padding-bottom: 50px;
  }
}

@media (max-width: 350px) {
  .schedule-ticket .content {
    top: 7px;
    left: 19px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .schedule-ticket .content h3 {
    font-size: 25px;
    margin-bottom: 8px;
  }
}

@media (max-width: 500px) {
  .schedule-ticket .content h3 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------
  ##   Recent Performance
  --------------------------------------------------------------*/
#recent-performance {
  padding: 100px 0;
  background: #f8f8f8;
}

.performace {
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto 30px;
}

.performace:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(228, 58, 144, 0.58);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.performace .per-thumb img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.performace .content {
  position: absolute;
  text-align: center;
  bottom: 30px;
  width: 78%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(60, 60, 60, 0.85);
  padding: 15px 0;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.performace .content:before {
  position: absolute;
  content: '';
  left: -7px;
  right: -7px;
  top: -7px;
  bottom: -7px;
  border: 3px solid rgba(60, 60, 60, 0.85);
  transition: all 0.3s ease-in-out;
}

.performace .content h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
}

.performace .content h5 {
  font-size: 16px;
  color: #fff;
}

.performace:hover:before {
  opacity: 1;
}

.performace:hover .per-thumb img {
  transform: scale(1.05);
}

.performace:hover .content {
  background: rgba(255, 255, 255, 0.8);
}

.performace:hover .content h3, .performace:hover .content h5 {
  color: #e43a90;
}

.performace:hover .content:before {
  border-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1400px) {
  #recent-performance {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##   Pricing
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0;
}

.pricing-table {
  text-align: center;
  background-repeat: no-repeat;
  padding: 60px 0;
  background-size: cover;
  position: relative;
  max-width: 365px;
  margin: 0 auto 30px;
}

.pricing-table .pricbg, .pricing-table .pricbg-bottom {
  position: absolute;
  filter: drop-shadow(-1px -11px 5px rgba(0, 0, 0, 0.1));
}

.pricing-table .pricbg path, .pricing-table .pricbg-bottom path {
  fill: #fff;
}

.pricing-table .pricbg {
  top: 0;
  left: 0;
}

.pricing-table .pricbg path {
  fill: #f1f1f1;
  transition: all 0.3s ease-in-out;
}

.pricing-table .pricbg-bottom {
  bottom: 0;
  left: 0;
  z-index: -1;
  filter: drop-shadow(0px 8px 5px rgba(0, 0, 0, 0.1));
}

.pricing-table .pricing-head {
  padding-bottom: 33px;
  position: relative;
}

.pricing-table .pricing-head .price-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
}

.pricing-table .price-inner {
  box-shadow: 0 -15px 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing-table .price {
  font-size: 55px;
  font-weight: 600;
  color: #e43a90;
  line-height: 55px;
  padding: 40px 0 20px;
}

.pricing-table .price-feature {
  margin: 0;
  list-style: none;
  padding: 0 70px 40px;
}

.pricing-table .price-feature li {
  line-height: 40px;
  border-bottom: 1px dashed #d7d7d7;
}

.pricing-table .tim-btn {
  padding: 12px 30px;
  background: transparent;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  text-transform: uppercase;
  color: #696868;
  font-size: 20px;
  display: inline-block;
}

.pricing-table .tim-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

.pricing-table:hover .pricbg path {
  fill: #e43a90;
}

.pricing-table:hover .pricing-head .price-title {
  color: #fff;
}

@media (max-width: 1400px) {
  .pricing-table .pricing-head {
    padding-bottom: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .pricing-table {
    padding: 45px 0;
  }
  .pricing-table .pricing-head {
    padding-bottom: 0;
  }
  .pricing-table .pricing-head .price-title {
    font-size: 28px;
  }
  .pricing-table .price-feature {
    padding: 0 30px 20px;
  }
  .pricing-table .price {
    padding: 50px 0 10px;
    font-size: 45px;
    margin-bottom: 0;
  }
  .container.con-pd {
    padding: 0 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .pricing-table {
    padding: 50px 0;
  }
  .pricing-table .pricing-head {
    padding-bottom: 0;
  }
  .pricing-table .pricing-head .price-title {
    font-size: 30px;
  }
  .pricing-table .price {
    font-size: 45px;
    padding: 50px 0 0;
  }
  .pricing-table .price-feature {
    padding: 0 30px 20px;
  }
  .pricing-table .tim-btn {
    padding: 8px 25px;
    font-size: 16px;
  }
}

@media (max-width: 1400px) {
  #pricing {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  #pricing .container.con-pd {
    padding: 0;
  }
}

/*--------------------------------------------------------------
  ##   Tabs
  --------------------------------------------------------------*/
.tabs-filter-button {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #313131;
  padding: 37px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.tabs-filter-button li {
  position: relative;
  padding-left: 20px;
}

.tabs-filter-button li:after {
  content: '';
  position: absolute;
  right: -60px;
  top: 6px;
  height: 16px;
  width: 2px;
  background: #4e4e4e;
}

.tabs-filter-button li:last-child:after {
  display: none;
}

.tabs-filter-button li a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.tabs-filter-button li a:hover {
  color: #e43a90;
}

.tabs-filter-button li.current a {
  color: #e43a90;
}

.tab-details .details {
  margin: 0;
  padding: 30px 40px;
  list-style: none;
  box-shadow: 0px 5px 21px 0px rgba(142, 142, 142, 0.23);
  background: rgba(255, 255, 255, 0.5);
}

.tab-details .details li {
  font-family: "Changa", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #585757;
  position: relative;
  padding-bottom: 5px;
  display: inline-block;
  text-align: left;
  width: 16%;
}

.tab-details .details li:before, .tab-details .details li:after {
  content: '';
  position: absolute;
  background: #adadad;
}

.tab-details .details li:before {
  bottom: 0;
  width: 25%;
  height: 1px;
  left: 25px;
  transform: translateX(-50%);
}

.tab-details .details li:after {
  left: 16px;
  width: 15px;
  height: 4px;
  bottom: -1px;
}

.tab-details.tab-dark ul {
  background: #313131;
}

.tab-details.tab-dark ul li {
  color: #fff;
}

.songs-details {
  margin: 0;
  padding: 0;
  list-style: none;
}

.songs-details li {
  display: inline-block;
  width: 16%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.songs-details li a {
  color: #585757;
}

.songs-details li a:hover {
  color: #e43a90;
}

.songs-details li span {
  color: #e43a90;
}

.songs-details li i {
  margin-right: 5px;
}

.tabs-filter {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-filter-wraper {
  background-image: url(../../media/background/13.jpg);
  padding-bottom: 30px;
}

.tab-filter-wraper.top-music-wrap {
  box-shadow: 0px 5px 21px 0px rgba(184, 184, 184, 0.23);
}

.tim-isotope-3 {
  padding: 0 40px;
}

.tim-songs-items.grid-item {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d1d1d1;
}

.tim-songs-items.grid-item:last-child {
  border-bottom: 0;
}

.tim-songs-items.tranding-song {
  padding: 16px 0;
  border-bottom: 1px solid #d1d1d1;
}

.tim-songs-items.tranding-song:before {
  clear: both;
  content: '';
  display: block;
}

/* Top Music */
#top-music {
  padding: 120px 0;
  background: #fbfbfb;
}

.circle-player {
  background: #f5f5f5;
  padding: 25px;
}

#jp_container_1 .jp-type-playlist {
  background: #dea23c;
  padding: 80px;
}

#jp_container_1 .jp-gui {
  margin: 0 auto;
  height: 410px;
  width: 410px;
  border-radius: 50%;
  background: #FFF;
  padding: 80px;
  position: relative;
}

#jp_container_1 .jp-gui .jp-controls {
  background: #dea23c;
  border-radius: 50%;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 65px 0;
}

#jp_container_1 .jp-gui .jp-controls button {
  border: 0;
  background: transparent;
}

#jp_container_1 .jp-gui .jp-next, #jp_container_1 .jp-gui .jp-previous {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#jp_container_1 .jp-gui .jp-next {
  right: 25px;
}

#jp_container_1 .jp-gui .jp-previous {
  left: 25px;
}

#jp_container_1 .jp-gui .jp-volume-controls {
  border-radius: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

#jp_container_1 .jp-gui .jp-volume-controls .jp-volume-bar {
  height: 5px;
  background: #fff;
  width: 70px;
}

#jp_container_1 .jp-gui .jp-volume-controls .jp-volume-bar .jp-volume-bar-value {
  height: 5px;
  background: #4c4c4c;
}

#jp_container_1 .jp-gui .jp-volume-controls .jp-mute {
  margin-right: 5px;
}

#jp_container_1 .jp-gui .jp-progress {
  width: 150px;
  margin: 0 auto;
}

#jp_container_1 .jp-gui .jp-progress .jp-seek-bar {
  height: 5px;
  background: #fff;
}

#jp_container_1 .jp-gui .jp-progress .jp-seek-bar .jp-play-bar {
  height: 5px;
  background: #4c4c4c;
}

#jp_container_1 .jp-gui .jp-progress .jp-current-time {
  float: left;
}

#jp_container_1 .jp-gui .jp-progress .jp-duration {
  float: right;
}

.tab-filter-wrapper {
  padding: 0 40px;
}

@media (max-width: 1200px) {
  .tabs-filter-button li:after {
    right: -44px;
  }
}

@media (max-width: 1200px) {
  .tabs-filter-button li:after {
    right: -30px;
  }
}

@media (max-width: 1199px) {
  .tab-pd-left, .tab-pd-right {
    padding: 70px 35px;
  }
}

@media (max-width: 768px) {
  .tabs-filter-button li {
    width: 25%;
  }
  .tabs-filter-button li:after {
    right: -17px;
  }
  .tim-isotope-3 {
    padding: 0;
  }
  .tab-details .details {
    display: none;
  }
  .songs-details li {
    display: block;
    width: 100%;
    float: right;
    padding: 15px;
    border-bottom: 1px solid #b1b1b1;
  }
  .songs-details li:last-child {
    border-bottom: 0;
  }
  .songs-details li:before {
    width: 30%;
    float: left;
    font-weight: 600;
    text-align: left;
  }
  .songs-details li:nth-of-type(1):before {
    content: "Artist";
  }
  .songs-details li:nth-of-type(2):before {
    content: "Title";
  }
  .songs-details li:nth-of-type(3):before {
    content: "Type";
  }
  .songs-details li:nth-of-type(4):before {
    content: "Rating";
  }
  .songs-details li:nth-of-type(5):before {
    content: "Published";
  }
  .songs-details li:nth-of-type(6):before {
    content: "View";
  }
  .tim-songs-items.grid-item {
    padding: 15px 0;
    border-bottom: 2px solid #000;
  }
  .tim-songs-items.tranding-song {
    padding: 0;
    border-bottom: 2px solid #211f1f;
  }
  .tab-filter-wrapper {
    padding: 0;
    overflow: hidden;
  }
  #top-music {
    padding: 50px 0;
  }
}

@media (max-width: 575px) {
  .tabs-filter-button li {
    width: 50%;
  }
  .tab-pd-left, .tab-pd-right {
    padding: 30px 15px 15px;
  }
}

/*--------------------------------------------------------------
  ##   Gallery
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##   Page Header
  --------------------------------------------------------------*/
.page-header {
  padding: 280px 0 30px;
  background-position: center;
  background-size: cover;
}

.page-header.event-header {
  padding: 300px 0 30px;
}

.page-header.event-header .page-header-title {
  padding-bottom: 150px;
}

.page-header .page-header-title {
  padding-bottom: 40px;
}

.page-header .page-header-title h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.page-header .page-header-title h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.page-header .page-header-title.event-page-header h2 {
  font-size: 36px;
}

.page-header .page-header-title.event-page-header h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.page-header .live-ticket-count {
  margin-bottom: 50px;
  text-align: center;
}

.page-header .live-ticket-count .countdown {
  margin: 0 auto;
}

.page-header .tim-btn.tim-btn-bgt {
  border: 1px solid #e4e4e4;
  color: #fff;
}

.page-header .tim-btn.tim-btn-bgt:hover {
  color: #e43a90;
}

.breadcrumbs a, .breadcrumbs span {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

.breadcrumbs a {
  color: #b4b4b4;
}

.breadcrumbs a:hover {
  color: #e43a90;
}

@media (max-width: 992px) {
  .page-header {
    padding-top: 155px !important;
  }
}

@media (max-width: 768px) {
  .page-header .page-header-title h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .page-header .live-ticket-count {
    margin-bottom: 20px;
    text-align: center;
  }
}

/*--------------------------------------------------------------
  ##   Page Header Magazine
  --------------------------------------------------------------*/
.mag-header-inner h2 {
  font-size: 60px;
  color: #fff;
}

.mag-header-inner h2 span {
  color: #db9a0a;
}

.mag-bn {
  position: relative;
  width: auto;
  z-index: 1;
  padding: 13px 35px;
  text-align: center;
  display: inline-block;
  margin-left: 15px;
}

.mag-bn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewX(-15deg);
  background: #313130;
  z-index: -1;
}

.mag-bn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 3%;
  width: 94%;
  height: 100%;
  -webkit-transform: skewX(-15deg);
  -ms-transform: skewX(-15deg);
  transform: skewX(-15deg);
  z-index: -1;
  border-left: 7px solid #fff;
  border-right: 7px solid #fff;
}

.mag-bn p {
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.mag-bn p span {
  color: #fff;
}

.mag-header-inner {
  max-width: 1350px;
  margin: 0 auto;
}

.mag-social ul {
  padding: 0;
  list-style: none;
}

.mag-social ul li {
  display: inline;
}

.mheader-top span {
  float: left;
}

.mheader-top p {
  float: left;
}

.mheader-top .mag-social {
  float: right;
}

.mheader-top {
  display: block;
  overflow: hidden;
  background: #1d1d1d;
  padding: 16px 15px 16px;
  border-radius: 5px 5px 0px 0px;
}

.mag-header-inner .mag-bn::before {
  background: #dc9c0a;
}

.mheader-top span {
  float: left;
  color: #fff;
  background: #dc9c0a;
  padding: 6px 13px;
  overflow: hidden;
  display: block;
  border-radius: 5px 0px 0px 5px;
  margin-right: 15px;
}

.mheader-top p {
  float: left;
  padding-top: 6px;
  margin: 0px;
  color: #adadad;
  font-size: 14px;
}

.mag-social ul li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  margin-right: 8px;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  line-height: 35px;
}

.mag-social ul li a.fb {
  background: #071662;
}

.mag-social ul li a.tt {
  background: #4d91e5;
}

.mag-social ul li a.ins {
  background: #9d1973;
}

.mag-social ul li a.gp {
  background: #cd0630;
}

.mag-header-inner img {
  max-width: 100%;
}

.headr-post {
  width: 25%;
  float: left;
  position: relative;
  z-index: 1;
}

.mheader-post {
  display: block;
  overflow: hidden;
}

.hp-content {
  position: absolute;
  bottom: 0;
  left: 16px;
  z-index: 999;
}

.hp-content p a {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 24px;
}

.hp-content a {
  color: #da9a0a;
  font-size: 18px;
  font-weight: 400;
}

.hp-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(to bottom, #000, transparent);
  background: -webkit-linear-gradient(bottom, #000, transparent);
  opacity: 0.7;
  z-index: 1;
  top: 0;
}

.magazine-tab-area .tab-content {
  overflow: hidden;
  width: 100%;
}

.page-header.magazine-header {
  padding-bottom: 0;
}

/*--------------------------------------------------------------
  ##   Woocommerce
  --------------------------------------------------------------*/
#product.section-padding {
  padding-bottom: 65px;
}

.product-top-menu {
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
}

.product-top-menu span {
  font-family: "Changa", sans-serif;
  margin-right: 5px;
}

.product-top-menu #product-show {
  padding: 0 22px 0 6px;
  margin-right: 20px;
}

.product-top-menu .product-select {
  border: 1px solid #ebebeb;
  background: transparent;
  font-family: "Changa", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #7a7979;
  outline: 0;
  padding: 0 10px;
}

.product-top-menu .product-menu-wrapper {
  width: 450px;
  float: right;
}

.tim-product-btn-vw input {
  display: none;
}

.tim-product-btn-vw label {
  cursor: pointer;
}

/* Product */
ul.products li.product {
  text-align: left;
}

ul.products li.product .product-thumb {
  position: relative;
  overflow: hidden;
  margin: 0 auto 20px;
}

ul.products li.product .product-thumb img {
  max-width: 100%;
  margin-bottom: 0;
}

ul.products li.product .product-thumb .new {
  position: absolute;
  top: 10px;
  right: -10px;
  background: #e43a90;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 17px;
}

ul.products li.product .product-thumb:before {
  position: absolute;
  content: '';
  background: rgba(228, 58, 144, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

ul.products li.product .product-thumb .add_to_cart_button {
  padding: 8px 25px;
  border: 1px solid #fff;
  font-family: "Changa", sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}

ul.products li.product .product-thumb .add_to_cart_button:hover {
  background: #434343;
  color: #fff;
  border-color: #434343;
}

ul.products li.product .product-thumb:hover .add_to_cart_button {
  bottom: 20px;
}

ul.products li.product .product-thumb:hover:before {
  opacity: 1;
}

ul.products li.product .woocommerce-loop-product__title {
  font-family: "Changa", sans-serif;
  color: #363636;
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

ul.products li.product .woocommerce-loop-product__title a {
  font-family: "Changa", sans-serif;
  color: #363636;
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

ul.products li.product .woocommerce-loop-product__title a:hover {
  color: #e43a90;
}

ul.products li.product .woocommerce-loop-product__title:hover {
  color: #e43a90;
}

ul.products li.product .price {
  display: block;
  float: right;
  margin: 0;
}

ul.products li.product .price .woocommerce-Price-amount {
  font-size: 24px;
  font-weight: 500;
  color: #e43a90;
  font-family: "Changa", sans-serif;
}

ul.products li.product-two {
  text-align: center;
}

ul.products li.product-two .woocommerce-loop-product__title {
  font-size: 22px;
}

ul.products li.product-two .star-rating {
  float: right;
}

ul.products li.product-two .product-thumb {
  position: relative;
}

ul.products li.product-two .product-thumb:before {
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.3);
}

ul.products li.product-two .product-thumb .product-details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: #fff;
  box-shadow: 0px 2px 12.09px 0.91px rgba(105, 104, 104, 0.08);
  border-radius: 5px;
  width: 160px;
  opacity: 0;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

ul.products li.product-two .product-thumb .product-details a {
  width: 40px;
  display: inline-block;
  text-align: center;
}

ul.products li.product-two .product-thumb .product-details a i {
  color: #636363;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
}

ul.products li.product-two .product-thumb .product-details a:nth-child(2) {
  border-right: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
}

ul.products li.product-two .product-thumb .product-details a:hover i {
  color: #e43a90;
}

ul.products li.product-two .product-thumb .new {
  left: 0;
  right: auto;
  font-family: "Changa", sans-serif;
}

ul.products li.product-two .product-thumb .new.sale {
  right: 0;
  left: auto;
  background: #d2d40b;
}

ul.products li.product-two .product-thumb:hover .product-details {
  opacity: 1;
}

ul.products li.product-two .product-thumb:hover .share-link {
  opacity: 1;
  visibility: visible;
}

ul.products li.product-two .product-thumb:hover .share-link li {
  transform: scale(1);
}

ul.products li.product-two .star-rating {
  font-size: 14px;
}

ul.products li.product-two .star-rating span:before {
  font-size: 14px;
  color: #e43a90;
}

ul.products li.product-two .price {
  float: none;
}

ul.products li.product-two .price ins {
  margin-left: 0;
  text-decoration: none;
}

ul.products li.product-two .price del .woocommerce-Price-amount {
  font-size: 18px;
  color: #898989;
  margin-left: 10px;
}

ul.products li.product-two .product-description {
  display: none;
}

ul.products li.product-two .share-link {
  position: absolute;
  bottom: 50px;
  text-align: center;
  width: 100%;
  list-style: none;
  opacity: 0;
  visibility: hidden;
}

ul.products li.product-two .share-link li {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  position: relative;
  margin-right: 10px;
  text-align: center;
  line-height: 35px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

ul.products li.product-two .share-link li:before {
  position: absolute;
  height: 30px;
  width: 30px;
  background: #fff;
  content: '';
  transform: rotate(45deg);
  z-index: 0;
  left: 3px;
  box-shadow: 0px 2px 12.09px 0.91px rgba(105, 104, 104, 0.23);
  border-radius: 4px;
  transition: all 265ms ease-in;
}

ul.products li.product-two .share-link li a {
  display: block;
  position: relative;
  z-index: 1;
  line-height: 30px;
}

ul.products li.product-two .share-link li:nth-child(2) {
  transition-delay: 0.01s;
}

ul.products li.product-two .share-link li:nth-child(3) {
  transition-delay: 0.05s;
}

ul.products li.product-two .share-link li:nth-child(3) {
  transition-delay: 0.07s;
}

ul.products li.product-two .share-link li:nth-child(4) {
  transition-delay: 0.09s;
}

ul.products li.product-two .share-link li:hover:before {
  transform: rotate(0deg);
  border-radius: 50%;
}

ul.products li.product-two .share-link li.fa-col a {
  color: #4868ab;
}

ul.products li.product-two .share-link li.tw-col a {
  color: #1da2f2;
}

ul.products li.product-two .share-link li.ins-col a {
  color: #f23751;
}

ul.products li.product-two .share-link li.dri-col a {
  color: #ee1370;
}

ul.products li.product-two .ajax_add_to_cart {
  border-radius: 10px;
  padding: 10px 18px;
  position: relative;
  font-size: 16px;
}

ul.products li.product-two .ajax_add_to_cart:before {
  position: absolute;
  content: '';
  left: 20px;
  height: 20px;
  width: 2px;
  background: rgba(255, 255, 255, 0.5);
  top: 12px;
  left: 43px;
}

ul.products li.product-two .ajax_add_to_cart i {
  margin-right: 20px;
}

/* Product 3 Column */
.site-main .columns-3 ul.products li.product {
  width: 33.33%;
  padding: 0 15px;
  margin: 0 0 40px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two {
  width: 98% !important;
  text-align: left;
  border: 1px solid #ebebeb;
  padding: 0;
  display: flex;
  align-items: center;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
  max-width: 31%;
  margin-bottom: 0;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
  padding: 0 20px;
  width: 69%;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .woocommerce-loop-product__title {
  font-size: 30px;
  margin-bottom: 20px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .product-description {
  display: block;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .price {
  margin-bottom: 20px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content p {
  margin-bottom: 30px;
  font-size: 16px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button {
  display: inline-block;
  padding: 15px 35px;
  border: 1px solid #e4e4e4;
  color: #363636;
  font-size: 18px;
  line-height: 18px;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  font-weight: 500;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button i {
  margin-right: 10px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button:hover {
  background: #e43a90;
  border-color: #e43a90;
  color: #fff;
}

/* Product 4 Column */
.site-main .columns-4 ul.products li.product {
  margin-right: 0;
  width: 25%;
  padding: 0 15px;
  margin-bottom: 50px;
}

.quickview-slider swiper-slide img {
  width: 100%;
}

.quickview-slider .slider-nav .slick-slide {
  padding: 0 3px;
}

.product-cart {
  margin: 40px 0 60px;
}

.product-cart .quantity {
  position: relative;
  background: #FFF;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.09);
  width: 90px;
  height: 50px;
  border: none;
  border-radius: 5px;
  float: left;
  padding: 11px 12px;
  margin-right: 10px;
}

.product-cart .quantity .minus, .product-cart .quantity .plus {
  cursor: pointer;
  color: #363636;
  font-size: 12px;
}

.product-cart .quantity .minus:focus, .product-cart .quantity .plus:focus {
  background: transparent;
  color: #363636;
}

.product-cart .quantity input {
  width: 51%;
  margin: 0 auto;
  border: none;
  padding: 0;
  text-align: center;
  font-size: 16px;
}

.product-cart .tim-cart-btn {
  background: #e43a90;
  padding: 10px 17px;
  color: #FFF;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  font-family: "Changa", sans-serif;
  cursor: pointer;
}

.product-cart .tim-cart-btn i {
  margin-right: 5px;
}

.product-cart .tim-cart-btn:hover {
  background: #333;
  color: #FFF;
}

.product-details .close-menu {
  right: 15px;
  top: 0px;
  padding: 0;
  height: auto;
  width: auto;
  line-height: 1;
  cursor: pointer;
}

.product-details .product-title {
  font-size: 26px;
}

.product-details .product-sub-title {
  font-size: 20px;
}

.product-details .price {
  margin-bottom: 30px;
}

.product-details .price ins {
  margin-right: 10px;
  text-decoration: none;
}

.product-details .price ins, .product-details .price del {
  font-size: 20px;
  font-weight: 600;
}

.product-details .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}

.product_meta .sku_wrapper {
  margin: 10px 0;
}

.product_meta .posted_in {
  margin: 10px 0;
}

.product_meta .tagcloud a {
  font-size: 14px;
  padding: 3px 15px;
  border-radius: 10px;
}

.product_meta .posted_in a {
  color: #333;
  font-size: 14px;
  margin: 3px;
}

.product_meta .posted_in a:hover {
  color: #e43a90;
}

.woocommerce-product-rating .star-rating {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  height: 19px;
}

.woocommerce-product-rating .star-rating:before {
  font-size: 14px;
}

.share-wrap {
  margin-top: 20px;
}

.share-wrap h3 {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
}

.product-share-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.product-share-link li {
  display: inline-block;
  margin-right: 5px;
}

.product-share-link li a {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  color: #fff;
  transition: all .3s ease-in-out;
}

.product-share-link li a.facebook-bg {
  background: #305fb3;
}

.product-share-link li a.twitter-bg {
  background: #5eaade;
}

.product-share-link li a.google-plus-bg {
  background: #d7482a;
}

.product-share-link li a.pinterest-bg {
  background: #bd081c;
}

.product-share-link li a:hover {
  background: #e43a90;
}

/* Shop Page */
.shop {
  padding: 100px 0;
}

.single-product {
  padding: 100px 0 60px;
}

.single-product-details .product-title {
  font-size: 32px;
  line-height: 30px;
  margin-bottom: 20px;
}

.single-product-details .price {
  margin-bottom: 50px;
}

.single-product-details .price ins {
  margin-right: 10px;
  text-decoration: none;
}

.single-product-details .price ins, .single-product-details .price del {
  font-size: 26px;
  font-weight: 600;
}

.single-product-details .star-rating span:before {
  color: #e43a90;
}

.single-product-details .review-link {
  color: #363636;
}

.single-product-details .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}

/* Quick View */
.quickview-wrapper {
  position: fixed;
  max-width: 760px;
  border-radius: 5px;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.quickview-wrapper .quickview-content {
  background: #fff;
  padding: 15px;
  transition: all 0.5s ease-in-out;
}

.quickview-wrapper.open {
  visibility: visible;
  transform: translate(0, -50%);
  opacity: 1;
}

.quickview-wrapper.open .quickview-content {
  transform: translateY(0);
}

.quickview-slider .slider-for .slick-slide, .quickview-slider .slider-nav .slick-slide, .quickview-slider .slider-for1 .slick-slide, .quickview-slider .slider-nav1 .slick-slide {
  outline: 0;
}

.quickview-slider .slider-for .slick-slide img, .quickview-slider .slider-nav .slick-slide img, .quickview-slider .slider-for1 .slick-slide img, .quickview-slider .slider-nav1 .slick-slide img {
  max-width: 100%;
}

.quickview-slider .slider-for, .quickview-slider .slider-for1 {
  margin-bottom: 15px;
}

.quickview-slider .slider-nav .slick-slide, .quickview-slider .slider-nav1 .slick-slide {
  cursor: pointer;
}

/* Product Single */
.product-tab-wrapper .product-tabs {
  margin: 50px 0 30px;
  list-style: none;
  text-align: center;
  border-top: 1px solid #ededed;
  text-align: center;
  display: inherit;
}

.product-tab-wrapper .product-tabs li {
  display: inline-block;
}

.product-tab-wrapper .product-tabs li a {
  padding: 10px 20px;
  text-decoration: none;
  color: #444;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border-top: 1px solid transparent;
  font-weight: 600;
}

.product-tab-wrapper .product-tabs li a:hover, .product-tab-wrapper .product-tabs li a.active {
  color: #e43a90;
  border-color: #e43a90;
}

.product-tab-wrapper .tab-content .tab-pane {
  padding: 0;
}

.comments-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.review-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review-list .review-comment {
  margin-bottom: 20px;
}

.review-list .review-comment .user-avatar {
  height: 70px;
  width: 70px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.review-list .review-comment .user-avatar img {
  width: 100%;
  height: auto;
}

.review-list .review-comment .comment-content {
  margin-left: 90px;
}

.review-list .review-comment .comment-content .name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.review-list .review-comment .comment-content .star-rating span:before {
  color: #e43a90;
}

.review-list .review-comment .comment-content .comment-meta {
  margin-bottom: 10px;
}

.review-list .review-comment .comment-content .comment-meta .star-rating li {
  font-size: 12px;
  margin-right: 0;
}

.review-list .review-comment .comment-content .comment-meta .post-date {
  font-size: 12px;
  color: #a7a7a7;
}

.rating-title {
  font-size: 22px;
  font-weight: 600;
}

.review-form textarea, .review-form input {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}

.review-form textarea {
  height: 150px;
}

.review-form input {
  height: 50px;
  margin-top: 20px;
}

.review-form .gp-btn {
  max-width: 250px;
  margin: 30px 0 0 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: transparent;
  color: #343434;
  border-radius: 4px;
  padding: 12px 0px;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid #c9c9c9;
}

.review-form .gp-btn:hover {
  background: #e43a90;
  color: #fff;
  border: 1px solid #e43a90;
}

.shop_attributes {
  width: 100%;
}

.shop_attributes tbody {
  border: 1px solid #dcdcdc;
}

.shop_attributes tbody tr {
  height: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.shop_attributes tbody tr th {
  width: 30%;
  border-right: 1px solid #dcdcdc;
  padding-left: 20px;
}

.shop_attributes tbody tr td {
  padding-left: 20px;
}

.shop_attributes tbody tr td p {
  margin-bottom: 0;
}

.related-product {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #dcdcdc;
}

.related-product .product-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #363636;
}

/* Rating Star Widgets Style */
.rating {
  margin-bottom: 20px;
}

.rating li {
  cursor: default;
  display: inline-block;
  font-size: 20px;
  list-style-type: none;
}

.star,
.rating:not(.vote-cast):hover .star:hover ~ .star,
.rating.vote-cast .star.selected ~ .star {
  color: #a5a5a5;
}

.rating:hover .star,
.rating.vote-cast .star {
  color: #e43a90;
}

/* Color Checkbox */
.color-checkboxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.color-checkboxes h4 {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.color-checkboxes .col-Blue-label {
  background: #2196f3;
}

.color-checkboxes .col-Green-label {
  background: #8bc34a;
}

.color-checkboxes .col-Yellow-label {
  background: #fdd835;
}

.color-checkboxes .col-Orange-label {
  background: #ff9800;
}

.color-checkboxes .col-Red-label {
  background: #f44336;
}

.color-checkboxes .col-Black-label {
  background: #222222;
}

.color-checkbox {
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 7px;
  position: relative;
  border-radius: 16px;
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  cursor: pointer;
}

.color-checkbox.bordered {
  -webkit-box-shadow: 0 0 0 2px #f0f0f0 inset;
  box-shadow: 0 0 0 2px #f0f0f0 inset;
}

.color-checkbox.bordered:after {
  border-color: black;
}

.color-checkbox:after {
  content: '';
  display: block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 7px;
  border: 2px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  transition: opacity 0.1s;
  text-align: center;
}

.color-checkbox:hover,
.color-checkbox__input:focus + .color-checkbox {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.color-checkbox__input:checked + .color-checkbox:after {
  opacity: 1;
}

.color-checkbox__input {
  visibility: hidden;
  width: 0;
  pointer-events: none;
  position: absolute;
}

/* Product Size */
.options__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.options__item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 0;
}

.options__item span {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 1px solid #363636;
  color: #363636;
  border-radius: 3px;
  text-align: center;
  left: 30px;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.options__item span:hover, .options__item span.active {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

/* Cart */
#cart {
  padding: 100px 0;
  background: #f7f7f7;
}

.woocommerce table.shop_table {
  margin-bottom: 60px;
  border: none;
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.09);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
}

.woocommerce table.shop_table th {
  border: 0;
  text-transform: uppercase;
  position: relative;
}

.woocommerce table.shop_table td {
  padding: 6px 12px;
  border: 0;
  text-transform: uppercase;
  position: relative;
}

.woocommerce table.shop_table thead tr {
  background: #f7f7f7;
}

.woocommerce table.shop_table thead tr th {
  border-bottom: 1px solid #f7f7f7;
  background: #e9e9e9;
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  padding: 15px 30px;
  text-align: center;
}

.woocommerce table.shop_table tbody tr {
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce table.shop_table tbody tr td.product-item a {
  color: #202020;
  text-decoration: none;
  font-weight: 600;
}

.woocommerce table.shop_table tbody tr td.product-item a.remove {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  background: #e9e9e9;
  transition: all 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  border: 0;
}

.woocommerce table.shop_table tbody tr td.product-item img {
  width: 70px;
  height: 70px;
  margin-left: 70px;
  margin-right: 15px;
  border-radius: 3px;
}

.woocommerce table.shop_table tbody tr td.product-price {
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: center;
  border: 1px solid #f7f7f7;
}

.woocommerce table.shop_table tbody tr td.actions {
  padding: 20px;
}

.woocommerce table.shop_table tbody tr td.actions .coupon {
  float: left;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input {
  margin-bottom: 0;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.input-text {
  margin-right: 10px;
  max-width: 200px;
  padding: 6px 15px;
  border: 2px solid #f7f7f7;
  border-radius: 25px;
  font-size: 14px;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.button {
  background: #333;
  color: #FFF;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.button:hover {
  background: #191919;
  color: #FFF;
}

.woocommerce table.shop_table tbody tr td.actions .button {
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap {
  padding: 10px 20px;
  background: #fff;
  border-radius: 15px;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul {
  margin: 0;
  padding: 0;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li .title {
  display: inline-block;
  width: 59%;
  font-size: 12px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li span[data-title="Subtotal"], .woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li span[data-title="Total"] {
  display: inline-block;
  width: 39%;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: right;
}

.woocommerce .grand-total h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.woocommerce .wc-proceed-to-checkout {
  padding-top: 20px;
}

.woocommerce .wc-proceed-to-checkout .gp-btn {
  padding: 12px 35px;
  color: #FFF;
  font-size: 14px;
  display: inline-block;
  margin: auto;
  float: none;
  text-align: center;
  border-radius: 25px;
  background: #e43a90;
  font-size: 14px;
  border: none;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.text-active, .active > .text, .active > .auto .text {
  display: none !important;
}

.active > .text-active, .active > .auto .text-active {
  display: inline-block !important;
}

/* Product Responsive */
@media (min-width: 1200px) {
  .quickview-wrapper {
    min-height: 600px;
  }
}

@media (max-width: 1380px) {
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
    max-width: 45%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
    width: 55%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button {
    padding: 12px 20px;
  }
}

@media (max-width: 1280px) {
  .site-main .columns-4 ul.products li.product {
    width: 33.33%;
  }
  .site-main .columns-3 ul.products {
    width: 100%;
  }
  .site-main .columns-3 ul.products li.product {
    width: 50%;
    float: left;
  }
}

@media (max-width: 1024px) {
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
    float: none;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .site-main .columns-4 ul.products li.product {
    width: 50%;
    float: left;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .woocommerce-loop-product__title {
    margin-bottom: 5px;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .price {
    margin-bottom: 5px;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content p {
    margin-bottom: 5px;
    margin-bottom: 15px;
  }
  .widget.widget-shop .widget-title-shop {
    font-size: 20px;
  }
  #range-slider p #amount {
    width: 104px;
  }
  .fil-btn {
    padding: 6px 12px;
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .quickview-wrapper {
    width: 60%;
  }
  .quickview-wrapper.open {
    transform: translate(0, 3%);
  }
  .product-details {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .single-product-details .product-title {
    margin-top: 20px;
  }
  ul.products li.product .product-thumb .add_to_cart_button {
    padding: 8px 11px;
  }
  .product-top-menu .product-menu-wrapper {
    width: auto;
    float: none;
  }
}

@media (max-width: 500px) {
  .site-main .columns-4 ul.products li.product {
    width: 100%;
  }
  .site-main .columns-3 ul.products {
    width: 100%;
  }
  .site-main .columns-3 ul.products li.product {
    width: 100%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two {
    display: inherit;
    width: 300px !important;
    margin: 0 auto 30px;
    float: none;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
    max-width: 100%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
    width: 100%;
    padding: 30px 20px 35px;
  }
  .quickview-wrapper {
    width: 90%;
  }
  .product-top-menu span:first-child {
    display: none;
  }
  .product-top-menu #product-show {
    display: none;
  }
  .product-top-menu .product-select {
    width: 110px;
  }
  .shop.site-main {
    padding: 50px 0;
  }
  .color-checkboxes h4 {
    font-size: 16px;
  }
}

@media (min-width: 501px) and (max-width: 575px) {
  ul.products li.product .woocommerce-loop-product__title a {
    font-size: 21px;
  }
  ul.products li.product .price .woocommerce-Price-amount {
    font-size: 21px;
  }
  ul.products li.product .product-thumb .add_to_cart_button {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #product.section-padding {
    padding-bottom: 0px;
  }
  .quickview-wrapper {
    top: 5%;
  }
  .single-product {
    padding: 50px 0 50px;
  }
  .woocommerce-product-rating.float-right {
    float: left !important;
    margin-bottom: 17px;
  }
  .single-product-details .product-title {
    font-size: 23px;
  }
  .single-product-details .price {
    margin-bottom: 27px;
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .single-product-details .price ins, .single-product-details .price del {
    font-size: 20px;
    font-weight: 600;
  }
}

/*--------------------------------------------------------------
  ##   Contact
  --------------------------------------------------------------*/
.gmap3-area {
  height: 425px;
}

.sin-cont-info {
  text-align: center;
  color: #525252;
  height: 410px;
  box-shadow: 0px 3px 9px 0px rgba(108, 108, 108, 0.35);
}

.sin-cont-info i {
  font-size: 45px;
  color: #3a3a3a;
  padding-bottom: 6px;
  display: inline-block;
}

.sin-cont-info h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.sin-cont-info p {
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sin-cont-info a {
  font-size: 15px;
  font-weight: 400;
  color: #797878;
}

.no-pad {
  padding: 0px;
}

.center-wrap {
  margin: 0 auto;
}

.office-location {
  background: #2c2c2c;
  position: relative;
  z-index: 6;
}

.office-location h3 {
  color: #fff;
}

.office-location p {
  color: #fff;
}

.office-location a {
  color: #fff;
}

.sin-cont-info.office-location i {
  color: #fff;
}

.office-location::before {
  content: '\f041';
  position: absolute;
  left: 0;
  font-family: Fontawesome;
  font-size: 250px;
  top: 44%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -25%);
}

.con-bottom-inner h4 {
  font-size: 30px;
  color: #3c3c3c;
}

.con-bottom-inner .per-social ul li {
  display: inline;
  margin: 0 10px;
}

.con-bottom-inner h4 span {
  font-size: 30px;
  color: #f18636;
}

.con-bottom-inner {
  text-align: center;
}

.con-bottom-inner h4 {
  font-size: 30px;
  color: #3c3c3c;
  padding-bottom: 15px;
}

.con-bottom-inner .per-social {
  padding-bottom: 30px;
}

.con-bottom-inner .per-social li {
  margin: 0 10px;
}

.con-bottom-inner .per-social ul li a i {
  text-shadow: none;
}

.con-bottom-inner .per-social ul li a {
  color: #3c3c3c;
}

.con-bottom-inner .per-social ul li a:hover {
  color: #e43a90;
}

.con-page-form {
  padding-top: 32px;
  display: inline-block;
}

.con-page-form textarea {
  width: 100%;
  border: 1px solid #e7e6e6;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  padding: 15px;
  height: 180px;
}

.con-page-form input {
  width: 49%;
  float: left;
  border: 1px solid #e7e6e6;
  box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  border-radius: 4px;
  height: 55px;
  padding: 0px 20px;
}

.Stock {
  border-width: 1px;
  border-color: #e7e6e6;
  border-style: solid;
  border-radius: 12px;
  position: absolute;
  left: 550px;
  top: 1323px;
  width: 818px;
  height: 178px;
  z-index: 64;
}

.mar-r {
  margin-right: 2%;
}

.con-page-form input[type="submit"] {
  background-color: transparent;
  color: #363636;
  padding: 16px 40px;
  border-color: #e7e6e6;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
  margin-top: 10px;
  text-transform: uppercase;
  margin-top: 30px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  height: 55px;
  line-height: 0;
}

.con-page-form input[type="submit"]:hover {
  color: #e43a90;
}

#googleMap {
  height: 500px;
}

@media only screen and (max-width: 575px) {
  .con-page-form input {
    width: 100%;
    margin-bottom: 20px;
  }
  .con-page-form input[type="submit"] {
    margin-top: 0;
  }
}

/*--------------------------------------------------------------
  ##   Footer
  --------------------------------------------------------------*/
#footer {
  background: #1b1b1b;
}

#footer .footer-inner {
  padding: 80px 0 40px;
}

.footer-about .footer-logo {
  max-width: 120px;
  margin-bottom: 30px;
  width: 100%;
  display: inline-block;
}

.footer-about .footer-logo img {
  width: 100%;
}

.footer-about > p {
  color: #c8c6c6;
  line-height: 25px;
  margin-bottom: 20px;
}

.footer-contact .contact-details i {
  float: left;
  width: 30px;
  height: 40px;
  line-height: 26px;
}

.footer-contact .contact-details p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 30px;
}

.footer-title {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 20px;
}

/* Footer Latest Post */
.footer-blog-inner .footer-blog {
  border-bottom: 1px solid #4c4c4c;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.footer-blog-inner .footer-blog:last-child {
  border-bottom: 0;
}

.footer-blog-inner .footer-blog .fea-image {
  float: left;
  position: relative;
}

.footer-blog-inner .footer-blog .fea-image i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}

.footer-blog-inner .footer-blog .content {
  margin-left: 140px;
}

.footer-blog-inner .footer-blog .content h3 {
  font-size: 15px;
  line-height: 24px;
}

.footer-blog-inner .footer-blog .content h3 a {
  color: #fff;
}

.footer-blog-inner .footer-blog .content h3 a:hover {
  color: #e43a90;
}

.footer-blog-inner .footer-blog .content .meta {
  color: #b4b3b3;
}

.footer-blog-inner .footer-blog .content .meta:hover {
  color: #e43a90;
}

/* Tags */
.tagcloud a {
  display: inline-block;
  padding: 10px 28px;
  font-size: 16px;
  color: #fff;
  background: #636363;
  margin: 0 5px 10px 0;
  font-family: "Changa", sans-serif;
}

.tagcloud a:hover {
  background: #e43a90;
}

/* Widget Newsletter */
.footer-newsletter p {
  color: #fff;
}

.footer-newsletter #widget-newsletter {
  display: flex;
}

.footer-newsletter #widget-newsletter input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding: 10px 20px;
  outline: 0;
  border: 0;
}

.footer-newsletter #widget-newsletter input::placeholder {
  color: #afafaf;
}

.footer-newsletter #widget-newsletter .submit {
  background: #e43a90;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 0;
  padding: 0 17px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.footer-newsletter #widget-newsletter .submit:hover {
  background: #444;
}

/*  Instagram Widget */
.instagram-feed a {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 5px;
}

.instagram-feed a img {
  max-width: 100%;
}

/* Footer Social Link */
.footer-social-link {
  height: 60px;
}

.footer-social-link li {
  display: inline-block;
  width: 25%;
  background: #000;
  margin-left: -4px;
  text-align: center;
  padding: 16px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-social-link li:before {
  position: absolute;
  content: '';
  height: 61px;
  width: 100%;
  top: -61px;
  background: #e43a90;
  z-index: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.footer-social-link li a {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: relative;
  z-index: 2;
}

.footer-social-link li.fb-bg {
  background: #4868ab;
}

.footer-social-link li.yotube-bg {
  background: #ff2500;
}

.footer-social-link li.tw-bg {
  background: #1da2f2;
}

.footer-social-link li.pin-bg {
  background: #bd1a1c;
}

.footer-social-link li:hover:before {
  top: 0;
}

.copyright-text {
  padding: 28px 0;
}

.copyright-text p {
  margin: 0;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.copyright-text p a {
  color: #e43a90;
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .tagcloud a {
    padding: 10px 23px;
  }
  .footer-newsletter #widget-newsletter input {
    width: 85%;
  }
}

@media (max-width: 576px) {
  .footer-social-link li {
    padding: 12px 0;
  }
  .footer-social-link li a {
    font-size: 16px;
  }
  .copyright-text p {
    text-align: center;
  }
  #footer .footer-inner {
    padding: 50px 0 40px;
  }
  .footer-newsletter #widget-newsletter input {
    width: 83%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .instagram-feed a {
    max-width: 107px;
  }
  .tagcloud a {
    padding: 10px 20px;
  }
}

/*--------------------------------------------------------------
  ##  Magazine
  --------------------------------------------------------------*/
.single-social-widget {
  width: 100%;
  background: #242e8a;
  height: 70px;
  margin-bottom: 10px;
}

.single-social-widget a {
  float: left;
  background: #19227a;
  color: #fff;
  display: block;
  width: 60px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 27px;
}

.single-social-widget p {
  color: #fff;
  margin: 0;
  padding-left: 80px;
  padding-top: 17px;
  font-family: "Changa", sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.single-social-widget span {
  color: #fff;
  margin: 0;
  padding-left: 81px;
  display: block;
  font-family: "Changa", sans-serif;
  font-size: 16px;
}

.single-social-widget p:first-child {
  padding-top: 10px;
}

.facebook-widget {
  background: #242e8a;
}

.facebook-widget a {
  background: #19227a;
}

.twitter-widget {
  background: #2196f0;
}

.twitter-widget a {
  background: #1487df;
}

.pint-widget {
  background: #cf0f21;
}

.pint-widget a {
  background: #b30919;
}

.magazine-post-widget > a {
  float: left;
}

.mag-post-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.mag-post-content h2 a {
  font-size: 18px;
  color: #363636;
}

.magazine-post-widget {
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}

.mag-post-content h2 {
  line-height: 17px;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
}

.mag-post-content span {
  line-height: 17px;
  padding: 5px 20px;
}

.mag-post-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.mag-show-content h2 {
  line-height: 17px;
  padding: 5px 20px;
  margin-bottom: 0;
}

.mag-show-content span {
  line-height: 16px;
  padding: 5px 20px;
  font-size: 16px;
}

.mag-show-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.mag-show-content > a {
  color: #dc9c0a;
  float: right;
  margin-right: 50px;
}

.mag-show-content h2 a {
  font-size: 20px;
  color: #363636;
}

.magazine-show-widget {
  margin-bottom: 20px;
  box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
}

.mag-show-widget .magazine-show-widget:last-child {
  margin-bottom: 0px;
}

.magazine-tab-area .magazine-show-widget {
  margin-bottom: 30px;
}

.mag-show-content {
  background: #fff;
  padding: 18px 0 27px;
}

.magazine-widget .mag-bn::before {
  background: #313130;
}

.magazine-widget .mag-bn p {
  color: #fff;
  font-weight: 700;
}

.magazine-sidebar {
  padding-top: 60px;
}

.magazine-widget .mag-bn {
  margin-bottom: 30px;
}

.magazine-widget {
  margin-bottom: 60px;
}

.mag-tag-widget ul li {
  position: relative;
  width: auto;
  z-index: 1;
  text-align: center;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 15px;
}

.mag-tag-widget ul li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background: #313130;
  z-index: -1;
}

.mag-tag-widget ul li a {
  color: #fff;
  padding: 12px 35px;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
}

.mag-tag-widget ul li:hover::before {
  background: #ddb013;
  transition: all 0.5s ease;
}

.mag-twitter-widget .single-twitter {
  border-bottom: 1px dashed #ddd;
  margin-top: 1rem;
}

.mag-twitter-widget .single-twitter p {
  font-size: 16px;
  color: #525252;
  margin-top: 10px;
}

.mag-twitter-widget .single-twitter .twt-top i {
  color: #313130;
  font-size: 27px;
  margin-right: 18px;
  margin-top: -1px;
  float: left;
  font-style: italic;
}

.mag-twitter-widget .single-twitter .twt-top span {
  color: #ddb013;
}

.magazine-blog {
  padding-top: 60px;
}

.magazine-blog .mag-bn p {
  color: #fff;
  font-weight: 700;
}

.magazine-post {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
}

.magazine-post:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.magazine-post img {
  transition: all 0.3s ease-in-out;
}

.magazine-show-widget a {
  display: block;
  overflow: hidden;
}

.magazine-show-widget:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.magazine-show-widget img {
  transition: all 0.3s ease-in-out;
}

.mags-post-content {
  position: absolute;
  bottom: 6px;
  left: 24px;
  z-index: 999;
}

.mags-post-content h4 a {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 42px;
  padding-right: 105px;
  display: inline-block;
}

.mags-post-content > a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background: #da9a0a;
  border-radius: 3px;
  padding: 8px 18px;
  display: inline-block;
  margin-bottom: 13px;
  font-family: "Changa", sans-serif;
}

.mag-post-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(to bottom, #000, transparent);
  background: -webkit-linear-gradient(bottom, #000, transparent);
  opacity: 0.7;
  z-index: 1;
  top: 0;
}

.mags-post-content p {
  font-size: 16px;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.mags-post-content p a {
  color: #ddb013;
}

.mid .mags-post-content h4 a {
  font-size: 24px;
  line-height: 32px;
  padding-right: 0;
}

.mid .mags-post-content > a {
  font-size: 15px;
  margin-bottom: 7px;
  padding: 5px 13px;
}

.mid .mags-post-content h4 {
  margin-bottom: 0;
}

.magazine-post.mid:first-child {
  margin-bottom: 20px;
}

.magazine-post-small {
  overflow: hidden;
  margin-bottom: 22px;
  box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
}

.magazine-post-small:last-child {
  margin-bottom: 0;
}

.magazine-post-small > a {
  float: left;
}

.mag-post-content-small {
  position: relative;
  display: block;
  overflow: hidden;
}

.mag-post-content-small h2 a {
  font-size: 18px;
  color: #363636;
}

.mag-post-content-small h2 a:hover {
  color: #da9a0a;
}

.mag-post-content-small h2 {
  line-height: 17px;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
}

.mag-post-content-small span {
  line-height: 17px;
  padding: 5px 20px;
}

.mag-post-content-small span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.magazine-blog .mag-bn {
  margin-bottom: 30px;
}

.magazine-tab-area .mag-bn {
  float: left;
  margin-bottom: 0;
}

.magazine-tab-btn {
  float: right;
  margin-top: 7px;
}

.magazine-tab-btn .nav-item a {
  font-size: 18px;
  color: #474646;
  font-family: "Changa", sans-serif;
}

.magazine-tab-btn .nav-item a:hover, .magazine-tab-btn .nav-item a.active, .mag-post-content h2 a:hover, .mag-show-content h2 a:hover,
.magazine-post-small-video .mag-post-content-small h2 a:hover, .single-concert .sin-concert-content h4 a:hover, .magazine-next-cons-content h2 a:hover,
.mag-hot-post h2 a:hover, .concer-sin-content h2 a:hover {
  color: #da9a0a;
}

.magazine-tab-area .tab-content .tab-pane {
  padding: 30px 15px 0;
}

.magazine-artist-info .aritist-music .icon {
  float: none;
  margin-right: 0;
  text-align: center;
}

.magazine-artist-info .aritist-music .content {
  margin-left: 0;
  text-align: center;
}

.magazine-artist-info .aritist-music .icon i {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
}

.magazine-artist-info .aritist-music .content p {
  color: #fff;
  font-size: 30px;
  margin: 0;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.magazine-artist-info .aritist-music .content span {
  font-size: 20px;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.magazine-artist-info {
  position: absolute;
  top: 0;
  height: 100%;
  background: rgba(58, 57, 58, 0.67);
  width: 102px;
  z-index: 6;
  padding-top: 20px;
}

.magazine-artist {
  position: relative;
  margin-bottom: 30px;
}

.magazine-artist::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(216, 198, 0, 0.4);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.magazine-artist:hover:before {
  opacity: 1;
}

.mag-artist-content {
  position: absolute;
  left: 0;
  bottom: 31px;
  left: 131px;
  z-index: 6;
}

.mag-artist-content p {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
  margin-bottom: 0;
}

.mag-artist-content span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.mag-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#000), to(transparent));
  background: -o-linear-gradient(top, #000, transparent);
  background: linear-gradient(to bottom, #000, transparent);
  background: -webkit-linear-gradient(bottom, #000, transparent);
  opacity: 0.7;
  z-index: 1;
  top: 0;
}

.aritist-music {
  margin-bottom: 20px;
}

.magazine-artist:hover .magazine-artist-info {
  background: rgba(220, 156, 10, 0.67);
  transition: all 0.5s ease;
}

.magazine-video {
  position: relative;
  margin-bottom: 30px;
}

.magazine-video .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #d21d20;
  font-size: 96px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}

.magazine-video .popup-video-btn::after {
  content: '';
  background: #fff;
  width: 46px;
  height: 46px;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.magazine-post-small-video {
  overflow: hidden;
  margin-bottom: 18px;
  box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
  position: relative;
}

.magazine-post-small-video:last-child {
  margin-bottom: 0;
}

.magazine-post-small-video > img {
  float: left;
}

.magazine-post-small-video .mag-post-content-small {
  position: relative;
  display: block;
  overflow: hidden;
}

.magazine-post-small-video .mag-post-content-small h2 a {
  font-size: 18px;
  color: #363636;
}

.magazine-post-small-video .mag-post-content-small h2 {
  line-height: 17px;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
}

.magazine-post-small-video .mag-post-content-small span {
  line-height: 17px;
  padding: 5px 20px;
}

.magazine-post-small-video .mag-post-content-small span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.magazine-post-small-video .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #d21d20;
  font-size: 25px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}

.mag-post-vid-img {
  float: left;
  position: relative;
}

.magazine-post-small-video .popup-video-btn::after {
  content: '';
  background: #fff;
  width: 10px;
  height: 13px;
  top: 8px;
  left: 9px;
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.single-concert {
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.single-concert > a.tag {
  position: absolute;
  top: 25px;
  left: 25px;
  background: #dc9c0a;
  border-radius: 3px;
  padding: 3px 10px;
  color: #fff;
}

.single-concert .sin-concert-content span {
  color: #707070;
  font-size: 16px;
  font-family: "Changa", sans-serif;
}

.single-concert .sin-concert-content span a {
  color: #ddb80a;
}

.single-concert .sin-concert-content h4 a {
  font-family: "Changa", sans-serif;
  font-size: 22px;
  color: #353535;
}

.single-concert .sin-concert-content p {
  font-size: 14px;
  color: #363636;
}

.single-concert a.imag {
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.single-concert a.imag img {
  transition: all 0.3s ease-in-out;
}

.single-concert:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.sin-concert-content {
  padding: 30px 15px 30px 25px;
}

.area-wrap {
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}

.concert-single img {
  float: left;
}

.concer-sin-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.concer-sin-content h2 a {
  font-size: 18px;
  color: #363636;
  font-family: "Changa", sans-serif;
}

.concert-single {
  overflow: hidden;
  padding-bottom: 20px;
  padding: 27px 15px 27px 25px;
  border-top: 1px dashed #ddd;
}

.concer-sin-content h2 {
  line-height: 17px;
  padding: 0 0 0 20px;
  margin-bottom: 0;
}

.concer-sin-content span {
  line-height: 17px;
  padding: 5px 20px;
  font-size: 14px;
  color: #ddb80a;
}

.concer-sin-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.concer-sin-content > a {
  background: #8a2467;
  color: #fff;
  padding: 3px 11px;
  border-radius: 5px;
  margin-top: 4px;
  display: inline;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 18px;
}

.magazine-next-cons-content h2 {
  line-height: 17px;
  padding: 5px 20px;
  margin-bottom: 0;
}

.magazine-next-cons-content span {
  line-height: 16px;
  padding: 5px 20px;
  font-size: 16px;
}

.magazine-next-cons-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.magazine-next-cons-content > a {
  color: #dc9c0a;
  float: right;
  margin-right: 50px;
}

.magazine-next-cons-content h2 a {
  font-size: 20px;
  color: #363636;
}

.magazine-next-cons-content {
  background: #fff;
  padding: 12px 0 12px;
}

.magazine-next-cons > a {
  display: inline-block;
  overflow: hidden;
}

.magazine-next-cons img {
  transition: all 0.3s ease-in-out;
}

.magazine-next-cons:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.mag-hot-post-long {
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin-bottom: 30px;
}

.mag-hot-post-long img {
  float: left;
  width: 50%;
}

.mag-hot-post-long.img-fullwidth > img {
  width: 100%;
}

.mag-hot-post .mhp-icon i {
  color: #fff;
  background: #2a2a2a;
  padding: 7px;
  border-radius: 51px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding-left: 11px;
}

.mag-hot-post .mhp-icon > a {
  background: #dc9c0a;
  color: #fff;
  padding: 3px 11px;
  border-radius: 5px;
  margin-top: 4px;
  display: inline;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 10px;
}

.mag-hot-post h2 {
  margin-top: 20px;
  line-height: 32px;
}

.mag-hot-post h2 a {
  font-size: 22px;
  color: #363636;
  font-family: "Changa", sans-serif;
  line-height: 32px;
}

.mag-hot-post p {
  font-size: 16px;
  color: #363636;
  font-family: "Changa", sans-serif;
}

.mag-hot-post .mhp-footer img {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  margin-right: 20px;
}

.mag-hot-post .mhp-footer span {
  font-size: 16px;
  color: #696969;
  font-family: "Changa", sans-serif;
}

.mag-hot-post .mhp-footer i {
  color: #ddb80a;
  background: none;
}

.mag-hot-post {
  overflow: hidden;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 15px;
}

.mhp-footer {
  border-top: 1px dashed #ddd;
  padding-top: 24px;
  margin-right: 30px;
  padding-bottom: 23px;
}

.bg-two {
  background: #e43a90 !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .magazine-post-widget > a {
    float: left;
    width: 84px;
  }
  .mag-post-content h2 {
    line-height: 15px;
    padding: 0px 0 5px 20px;
  }
  .magazine-widget .mag-bn p {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
  }
  .mag-tag-widget ul li a {
    color: #fff;
    padding: 4px 11px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }
  .hp-content p a {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .mag-show-content h2 a {
    font-size: 16px;
  }
  .mag-show-content {
    padding: 9px 0 17px;
  }
  .mag-show-content span {
    font-size: 14px;
  }
  .mags-post-content > a {
    font-size: 16px;
    padding: 2px 8px;
  }
  .mags-post-content h4 a {
    font-size: 20px;
    line-height: 28px;
    padding-right: 50px;
  }
  .magazine-post.mid .mags-post-content {
    left: 10px;
  }
  .mid .mags-post-content > a {
    font-size: 15px;
    padding: 4px 10px;
  }
  .mid .mags-post-content h4 a {
    font-size: 18px;
    line-height: 28px;
  }
  .magazine-post-small > a {
    display: none;
  }
  .mag-post-content-small h2 a {
    font-size: 16px;
  }
  .tab-content .mag-show-content > a {
    margin-right: 18px;
  }
  .tab-content .mag-show-content h2 {
    padding: 5px 8px;
  }
  .tab-content .mag-show-content span {
    padding: 5px 9px;
  }
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .mag-artist-content {
    left: 115px;
  }
  .mag-post-vid-img {
    display: none;
  }
  .magazine-post-small-video .mag-post-content-small {
    padding-bottom: 4px;
  }
  .concert-single img {
    display: none;
  }
  .single-concert .sin-concert-content span {
    font-size: 13px;
  }
  .single-concert .sin-concert-content h4 a {
    font-size: 16px;
    line-height: 16px;
  }
  .sin-concert-content {
    padding: 14px 10px 0px 15px;
  }
  .concert-single {
    padding: 13px 12px 10px 1px;
  }
  .concer-sin-content > a {
    padding: 2px 8px;
    font-size: 12px;
  }
  .magazine-next-cons-content h2 a {
    font-size: 16px;
  }
  .magazine-next-cons-content span {
    font-size: 14px;
  }
  .concer-sin-content h2 {
    line-height: 16px;
  }
  .concer-sin-content h2 a {
    font-size: 16px;
  }
  .magazine-next-cons {
    margin-bottom: 20px;
  }
  .magazine-post-small {
    margin-bottom: 15px;
    padding-bottom: 5px;
  }
  .magazine-post-small-video .mag-post-content-small h2 a {
    font-size: 16px;
  }
  .mag-hot-post-long.big-one img {
    display: none;
  }
  .mag-hot-post h2 {
    margin-top: 10px;
    line-height: 1.2rem;
  }
  .mag-hot-post h2 a {
    font-size: 16px;
    line-height: 16px;
  }
  .mag-hot-post .mhp-icon i {
    padding: 4px;
    width: 21px;
    height: 21px;
    padding-left: 7px;
    font-size: 13px;
  }
  .mag-hot-post .mhp-footer img {
    display: none;
  }
  .mhp-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .mag-post-content h2 a {
    font-size: 16px;
  }
  .mag-twitter-widget .single-twitter p {
    font-size: 14px;
    line-height: 26px;
  }
  .magazine-widget {
    margin-bottom: 30px;
  }
  .magazine-post-small-video .mag-post-content-small span {
    padding: 5px 14px;
    font-size: 14px;
  }
  .magazine-post-small-video .mag-post-content-small h2 {
    padding: 5px 0 5px 12px;
  }
  .mag-post-content-small span {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mheader-top p {
    padding-top: 0px;
    width: 60%;
    line-height: 21px;
  }
  .hp-content p {
    line-height: 20px;
  }
  .hp-content p a {
    font-size: 15px;
  }
  .hp-content a {
    font-size: 14px;
  }
  .hp-content {
    top: 50px;
  }
  .magazine-post-small img {
    width: 93px;
  }
  .mag-show-content > a {
    margin-right: 20px;
  }
  .mag-show-content h2 a {
    font-size: 16px;
  }
  .mag-show-content span {
    font-size: 14px;
  }
  .mag-show-content {
    padding: 5px 0 10px;
  }
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .magazine-artist-info {
    width: 80px;
  }
  .mag-artist-content {
    left: 90px;
  }
  .mag-bn p {
    font-size: 20px;
  }
  .mag-bn {
    padding: 8px 33px;
  }
  .magazine-post-small-video:last-child {
    display: none;
  }
  .mag-post-vid-img img {
    width: 102px;
  }
  .concert-single img {
    display: none;
  }
  .single-concert .sin-concert-content span {
    font-size: 14px;
  }
  .single-concert .sin-concert-content h4 a {
    font-size: 17px;
  }
  .concert-single {
    padding: 13px 15px 8px 0px;
  }
  .sin-concert-content {
    padding: 14px 15px 0px 20px;
  }
  .magazine-next-cons-content span {
    font-size: 14px;
  }
  .magazine-next-cons-content h2 a {
    font-size: 17px;
  }
  .magazine-widget {
    width: 50%;
    float: left;
    padding-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mheader-top p {
    font-size: 13px;
    width: 50%;
    margin-top: -8px;
    padding-top: 0;
  }
  .headr-post {
    width: 50%;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 30px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 26px;
  }
  .aritist-music {
    margin-bottom: 0;
  }
  .magazine-artist-info {
    padding-top: 10px;
  }
  .magazine-next-cons {
    width: 50%;
    float: left;
    padding: 15px;
  }
  .magazine-post-small-video:last-child {
    margin-bottom: 30px;
  }
  .magazine-widget {
    width: 50%;
    float: left;
    padding-right: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .mheader-top p {
    font-size: 14px;
    width: 72%;
    line-height: 24px;
  }
  .mheader-top span {
    padding: 3px 7px;
    border-radius: 5px 0px 0px 5px;
    margin-right: 11px;
    margin-top: 7px;
  }
  .mheader-top .mag-social {
    float: none;
    text-align: center;
    margin-top: 14px;
    display: inline-block;
    width: 100%;
  }
  .headr-post {
    width: 50%;
  }
  .hp-content p a {
    font-size: 16px;
  }
  .hp-content p {
    line-height: 24px;
  }
  .hp-content {
    top: 85px;
  }
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .magazine-artist-info {
    width: 80px;
  }
  .mag-artist-content {
    left: 90px;
  }
  .magazine-artist-info {
    padding-top: 10px;
  }
  .magazine-post-small-video:last-child {
    margin-bottom: 30px;
  }
  .mag-hot-post-long img {
    float: none;
    width: 100%;
  }
  .mag-show-widget .magazine-show-widget:last-child {
    margin-bottom: 30px;
  }
  .magazine-sidebar .magazine-show-widget {
    width: 45%;
    margin: 0 2%;
    margin-bottom: 30px;
    float: left;
  }
  .magazine-next-cons {
    width: 50%;
    float: left;
    padding: 0 15px;
    margin-bottom: 15px;
  }
  .magazine-post-small:last-child {
    margin-bottom: 22px;
  }
  .magazine-post.mid {
    width: 50%;
    float: left;
  }
}

@media (max-width: 575px) {
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .magazine-artist-info {
    width: 80px;
  }
  .mag-artist-content {
    left: 90px;
  }
  .magazine-artist-info {
    padding-top: 35px;
  }
  .headr-post {
    width: 50%;
  }
  .hp-content p a {
    font-size: 16px;
  }
  .hp-content p {
    line-height: 24px;
  }
  .hp-content {
    top: 75px;
  }
  .magazine-post-small:last-child {
    margin-bottom: 30px;
  }
  .magazine-post-small-video:last-child {
    margin-bottom: 30px;
  }
  .mag-hot-post-long img {
    float: none;
    width: 100%;
  }
  .mag-show-content h2 {
    padding: 5px 11px;
  }
  .mag-show-content span {
    font-size: 13px;
    padding: 5px 11px;
  }
  .mag-show-content > a {
    margin-right: 23px;
  }
  .mag-header-inner h2 {
    font-size: 45px;
    line-height: 50px;
    margin-top: 9px;
  }
}

@media (min-width: 420px) and (max-width: 575px) {
  .magazine-next-cons {
    width: 80%;
    margin: 0 auto;
  }
  .mags-post-content {
    bottom: 0px;
    left: 13px;
  }
  .concert-single img {
    width: 20%;
  }
}

@media (max-width: 460px) {
  .hp-content {
    top: 7px;
  }
}
